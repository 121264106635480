import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function SslCertificates(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = 'Install SSL - Secure your website using SSL encryption';
    }, []);
    
    return (
        <>
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    SSL Certificates
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">SSL Certificates</h1>
                <p className="font-light">Secure your websites' data, use SSL encrytion.</p>
            </div>

            <div className="flex flex-col mt-10">
                <div className="px-5">
                    <h1 className="text-md font-bold uppercase mb-4">Free SSL (Let's Encrypt)</h1>
                    <div>
                        <p className="text-xs font-bold uppercase mb-1">Domain:</p>
                        <select className="border-2 border-custom-green bg-black text-white rounded-md p-1 sm:w-[50%] w-full">
                            <option value="domain1">turfdev.in</option>
                            <option value="domain2">tdshop.com</option>
                            <option value="domain3">kb.turfdev.in</option>
                        </select>
                    </div>

                    <div className="flex mt-4">
                        <button className="w-40 elastic-button1" id="installFreeSSL" type="submit">Install Free SSL</button>
                    </div>
                </div>

                <div className="px-5 mt-8">
                    <h1 className="text-md font-bold uppercase">SSL Certificates</h1>
                    <p className="text-sm mb-4">You can use the below interface to install your own (paid) certificates.</p>
                    
                    <div className="flex flex-col">
                        <label htmlFor="ssl-cert" className="text-xs font-bold uppercase mb-1">SSL Certificate:</label>
                        <textarea type="text" id="ssl-cert" name="ssl-cert" className="md:w-[70%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm mb-2" rows={4} />
                    </div>
                    <div className="flex flex-col mt-2">
                        <label htmlFor="ssl-key" className="text-xs font-bold uppercase mb-1">Private Key:</label>
                        <textarea type="text" id="ssl-key" name="ssl-key" className="md:w-[70%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm mb-2" rows={4} />
                    </div>
                    <div className="flex flex-col mt-2">
                        <label htmlFor="ssl-ca" className="text-xs font-bold uppercase mb-1">CA Bundle or Chain Certificate:</label>
                        <textarea type="text" id="ssl-ca" name="ssl-ca" className="md:w-[70%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm mb-2" rows={4} />
                    </div>

                    <div className="flex md:flex-row flex-col mt-4 gap-2">
                        <button className="button1" id="installSSL" type="submit">Install SSL</button>
                        <button className="button2" id="removeSSL" type="submit">Remove SSL</button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SslCertificates;
