import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Contact() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState("");
    const [agreeChecked, setAgreeChecked] = useState(false);

    const fieldErrors = {};

    const handleCheckboxChange = () => {
        setAgreeChecked(!agreeChecked);
    };

    const handleInputChange = (e) => {
		setErrorMessages('');
        const { name, value } = e.target;

        if (name === 'message' && value.length > 500) {
            // Prevent typing if the message length exceeds 1000 characters
            return;
        }

        setFormData({
            ...formData,
            [name]: e.target.value,
        });
    };     

    const handleFormSubmit = async (e) => {
        // e.preventDefault(); // Prevent the default form submission
        setLoading(true);

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Get the form data
        const { name, email, phone, message } = formData;

        if (name) {
            if (name.length > 20) {
                fieldErrors.name = "Name should not be more than 20 characters!";
            }
        } else {
            fieldErrors.name = "Name is required!";
        }

        if (email) {
			if (!emailRegex.test(email)) {
				fieldErrors.email = "Email is not in a proper format."
			}
		} else {
            fieldErrors.email = "Email is required!";
        }

        if (!message) {
            fieldErrors.message = "Message is required!";
        }

        setErrorMessages(fieldErrors);

        if (Object.values(fieldErrors).some((error) => error !== "")) {
            setLoading(false);
            return;
        }

        if (!agreeChecked) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                agree: "You must agree to the terms and conditions.",
            }));
            setLoading(false);
            return;
        } else {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                agree: "",
            }));
        }

        await axios
            .post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/sendcontactmail`, formData)
            .then((response) => {
                setMessage("Mail sent successfully. We will get back to you soon.");
                
                // Automatically refresh the page after 3 seconds
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            })
            .catch((error) => {
				// console.log("Ye hai error: ", error);
                setErrorMessages((prevErrors) => ({
					...prevErrors,
					sending: "An error occurred while sending the mail. Please try again later.",
				}));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const remainingCharacters = 500 - formData.message.length;

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleFormSubmit();
        }
    };

    return (
        <>
            <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex justify-center items-center flex-col mt-5 gap-2">
                <input
                    className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    required
                />
                {errorMessages.name && (
                    <div className="w-full flex justify-start -mt-2">
                        <p className="text-red-600 text-sm">
                            {errorMessages.name}
                        </p>
                    </div>
                )}

                <input
                    className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    required
                />
                {errorMessages.email && (
                    <div className="w-full flex justify-start -mt-2">
                        <p className="text-red-600 text-sm">
                            {errorMessages.email}
                        </p>
                    </div>
                )}

                <input
                    className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                    placeholder="Phone"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    required
                />

                <textarea
                    className="w-[100%] h-20 placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                    placeholder="Message"
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                />
                <div className="w-full flex justify-end -mt-1">
                    <p className="text-xs text-gray-700">
                        Remaining characters: {remainingCharacters}
                    </p>
                </div>
                {errorMessages.message && (
                    <div className="w-full flex justify-start -mt-2">
                        <p className="text-red-600 text-sm -mt-5">
                            {errorMessages.message}
                        </p>
                    </div>
                )}

                <div className="flex gap-2">
                    <input
                        type="checkbox"
                        id="agree"
                        name="agree"
                        onChange={handleCheckboxChange}
                        required
                    />
                    <label
                        htmlFor="agree"
                        className="text-xs text-gray-700"
                    >
                        I agree to the Turfdev's{" "}
                        <span className="underline underline-offset-1">
                            <Link
                                to="/privacy"
                                className="hover:text-black"
                            >
                                terms and conditions
                            </Link>
                        </span>
                    </label>
                </div>
                {errorMessages.agree && (
                    <div className="w-full flex justify-center -mt-2">
                        <p className="text-red-500 text-xs">
                            {errorMessages.agree}
                        </p>
                    </div>
                )}

                <button
                    className={`${loading ? "cursor-not-allowed" : ""} w-full elastic-button1`}
                    type="submit"
                    disabled={loading}
                    onClick={handleFormSubmit}
                >
                    {loading ? "Sending..." : "SEND"}
                </button>

                <div>
                    {errorMessages.sending && (
                        <p className="text-sm text-center text-red-500">
                            {errorMessages.sending}
                        </p>
                    )}
                    {message && (
                        <p className="text-sm text-center text-green-500">
                            {message}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default Contact;
