import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function DashboardOptions(props) {
    // console.log("Props", props)
    // const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    }

    // const toggleDropdown = () => {
    //     setIsDropdownOpen(prevState => !prevState);
    // }
    
    return (
        <>
            <div className="text-black font-light p-5 lg:py-20 lg:pl-20 lg:pr-5 w-full">
                {/* <div className="block lg:hidden font-bold hover:scale-105 cursor-pointer text-xl text-center" onClick={toggleDropdown}>
                    <h1>Menu</h1>
                    <i className={isDropdownOpen ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
                </div> */}

                <div className="block items-center">
                    <h1 className="text-center lg:text-left uppercase text-xl font-bold mb-5">Dashboard Options</h1>
                    <ul className="text-left font-light capitalize flex flex-col">
                        <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem0 === 'home' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard"><i className="fa-solid fa-house"></i><span className="pl-4">Home</span></Link></li>
                        <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem0 === 'products' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard/products"><i className="fa-solid fa-server"></i><span className="pl-4">Products</span></Link></li>
                        <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem0 === 'invoices' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard/invoices"><i className="fa-solid fa-money-check-dollar"></i><span className="pl-4">Invoices</span></Link></li>
                        <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem0 === 'payment' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard/payment"><i className="fa-regular fa-credit-card"></i><span className="pl-4">Payment</span></Link></li>
                        
                        <li className="flex flex-col items-center justify-start mt-1" onClick={toggleMenu}>
                            <Link className={`${props.selectedItem0 === 'support' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="">
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-4 items-center">
                                        <i className="fa-solid fa-headset"></i>
                                        Help Center
                                    </div>
                                    <i className={isMenuOpen ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
                                </div>
                            </Link>
                            <ul className={isMenuOpen ? "block w-full pl-4" : "hidden"}>
                                <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem2 === 'mytickets' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard/mytickets"><i className="fa-solid fa-list-check"></i><span className="pl-4">My Tickets</span></Link></li>
                                <li className="flex items-center justify-start mt-1"><Link className={`${props.selectedItem2 === 'openticket' ? "bg-custom-green text-white" : "hover:bg-gray-300"} p-2 cursor-pointer rounded w-[100%]`} to="/dashboard/contactsupport"><i className="fa-solid fa-ticket"></i><span className="pl-4">Open Ticket</span></Link></li>
                            </ul>
                        </li> 
                    </ul>
                </div>
            </div>
        </>
    )

}

export default DashboardOptions;