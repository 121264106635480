import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function ServicesManager(userData) {
    // const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = 'Services - Start, Stop, Restart, Install, Remove';
    }, []);
    
    return (
        <>
            <div className="flex mb-10 gap-2 items-center text-xs font-bold flex-wrap">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?createApp`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Service Manager
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">Service Manager</h1>
                <p className="font-light">Install, start, stop, and restart your services.</p>
            </div>

            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col flex-1 sm:p-5 gap-2">

                    <div className="p-4 border border-custom-green rounded-xl gap-4 flex justify-between items-center">
                        <div className="flex flex-1 items-center md:gap-0 gap-2">
                            <div className="flex flex-1 flex-col">
                                <h1 className="text-xl md:text-md uppercase font-bold">Apache</h1>
                                <p className="font-light text-sm capitalize">webserver</p>
                            </div>

                            <div className="flex flex-1 uppercase text-sm">
                                <div className="flex gap-2">
                                    <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Active</p>
                                </div>
                                <div className="hidden">
                                    <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Stopped</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center flex-col md:flex-row gap-2 md:gap-0">
                            <div className="flex flex-1 gap-1">
                                <button className="w-10 h-10 text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full p-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full p-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-stop"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full p-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                            </div>

                            <div className="flex-1 flex justify-end">
                                <button className="button-red" type="submit">Uninstall</button>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border border-custom-green rounded-xl gap-4 flex justify-between items-center">
                        <div className="flex flex-1 items-center md:gap-0 gap-2">
                            <div className="flex flex-1 flex-col">
                                <h1 className="text-xl md:text-md uppercase font-bold">Nginx</h1>
                                <p className="font-light text-sm capitalize">webserver</p>
                            </div>

                            <div className="flex flex-1 uppercase text-sm">
                                <div className="hidden">
                                    <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Active</p>
                                </div>
                                <div className="flex gap-2">
                                    <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Stopped</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center flex-col md:flex-row gap-2 md:gap-0">
                            <div className="flex flex-1 gap-1">
                                <button className="w-10 h-10 text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full p-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full p-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-stop"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full p-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                            </div>

                            <div className="flex-1 flex justify-end">
                                <button className="button-red" type="submit">Uninstall</button>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border border-custom-green rounded-xl gap-4 flex justify-between items-center">
                        <div className="flex flex-1 items-center md:gap-0 gap-2">
                            <div className="flex flex-1 flex-col">
                                <h1 className="text-xl md:text-md uppercase font-bold">MySQL</h1>
                                <p className="font-light text-sm capitalize">database server</p>
                            </div>

                            <div className="flex flex-1 uppercase text-sm">
                                <div className="flex gap-2">
                                    <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Active</p>
                                </div>
                                <div className="hidden">
                                    <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Stopped</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center flex-col md:flex-row gap-2 md:gap-0">
                            <div className="flex flex-1 gap-1">
                                <button className="w-10 h-10 text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full p-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full p-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-stop"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full p-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                            </div>

                            <div className="flex-1 flex justify-end">
                                <button className="button-red" type="submit">Uninstall</button>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border border-custom-green rounded-xl gap-4 flex justify-between items-center">
                        <div className="flex flex-1 items-center md:gap-0 gap-2">
                            <div className="flex flex-1 flex-col">
                                <h1 className="text-xl md:text-md uppercase font-bold">Redis</h1>
                                <p className="font-light text-sm capitalize">caching server</p>
                            </div>

                            <div className="flex flex-1 uppercase text-sm">
                                <div className="flex gap-2">
                                    <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Active</p>
                                </div>
                                <div className="hidden">
                                    <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Stopped</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center flex-col md:flex-row gap-2 md:gap-0">
                            <div className="flex flex-1 gap-1">
                                <button className="w-10 h-10 text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full p-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full p-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-stop"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full p-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                            </div>

                            <div className="flex-1 flex justify-end">
                                <button className="button-red" type="submit">Uninstall</button>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border border-custom-green rounded-xl gap-4 flex justify-between items-center">
                        <div className="flex flex-1 items-center md:gap-0 gap-2">
                            <div className="flex flex-1 flex-col">
                                <h1 className="text-xl md:text-md uppercase font-bold">Memcache</h1>
                                <p className="font-light text-sm capitalize">caching server</p>
                            </div>

                            <div className="hidden flex-1 uppercase text-sm">
                                <div className="flex gap-2">
                                    <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Active</p>
                                </div>
                                <div className="hidden">
                                    <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                    <p className="hidden lg:block">Stopped</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 items-center flex-col md:flex-row gap-2 md:gap-0">
                            <div className="hidden flex-1 gap-1">
                                <button className="w-10 h-10 text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full p-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full p-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-stop"></i></button>
                                <button className="w-10 h-10 text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full p-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                            </div>

                            <div className="flex-1 flex justify-end">
                                <button className="button1" type="submit">Install</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default ServicesManager;
