import effortlessManagedHostingBg from '../../../../../images/effortless-hosting.png';

function EffortlessManagedHosting() {
    
    return (
        <div className="px-5 lg:px-20 py-10 lg:py-20">
            <div className='text-center flex flex-col justify-center items-center text-white'>
                <h2 className='text-2xl lg:text-3xl py-1 px-8 bg-black'>Effortless Managed Cloud Hosting</h2>
                <h2 className='mt-5 text-2xl lg:text-3xl py-1 px-8 bg-black'>Focus on Code, We Handle the Rest</h2>
            </div>
            <div className="lg:py-20 bg-contain bg-no-repeat bg-center flex flex-col items-center justify-center gap-20" style={{ backgroundImage: `url(${effortlessManagedHostingBg})` }}>
                <h3 className='text-2xl text-center'>Deploy Seamlessly Across Global Cloud Providers with Comprehensive Managed Services</h3>
                <p className='leading-8 text-center lg:w-[60%]'>Experience a new era of managed cloud hosting where you can focus  entirely on your code while we handle all the complexities of server  setup, configuration, and management. Utilizing a <span className='bg-black text-white'>MicroServices  Architecture,</span> we ensure your applications are modular, scalable, and  resilient. Deploy your site effortlessly with our robust infrastructure  that spans global cloud providers, and enjoy enhanced security,  reliability, and scalability.</p>
            </div>
        </div>
    )
    
}

export default EffortlessManagedHosting;
