import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

function ServerDetails(userData) {
    const user = userData.userData;
    const server = userData.server;
    const serverDetails = userData.serverDetails;
    const serverStatus = userData.serverStatus;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = `Server Details - ${collectionName}`;
    }, [collectionName]);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sshPassword, setSshPassword] = useState("");
    const [strongPassword, setStrongPassword] = useState(false);
    const [error, setError] = useState("");
    const [copyLocation, setCopyLocation] = useState("");
    
    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?]).{8,}$/;
        return regex.test(password);
    };

    const handlePassword = (e) => {
        const inputValue = e.target.value;
        setSshPassword(inputValue);

        if (inputValue.length < 8) {
            setError('Password should be at least 8 characters long');
            setStrongPassword(false);
        } else {
            if (!isStrongPassword(inputValue)) {
                setError('Password should contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character');
                setStrongPassword(false);
            } else {
                setError('');
                setStrongPassword(true);
            }
        };
    };

    const changeSSHPassword = () => {
        // Handle change SSH password logic here
        console.log("Changing SSH user password");
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    }

    const togglePasswordVisibility2 = () => {
        setIsPasswordVisible2(prevState => !prevState);
    }

    const handleCopy = (location, textToCopy) => {
        if (window.isSecureContext && navigator.clipboard) {
            // Secure context (HTTPS)
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setCopyLocation(location);
                    setTimeout(() => setCopyLocation(""), 2000);
                })
                .catch(err => {
                    console.error('Failed to copy in secure context: ', err);
                });
        } else {
            // Insecure context (HTTP) - Fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                textArea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                textArea.style.opacity = "0";  // Hide the textarea element
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
    
                setCopyLocation(location);
                setTimeout(() => setCopyLocation(""), 2000);
            } catch (err) {
                console.error('Failed to copy in insecure context: ', err);
            }
        }
    };

    const handleAction = async (action) => {
        setIsLoading(true);
        const apiMap = {
            start: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/startEC2Instance`,
            stop: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/stopEC2Instance`,
            restart: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/rebootEC2Instance`
        };

        const payload = {
            poorvaID: user.poorvaID,
            serverName: server.serverName
        };

        try {
            const response = await axios.post(apiMap[action],
                payload,
                {withCredentials: true}
            );
            console.log(response.data);
            alert(`Server ${action}ed successfully.`);
        } catch (error) {
            console.error(`Error ${action}ing the server:`, error);
            alert(`Failed to ${action} the server.`);
        } finally {
            setIsLoading(false);
        }
    };

    // console.log("SERVER: ", server);

    return (
        <>      
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Server Details
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">Server Details</h1>
                <p className="font-light">Basic information, access details, power options, etc.</p>
            </div>
            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">
                    <h1 className="text-md font-bold uppercase mb-2">Basic Details</h1>
                    <table className="text-sm">
                        <tbody>
                            <tr>
                                <td>Server Name:</td>
                                <td>{server.serverName}</td>
                            </tr>
                            <tr>
                                <td>OS:</td>
                                <td>{server.operatingSystem.replace("-", " ")}</td>
                            </tr>
                            <tr>
                                <td>Memory:</td>
                                <td>{serverDetails?.details?.Memory}</td>
                            </tr>
                            <tr>
                                <td>Storage:</td>
                                <td>{server.volumeSize} GB</td>
                            </tr>
                            <tr>
                                <td>CPU:</td>
                                <td>{serverDetails?.details?.Core}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h1 className="text-md font-bold uppercase mb-2 mt-8">Access Details (SSH & SFTP)</h1>
                    <table className="text-sm">
                        <tbody>
                            {/* <tr>
                                <td>Hostname:</td>
                                <td>{server.hostName}</td>
                            </tr> */}
                            <tr>
                                <td>IP Address:</td>
                                <td>{server.publicIpAddress}</td>
                                <td><i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("sshpassword", server.publicIpAddress)}></i></td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>{server.sshSftpUser}</td>
                                <td><i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("sshpassword", server.sshSftpUser)}></i></td>
                            </tr>
                            <tr>
                                <td className="w-[30%]">Password:</td>
                                <td className="">
                                    <input className="w-[50%] sm:text-sm" type={isPasswordVisible ? "text" : "password"} value={server.sshSftpPassword} id="password" disabled />
                                </td>
                                <td className="flex justify-start items-center">
                                    <i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("sshpassword", server.sshSftpPassword)}></i>
                                    <i onClick={togglePasswordVisibility} className={isPasswordVisible ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {copyLocation === "sshpassword" && <div className="text-custom-green text-sm">Copied!</div>}
                    
                    <h1 className="text-md font-bold uppercase mb-2 mt-8">Change SSH/SFTP Password</h1>
                    <div className="text-sm flex flex-col">
                        <div className="flex md:w-[50%] lg:w-full items-center">
                            <input
                                className="h-8 w-[70%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                placeholder="New password"
                                type={isPasswordVisible2 ? "text" : "password"}
                                id="changePassword"
                                onChange={handlePassword}
                                value={sshPassword}
                            />
                            <i onClick={togglePasswordVisibility2} className={isPasswordVisible2 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                        </div>
                        {error && error.includes("Password") && (
                            <div className='w-full mt-2'>
                                <p className='text-red-700 text-xs text-left font-bold'>{error}</p>
                            </div>
                        )}
                        <div className="flex mt-2">
                            <button
                                className={`${!strongPassword || error || serverStatus !== "running" ? "opacity-50" : ""} elastic-button1 px-6 uppercase`}
                                disabled={!strongPassword || error || serverStatus !== "running"}
                                type="submit"
                                onClick={changeSSHPassword}
                            >Change
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-1 p-5">

                    <div className="">
                        <h1 className="text-md font-bold uppercase mb-2">Server Actions (Power Buttons):</h1>
                        <div className="flex justify-between gap-1">
                            <button className="text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-[6px] w-[33%] py-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" onClick={() => handleAction('start')} disabled={isLoading || serverStatus === "running"} title="Start"><i className="fa-solid fa-play"></i></button>
                            <button className="text-center border-2 border-red-700 bg-transparent text-red-700 rounded-[6px] w-[33%] py-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" onClick={() => handleAction('stop')} disabled={isLoading || serverStatus !== "running"} title="Shutdown"><i className="fa-solid fa-power-off"></i></button>
                            <button className="text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-[6px] w-[33%] py-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" onClick={() => handleAction('restart')} disabled={isLoading || serverStatus !== "running"} title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ServerDetails;
