import React from "react";
import { useParams, useNavigate } from "react-router-dom";

function ServerOptions(props) {
    const { collectionName } = useParams();
    const navigate = useNavigate();
    const activeTab = props.activeServerTab;

    const handleSelectedOption = (optionValue) => {
        navigate(`/dashboard/manageserver/${collectionName}?${optionValue}`);
    };

    return (
        <div className="text-black font-light px-5 lg:px-0 lg:pb-20 lg:pl-20 lg:pr-5 w-full">
            <div className="block items-center">
                <ul className="lg:text-left text-center font-light capitalize flex flex-col items-center w-full">
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("serverDetails")} className={`${activeTab === 'serverDetails' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Server Details</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("appManager")} className={`${activeTab === 'appManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Application Manager</div></li>
                    {/* <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("dbManager")} className={`${activeTab === 'dbManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Database Manager</div></li> */}
                    {/* <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("phpMaager")} className={`${activeTab === 'phpManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>PHP Manager</div></li> */}
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("monitoring")} className={`${activeTab === 'monitoring' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Monitoring and Logs</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("cronJobs")} className={`${activeTab === 'cronJobs' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Cron Jobs</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("sslCertificates")} className={`${activeTab === 'sslCertificates' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>SSL Certificates</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("backupRestore")} className={`${activeTab === 'backupRestore' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Backup and Restore</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("servicesManager")} className={`${activeTab === 'servicesManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Service Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("upgradePlan")} className={`${activeTab === 'upgradePlan' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Change Plan</div></li>
                </ul>
            </div>
        </div>
    )

}

export default ServerOptions;