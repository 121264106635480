import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function CronJobs(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = "Cron Jobs - Manage your scheduled tasks";
    }, []);
    
    return (
        <>
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Cron Jobs
                </p>
            </div>
            
            {/* The content goes here */}
            <div>
                <h1 className="text-xl uppercase font-bold">Cron Jobs</h1>
                <p className="font-light">Manage your schduled tasks here. You should have basic knowledge of cron jobs.</p>
            </div>

            <div className="flex flex-col mt-10">
                <div className="flex flex-col px-5">
                    <h1 className="text-md font-bold uppercase mb-4">Add New Cron Job</h1>

                    <label htmlFor="cron-minute" className="text-xs font-bold uppercase mb-1">Minute:</label>
                    <input type="text" id="cron-minute" name="cron-minute" placeholder="Minute" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />

                    <label htmlFor="cron-hour" className="text-xs font-bold uppercase mb-1">Hour:</label>
                    <input type="text" id="cron-hour" name="cron-hour" placeholder="Minute" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />

                    <label htmlFor="cron-day" className="text-xs font-bold uppercase mb-1">Day:</label>
                    <input type="text" id="cron-day" name="cron-day" placeholder="Day" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />

                    <label htmlFor="cron-month" className="text-xs font-bold uppercase mb-1">Month:</label>
                    <input type="text" id="cron-month" name="cron-month" placeholder="Month" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />

                    <label htmlFor="cron-weekday" className="text-xs font-bold uppercase mb-1">Weekday:</label>
                    <input type="text" id="cron-weekday" name="cron-weekday" placeholder="Weekday" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />
                    
                    <label htmlFor="cron-command" className="text-xs font-bold uppercase mt-4 mb-1">Command:</label>
                    <input type="text" id="cron-command" name="cron-command" placeholder="Command" className="mb-1 w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md p-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" />
                    
                    <div className="flex">
                        <button className="button1 mt-4" id="addCron" type="submit">Add</button>
                    </div>
                </div>

                <div className="mt-8 px-5 overflow-x-auto">
                    <h1 className="text-md font-bold uppercase mb-4">Current cron jobs</h1>
                    <table className="w-[250%] sm:w-auto table-auto">
                        <thead className="">
                            <tr className="bg-gray-300 border-t border-b border-gray-300">
                                {/* Padding is not working on "tr" so it's implemented on "th" and "td" */}
                                <th className="pl-4 py-2 w-[20%]">Schedule</th>
                                <th>Command</th>
                                <th className="w-[20%]"></th>
                            </tr>
                        </thead>
                        
                        <tbody className="">
                            <tr className="p-4 border-b border-gray-300">
                                <td className="pl-4 py-2">*/5 * * * *</td>
                                <td>/usr/bin/php /home/user/turfdev.in/public/bin/magento cache:flush &gt; /dev/null 2&gt;&1</td>
                                <td className="text-2xl text-right pr-4">
                                    <i className="fa-solid fa-pen-to-square cursor-pointer mr-4 hover:scale-105" title="Edit"></i>
                                    <button className="fa-solid fa-play cursor-pointer mr-4 text-green-700 hover:scale-105" title="Run now" type="submit"></button>
                                    <button className="fa-solid fa-trash-can cursor-pointer text-red-700 hover:scale-105" title="Delete" type="submit"></button>
                                </td>
                            </tr>
                            <tr className="p-4 border-b border-gray-300">
                                <td className="pl-4 py-2">15 14 * * *</td>
                                <td>ping &gt; /dev/null 2&gt;&1</td>
                                <td className="text-2xl text-right pr-4">
                                    <i className="fa-solid fa-pen-to-square cursor-pointer mr-4 hover:scale-105" title="Edit"></i>
                                    <button className="fa-solid fa-play cursor-pointer mr-4 text-green-700 hover:scale-105" title="Run now" type="submit"></button>
                                    <button className="fa-solid fa-trash-can cursor-pointer text-red-700 hover:scale-105" title="Delete" type="submit"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CronJobs;
