import logoGcp from '../../../../../images/logo-gcp.png';
import logoVultr from '../../../../../images/logo-vultr.png';
import logoAws from '../../../../../images/logo-aws.png';
import logoLinode from '../../../../../images/logo-linode.png';
import logoDigitalOcean from '../../../../../images/logo-digitalOcean.png';
import logoAMSAzure from '../../../../../images/logo-MSAzure.png';

import logoWordPress from '../../../../../images/logo-wordpress.png';
import logoOpenCart from '../../../../../images/logo-opencart.png';
import logoPHP from '../../../../../images/logo-php.png';
import logoMagento from '../../../../../images/logo-magento.png';
import logoDrupal from '../../../../../images/logo-drupal.png';
import logoWoo from '../../../../../images/logo-woocommerce.png';
import logoJoomla from '../../../../../images/logo-joomla.png';
import logoLaravel from '../../../../../images/logo-laravel.png';
import logoPresta from '../../../../../images/logo-PrestaShop.png';

function SelectYourPlatform() {
    
    return (
        <div className="px-5 lg:px-40 py-10 flex flex-col lg:flex-row justify-center items-center lg:gap-8 gap-20">
            <div className='flex-1 lg:text-left text-center'>
                <h2 className='text-2xl lg:text-3xl' style={{ textShadow: '2px 3px 5px rgba(0,0,0,0.30)' }}>Select Your Platform.</h2>
                <h3 className='text-xl lg:text-2xl text-custom-green' style={{ textShadow: '2px 3px 5px rgba(0,0,0,0.30)' }}>Embrace the Cloud.</h3>
                <p className='mt-10'>Empower Your Applications with Versatile Cloud Solutions, Prioritizing Flexibility, Performance, and Reliability Excellence.</p>
            </div>
            <div className='flex-1 flex flex-col justify-center items-center gap-8 lg:border-r border-black border-opacity-50'>
                <h5 className='uppercase text-center'>Cloud <br/>Providers</h5>
                <div className='w-16 flex flex-col gap-8 justify-center items-center'>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoGcp} />
                        <img alt="" src={logoVultr} />
                    </div>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoAws} />
                        <img alt="" src={logoLinode} />
                    </div>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoDigitalOcean} />
                        <img alt="" src={logoAMSAzure} />
                    </div>
                </div>
            </div>
            <div className='flex-1 flex flex-col justify-center items-center gap-8'>
                <h5 className='uppercase text-center'>Applications</h5>
                <div className='w-16 flex flex-col gap-8 justify-center items-center'>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoWordPress} />
                        <img alt="" src={logoOpenCart} />
                        <img alt="" src={logoPHP} />
                    </div>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoMagento} />
                        <img alt="" src={logoDrupal} />
                        <img alt="" src={logoWoo} />
                    </div>
                    <div className='flex gap-8 justify-center items-center'>
                        <img alt="" src={logoJoomla} />
                        <img alt="" src={logoLaravel} />
                        <img alt="" src={logoPresta} />
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default SelectYourPlatform;
