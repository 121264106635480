import React from "react";
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import SupportImg from '../../../images/24x7-support.png';
import Commitment from '../../../images/commitment.png';
import ContactUs from '../../../images/contact-us.png';
import Team from '../../../images/team.png';
import Feedback from '../../../images/feedback.png';

function Support(userData) {
    const user = userData.user;

    return (
        <>
            <Header userData={user}/>

            <div className="px-5 py-20 md:px-20 md:py-32 text-center">
                <h1 className="md:text-[90px] text-[50px] font-bold leading-tight tracking-tighter mb-5">Your Support,<br/><span className="text-custom-green">Anytime, Anywhere</span></h1>
                <p>Elevating Your Experience with 24/7/365 Assistance.</p>    
            </div>

            <div className="flex p-5 md:px-20 md:py-5 flex-col-reverse lg:flex-row justify-center items-center gap-4">
                <div className="flex flex-1 justify-center gap-8 flex-col">
                    <h1 className="text-2xl font-bold">24/7/365 Support</h1>
                    <p className="">At Turfdev, we are dedicated to providing exceptional support to our valued customers around the clock. We understand that your needs may arise at any hour, which is why we offer continuous support through our ticket and chat systems. Whether you have a pressing issue, a question, or need guidance, our team is here to assist you at all times.</p>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    <img className="md:w-[80%]" src={SupportImg} alt="" />
                </div>
            </div>

            <div className="flex p-5 md:px-20 md:py-5 flex-col-reverse lg:flex-row-reverse justify-center items-center gap-4">
                <div className="flex flex-1 justify-center gap-8 flex-col">
                    <h1 className="text-2xl font-bold">Our Commitment to You</h1>
                    <ul className="list-disc disc-custom-green leading-6 text-left flex flex-col gap-2">
                        <li><span className="font-bold text-custom-green">24/7 Availability: </span>We take pride in being available 24 hours a day, 7 days a week, and 365 days a year. No matter when you need us, our support team is just a click away.</li>
                        <li><span className="font-bold text-custom-green">Prompt Ticket Responses: </span>Submit a support ticket, and we'll promptly assess your request. Our team will work diligently to provide a timely resolution, ensuring that your concerns are addressed efficiently.</li>
                        <li><span className="font-bold text-custom-green">Real-Time Chat Support: </span>For those times when you require immediate assistance, our live chat feature allows you to connect with a support representative in real-time. We're here to guide you through any issues you encounter.</li>
                    </ul>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    <img className="md:w-[80%]" src={Commitment} alt="" />
                </div>
            </div>

            <div className="flex p-5 md:px-20 md:py-5 flex-col-reverse lg:flex-row justify-center items-center gap-4">
                <div className="flex flex-1 justify-center gap-8 flex-col">
                    <h1 className="text-2xl font-bold">How to Reach Us</h1>
                    <ul className="list-disc disc-custom-green leading-6 text-left flex flex-col gap-2">
                        <li><span className="font-bold text-custom-green">Support Tickets: </span>Submit a support ticket through our user-friendly platform. Describe your issue, and our team will start working on a solution right away.</li>
                        <li><span className="font-bold text-custom-green">Live Chat: </span>Click on the chat icon located on our website. Our live chat agents are ready to engage with you, answer questions, and assist you in real-time.</li>
                        <li><span className="font-bold text-custom-green">Phone Support: </span>Have an urgent matter that needs immediate attention? You can also reach us by phone. Call our dedicated support hotline to speak with a support representative.</li>
                    </ul>
                    <div className="flex lg:flex-row flex-col gap-4 justify-center">
                        <Link to="/dashboard/contactsupport" className="elastic-button1 px-4 gap-2"><i className="fa-solid fa-ticket"></i> Raise a Ticket</Link>
                        <Link to="/dashboard/contactsupport" className="elastic-button2 px-4 gap-2"><i className="fa-solid fa-message"></i>Start a Chat</Link>
                        <Link to="/dashboard/contactsupport" className="elastic-button1 px-4 gap-2"><i className="fa-solid fa-headset"></i>Call Support</Link>
                    </div>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    <img className="md:w-[80%]" src={ContactUs} alt="" />
                </div>
            </div>

            <div className="flex p-5 md:p-20 flex-col-reverse lg:flex-row-reverse justify-center items-center gap-4">
                <div className="flex flex-1 justify-center gap-8 flex-col">
                    <h1 className="text-2xl font-bold">Our Expert Team</h1>
                    <p>Our support team consists of highly skilled professionals who are well-versed in our products and services. They are dedicated to providing you with the best assistance and solutions, ensuring that your experience with us remains exceptional.</p>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    <img className="" src={Team} alt="" />
                </div>
            </div>

            <div className="flex p-5 md:p-20 flex-col-reverse lg:flex-row justify-center items-center gap-4">
                <div className="flex flex-1 justify-center gap-8 flex-col">
                    <h1 className="text-2xl font-bold">Customer Feedback</h1>
                    <p>We value your feedback and continuously strive to improve our support services. If you have any suggestions or comments regarding your experience with our support team, we'd love to hear from you.</p>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    <img className="" src={Feedback} alt="" />
                </div>
            </div>

            <div className="flex justify-center items-center flex-col p-5 md:p-20 gap-4">
                <h1 className="text-3xl font-bold text-custom-green">Contact Us</h1>
                
                <ContactForm />
            </div>

            <Footer />
            {/* <Scripts /> */}
        </>
    )
}

export default Support;