import whatMakesUniqueImg from '../../../../../images/what-makes-unique.png'

function WhatMakesUnique() {
    
    return (
        <div className='lg:mt-10 px-5 lg:px-20 py-20 flex flex-col-reverse lg:flex-row-reverse gap-12'>
            <div className='lg:px-10 flex justify-center items-center flex-col gap-8 text-center'>
                <h2 className='lg:px-8 py-2 bg-black text-white text-2xl'>What Makes Poorva Cloud Unique?</h2>
                <h2 className='text-xl'><span className='text-custom-green'>Catalysts of Innovation:</span> Unveiling Poorva Cloud's Microservices Magic</h2>
                <p>At Poorva Cloud, our commitment to innovation shines through our  pioneering use of microservices architecture. This revolutionary  approach forms the cornerstone of our uniqueness, enabling unparalleled  agility, scalability, and efficiency in cloud solutions. With  microservices at our core, we empower businesses to swiftly adapt to  evolving demands, ensuring optimal performance and reliability at every  turn.</p>
            </div>

            <div className=''>
                <img src={whatMakesUniqueImg} alt="" />
            </div>
        </div>
    )
    
}

export default WhatMakesUnique;
