import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0";
import axios from 'axios';
import Copyright from "../components/Copyright";

function ChangePassword(userData) {
    const user = userData.user;
    // console.log(user);
    useEffect(() => {
        document.title = "Change User's Password";
    }, []);

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [errorMessage, setErrorMessage] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [password, setPassword] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?]).{8,}$/;
        return regex.test(password);
    };
    
    const handleChange = (e) => {
        let errMessage = '';
        const { name, value } = e.target;

        if (name === 'currentPassword' && value.length === 0) {
            errMessage = 'Current password is required';
        }

        if (name === 'newPassword') {
            if (value.length === 0) {
                errMessage = 'New Password is required';
            } else if (!isStrongPassword(value)) {
                errMessage = 'Password should contain at least 1 uppercase, 1 lowercase, 1 special character, and 1 number, and be at least 8 characters long.';
            };
        };

        if (name === 'confirmPassword') {
            if (value.length === 0) {
                errMessage = 'Confirm the password';
            } else if (value !== password.newPassword) {
                errMessage = 'Passwords do not match';
            };
        };

        setErrorMessage({
            ...errorMessage,
            [name]: errMessage || '',
        });

        setPassword({
            ...password,
            [name]: value,
        });
    }

    const generateRandomPassword = () => {
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";
        const specialChars = "!@#$%^&*()_-+=<>?";
    
        const getRandomChar = (charSet) => {
            const randomIndex = Math.floor(Math.random() * charSet.length);
            return charSet.charAt(randomIndex);
        };
    
        let password = '';
    
        // Ensure at least one uppercase, one lowercase, and one number
        password += getRandomChar(upperCaseChars);
        password += getRandomChar(lowerCaseChars);
        password += getRandomChar(numbers);
        password += getRandomChar(specialChars);
    
        // Generate the rest of the password
        for (let i = 0; i < 9; i++) {
            const randomCharSet = upperCaseChars + lowerCaseChars + numbers + specialChars;
            password += getRandomChar(randomCharSet);
        }
    
        // Shuffle the password characters
        password = password.split('').sort(() => Math.random() - 0.5).join('');
    
        return password;
    };
    

    const handleGeneratePassword = () => {
        const generatedPassword = generateRandomPassword();
        
        setPassword({
            ...password,
            newPassword: generatedPassword,
            confirmPassword: generatedPassword,
        });

        if (!isStrongPassword(generatedPassword)) {
            setErrorMessage({
                ...errorMessage,
                newPassword: 'Password should contain at least 1 uppercase, 1 lowercase, 1 special character, and 1 number, and be at least 8 characters long.',
                confirmPassword: '',
            });
        } else {
            setErrorMessage({
                ...errorMessage,
                newPassword: '',
                confirmPassword: '',
            });
        };
        // calculatePasswordStrength(); // Update password strength when generating a new password
    };

    useEffect(() => {
        const hasErrors = Object.values(errorMessage).some((error) => error !== '');
        const allFieldsFilled = Object.values(password).every((field) => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled || hasErrors);
    }, [password, errorMessage]);

    const handleSignOut = async () => {
        try {
            const signoutApiUrl = `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/signout`;
            await axios.get(signoutApiUrl, { withCredentials: true, })
            
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.log("An error occurred during signout: ", error);
        };
    };

    const handleChangePassword = async () => {
        setError('');
        setMessage('');

        if (password.newPassword !== password.confirmPassword) {
            setErrorMessage({
                ...errorMessage,
                newPassword: '',
                confirmPassword: 'Passwords do not match',
            });
            return;
        }
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/change-password`, password, {
                withCredentials: true   
            });

            // console.log('Response: ', res.data.message);
            setMessage(res.data.message);
            await handleSignOut();
        } catch (error) {
            // console.log(error);
            setError(error.response.data.message);
        }

        if (error) {
            setTimeout(() => {
                setError('');
            }, 5000); // 5000 milliseconds = 5 seconds
        }
        
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div>
            <DashboardHeader userData={user}/>
            <div className="flex lg:flex-row flex-col">
                <div className="lg:w-[27%]">
                    <DashboardOptions0 />
                </div>
                <div className="h-screen overflow-y-auto lg:w-[73%] text-black">
                    {user.googleID &&
                        <div className="lg:w-[70%] w-full backdrop-blur-lg h-screen absolute z-30 flex justify-center items-center">
                            <p className="text-black font-bold md:text-xl lg:mt-0 -mt-64">Google users can't change password</p>
                        </div>
                    }
                    <div className="flex flex-col p-5">
                        <div className="p-5 text-white text-center bg-custom-green rounded-t rounded-b-none shadow-custom3">
                            <h1 className="text-xl text-center uppercase">Change User Password</h1>
                        </div>
                        <div className="flex justify-center lg:px-20 lg:py-5 p-5 text-left bg-white rounded-b rounded-t-none shadow-custom3">
                            <div className="sm:w-[60%] flex flex-col justify-center gap-4">
                                <div>
                                    <label htmlFor="currentPassword" className="text-xs font-bold uppercase">Current Password</label>
                                    <input
                                        className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        placeholder="Current Password"
                                        type='password'
                                        // type={passwordVisible ? "text" : "password"}
                                        // value={currentPassword}
                                        value={password.currentPassword}
                                        // onChange={(e) => setcurrentPassword(e.target.value)}
                                        onChange={handleChange}
                                        name="currentPassword"
                                        id="currentPassword"
                                    />
                                </div>
                                {errorMessage.currentPassword && (
                                    <div className="w-full flex justify-start -mt-2">
                                        <p className="text-red-500 text-xs">{errorMessage.currentPassword}</p>
                                    </div>
                                )}

                                <div>
                                    <label htmlFor="newPassword" className="text-xs font-bold uppercase">New Password</label>
                                    <div className="flex items-center">
                                        <input
                                            className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            placeholder="New Password"
                                            type={passwordVisible ? "text" : "password"}
                                            // value={newPassword}
                                            value={password.newPassword}
                                            // onChange={handlePasswordStrength}
                                            onChange={handleChange}
                                            name="newPassword"
                                            id="newPassword"
                                        />
                                        <i onClick={togglePasswordVisibility} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                    </div>
                                    {errorMessage.newPassword && (
                                        <div className="w-full flex justify-start mt-1">
                                            <p className="text-red-500 text-xs">{errorMessage.newPassword}</p>
                                        </div>
                                    )}

                                    <div className="flex sm:items-center sm:flex-row flex-col-reverse items-start mt-1 gap-2">
                                        <button
                                            type="submit"
                                            onClick={handleGeneratePassword}
                                            className="px-2 py-1 border border-black rounded hover:shadow-custom3"
                                        >
                                            <i className="fa-solid fa-rotate"></i> Generate Password
                                        </button>
                                        {/* <div className="flex border border-color3 h-2 flex-1 rounded-full w-full">
                                            <div className="rounded-full"
                                                style={{
                                                    width: `${passwordStrength}%`,
                                                    backgroundColor: `rgba(${255 - Math.round((passwordStrength / 100) * 255)}, ${Math.round((passwordStrength / 100) * 255)}, 0, 1)`,
                                                    transition: 'width 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                                }}
                                            ></div>
                                        </div> */}
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="confirmPassword" className="text-xs font-bold uppercase">Confirm Password</label>
                                    <input
                                        className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        placeholder="Confirm Password"
                                        // type={passwordVisible ? "text" : "password"}
                                        type='password'
                                        value={password.confirmPassword}
                                        // value={confirmPassword}
                                        // onChange={(e) => setConfirmPassword(e.target.value)}
                                        // onChange={checkEqualPassword}
                                        onChange={handleChange}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                    />
                                </div>
                                {errorMessage.confirmPassword && (
                                    <div className="w-full flex justify-start -mt-2">
                                        <p className="text-red-500 text-xs">{errorMessage.confirmPassword}</p>
                                    </div>
                                )}

                                <div className="flex gap-2 md:flex-row flex-col mt-8">
                                    <button onClick={handleChangePassword}
                                        className={`${isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''} elastic-button1 w-full`}
                                        type="submit"
                                        disabled={isButtonDisabled}
                                    >
                                        Change Password
                                    </button>
                                    {/* <button className="flex flex-1 justify-center items-center w-full border border-color1 bg-color1 hover:bg-transparent hover:text-color1 px-8 py-2 rounded-full text-white transition duration-150 ease-out" type="submit">
                                        Cancel
                                    </button> */}
                                </div>
                                <div>
                                    <h3 className="text-sm text-center text-green-500 font-bold"> {message} </h3>
                                </div>
                                <div>
                                    <h3 className="text-sm text-center text-red-500 font-bold"> {error} </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    );
}

export default ChangePassword;