import yourPathToSuccessImg from '../../../../../images/your-path-to-success.png';

function YourPathToSuccess() {

    return (
        <div className='px-5 lg:px-20 py-10'>
            <h2 className='text-3xl lg:text-4xl text-custom-green text-center' style={{ textShadow: '2px 3px 5px rgba(0,0,0,0.30)' }}>Your Path to Success:</h2>
            <h3 className='mt-5 text-2xl text-center'>AI-Powered Journey to Digital Triumph with Managed Cloud Hosting Begins Here...</h3>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className='lg:w-[40%]'>
                    <img src={yourPathToSuccessImg} alt="Your Path to Success" />
                </div>
                <div className='lg:w-[60%] lg:pl-20'>
                    <ul className="pl-5 list-disc disc-custom-green flex flex-col gap-8">
                        <li><span className='font-bold text-custom-green'>AI-Powered Streamlined Infrastructure:</span> Optimize operations, prioritize business expansion effortlessly.</li>
                        <li><span className='font-bold text-custom-green'>Scalability on Demand:</span> Grow seamlessly, adjust resources dynamically as needed.</li>
                        <li><span className='font-bold text-custom-green'>Robust Security Measures:</span> Ensure data and application protection with effective safeguards.</li>
                        <li><span className='font-bold text-custom-green'>Expert Support 24/7/365: </span> Access assistance anytime, ensuring uninterrupted and efficient operations.</li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default YourPathToSuccess;
