import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

function ListTickets(userData) {
    // const navigate = useNavigate()
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Poorva Cloud - Support tickets";
    }, []);

    const selectedItem0 = 'support';
    const selectedItem2 = 'mytickets';

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} selectedItem2={selectedItem2} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden md:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} selectedItem2={selectedItem2}/>
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className="bg-white text-black flex flex-col gap-2 m-5 md:shadow-custom3 rounded lg:pl-5 lg:pr-20 pr-5 items-start">
                        
                        <div className="w-full">
                            <h1 className="uppercase text-xl font-bold text-left">Recent Tickets</h1>
                            
                            {/* <table className="w-[200%] sm:w-full sm:table-fixed table-auto mt-4 text-left">
                                <thead className="">
                                    <tr className="bg-color2 text-white border-t border-b border-color3">
                                        <th className="pl-4 py-2">Department</th>
                                        <th>Subject</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    <tr className="p-4 border-b border-color3 cursor-pointer">
                                        <td className="pl-4 py-2 font-bold">Technical</td>
                                        <td className="">
                                            <p className="font-bold">#TD78019723</p>
                                            <p className="">Need help setting up WordPress</p>
                                        </td>
                                        <td className="font-bold">Answered</td>
                                    </tr>
                                </tbody>
                            </table> */}
                            <div className="w-full mt-4 px-5">
                                <div className="text-left flex text-white p-2 bg-black">
                                    <div className="hidden md:flex flex-1">
                                        <h3 className="font-bold uppercase">Department</h3>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="font-bold uppercase">Subject</h3>
                                    </div>
                                    <div className="hidden md:flex flex-1">
                                        <h3 className="font-bold uppercase">Status</h3>
                                    </div>
                                </div>
                                
                                <Link to="">
                                    <div className="text-left flex border-b border-gray-200 p-2 hover:bg-gray-100">
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Technical</h3>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-bold">#TD97103572</h3>
                                            <h3 className="">Need help setting up WordPress</h3>
                                        </div>
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Answered</h3>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="">
                                    <div className="text-left flex border-b border-gray-200 p-2 hover:bg-gray-100">
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Sales and Billing</h3>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-bold">#TD90120396</h3>
                                            <h3 className="">Raise an invoice for my TD-XLARGE server</h3>
                                        </div>
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold text-green-700">Open</h3>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="">
                                    <div className="text-left flex border-b border-gray-200 p-2 hover:bg-gray-100">
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Technical</h3>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-bold">#TD06163902</h3>
                                            <h3 className="">Website throwing a 500 internal server error</h3>
                                        </div>
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Closed</h3>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="">
                                    <div className="text-left flex border-b border-gray-200 p-2 hover:bg-gray-100">
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Technical</h3>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-bold">#TD02537529</h3>
                                            <h3 className="">Having problem creating a new domain</h3>
                                        </div>
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold text-red-500">Customer-Reply</h3>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="">
                                    <div className="text-left flex border-b border-gray-200 p-2 hover:bg-gray-100">
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold">Sales and Billing</h3>
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-bold">#TD72017423</h3>
                                            <h3 className="">Server is terminated</h3>
                                        </div>
                                        <div className="hidden md:flex flex-1">
                                            <h3 className="font-bold text-orange-500">In Progress</h3>
                                        </div>
                                    </div>
                                </Link>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <Copyright />
        </>
    )

}

export default ListTickets;
