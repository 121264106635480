import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function WebManager(userData) {
    // const user = userData.userData;
    const collectionName = userData.collection;
    const application = userData.appDetails;
    // console.log(application);

    useEffect(() => {
        document.title = `Manage your application - ${application.domain}`;
    }, []);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    }

    const togglePasswordVisibility2 = () => {
        setIsPasswordVisible2(prevState => !prevState);
    }
    
    return (
        <>
            <div className="flex mb-10 gap-2 items-center text-xs font-bold flex-wrap">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?appManager`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Application Details
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">Domain: {application.domain}</h1>
                <p className="font-light">This interface lets you manage your application with various options.</p>
            </div>

            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">
                    <h1 className="text-md font-bold uppercase mb-2">Basic Details</h1>
                    <table className="text-sm">
                        <tbody>
                            <tr>
                                <td>Software:</td>
                                <td>{application.appType}</td>
                            </tr>
                            <tr>
                                <td>Document Root:</td>
                                <td>/home/user/turfdev.in/public</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <h1 className="text-md font-bold uppercase mb-2 mt-8">Database Details</h1>
                    <table className="text-sm">
                        <tbody>
                            <tr>
                                <td>Database Name:</td>
                                <td>td_data</td>
                            </tr>
                            <tr>
                                <td>User:</td>
                                <td>td_dbuser</td>
                            </tr>
                            <tr>
                                <td>Password:</td>
                                <td>
                                    <input className="justify-center items-center bg-transparent w-[70%]" type={isPasswordVisible ? "text" : "password"} value={isPasswordVisible ? "Turf@My" : "*****************"} id="password" disabled />
                                    <i onClick={togglePasswordVisibility} className={isPasswordVisible ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h1 className="text-md font-bold uppercase mb-2 mt-8">Change Database User's Password</h1>
                    <div className="text-sm flex flex-col">
                        <div className=" items-center">
                            <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="New password" type={isPasswordVisible2 ? "text" : "password"} id="changePassword" />
                            <i onClick={togglePasswordVisibility2} className={isPasswordVisible2 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                        </div>
                        <div className="flex mt-2">
                            <button className="button1" type="submit">Change</button>
                        </div>
                    </div>

                    <div className="flex flex-col mt-2">
                        <p className="text-md font-bold uppercase mb-2 mt-8">Current PHP Version</p>
                        <select className="md:w-[50%] border-2 border-custom-green bg-black text-white rounded-md px-1 py-[5.5px]">
                            <option value="php74">PHP 7.4</option>
                            <option value="php80">PHP 8.0</option>
                            <option value="php81">PHP 8.1</option>
                            <option value="php82">PHP 8.2</option>
                        </select>
                        <div className="flex text-sm">
                            <button className="mt-2 button1" type="submit">Change</button>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col lg:flex-1 p-5">
                    <h1 className="text-md font-bold uppercase">Access Database:</h1>
                    <p className="text-sm">A graphical interface to manage your database.</p>
                    <div className="flex justify-between mt-2">
                        <button className="elastic-button1 w-40" type="submit" title="Launch">phpMyAdmin</button>
                    </div>

                    <h1 className="text-md font-bold uppercase mt-8">Container Service:</h1>
                    <p className="text-sm">The container service for your domain, if it's powered off the website will go down.</p>
                    <div className="flex justify-between mt-2 gap-2">
                        <button className="text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-[6px] w-[33%] py-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                        <button className="text-center border-2 border-red-700 bg-transparent text-red-700 rounded-[6px] w-[33%] py-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-power-off"></i></button>
                        <button className="text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-[6px] w-[33%] py-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                    </div>

                    <div className="mt-8 flex gap-2 flex-col">
                        <div>
                            <h1 className="text-md font-bold uppercase">Delete Website with its Data:</h1>
                            <p className="text-sm">WARNING: You will lose all the data associated with this domain, this action can't be reverted.</p>
                        </div>
                        <button className="sm:w-[60%] text-center bg-red-700 text-white rounded-[6px] py-2 px-10 hover:shadow-custom3" type="submit">Delete Website</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default WebManager;
