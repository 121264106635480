import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

function ServerOptions(props) {
    const { collectionName } = useParams();
    const navigate = useNavigate();
    // const location = useLocation();
    const application = props.application;
    const activeTab = props.activeAppTab;

    const handleSelectedOption = (optionValue) => {
        navigate(`/dashboard/manageserver/${collectionName}/app/${application.applicationName}?${optionValue}`);
    };

    return (
        <div className="text-black px-5 lg:px-0 lg:pt-10 lg:pb-20 lg:pl-20 lg:pr-5 w-full">
            <div className="flex">  
                <Link className="text-sm text-slate-600 hover:text-custom-green flex justify-start items-center gap-1"
                    to={`/dashboard/manageserver/${collectionName}?serverDetails`}
                    title="Server Management"
                >
                    <i className="fa-solid fa-arrow-left-long"></i>
                    <i className="ml-2 fa-solid fa-server"></i>
                    <p>Server Management</p>
                </Link>
            </div>
            <h1 className="lg:text-left text-center uppercase text-xl font-bold py-5 w-full">App Management</h1>
            <div className="block items-center">
                <ul className="lg:text-left text-center capitalize flex flex-col items-center w-full">
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("appDetails")} className={`${activeTab === 'appDetails' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Application Details</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("dbManager")} className={`${activeTab === 'dbManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Database Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("phpManager")} className={`${activeTab === 'phpManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>PHP Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("backupRestore")} className={`${activeTab === 'backupRestore' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Backup and Restore</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("cronJobs")} className={`${activeTab === 'cronJobs' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Cron Jobs</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("servicesManager")} className={`${activeTab === 'servicesManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Service Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("codeEditor")} className={`${activeTab === 'codeEditor' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Code Editor</div></li>
                </ul>
            </div>
        </div>
    )

}

export default ServerOptions;