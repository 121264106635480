import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios"
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";

// import PayPal from '../../../images/logo-paypal.png';

function Checkout(userData) {

    // SUBMIT DATA STARTS =======================================================
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [existingUserData, setExistingUserData] = useState({});

    const [user, setUsers] = useState({
        Firstname: "",
        Lastname: "",
        Email: "",
        Phone: "",
        Company: "",
        Flat: "",
        Area: "",
        City: "",
        District: "",
        State: "",
        Country: "",
        Pin: "",
        PayMethod: "",
        BillAdd: ""
    });

    // const [message, setSignupMessage] = useState("");

    const handleInputChange = e => {
        const { name, value } = e.target
        setUsers({
            ...user,
            [name]: value
        });
    };

    useEffect(() => {
        // Get the userId from localStorage
        const userId = localStorage.getItem('userId');
        if (!userId) {
            // Handle the case when the userId is not available in localStorage
            return;
        }

        // Make a GET request to fetch user data by ID
        axios.get(`https://apicloud.turfdev.in/api/listUsers/getbyID/${userId}`)
            .then((response) => {
                // Handle the response and set the user data in your component's state.
                setUsers(response.data);
                setExistingUserData(response.data);
            })
    }, []);


    // const handleSaveChanges = () => {
    //     const userId = localStorage.getItem('userId');
    //     const updatedUserData = {};

    //     // Check each field and update it if a new value is provided and it's different from the existing value
    //     if (user.Firstname && user.Firstname !== existingUserData.Firstname) {
    //         updatedUserData.Firstname = user.Firstname;
    //     }
    //     if (user.Lastname && user.Lastname !== existingUserData.Lastname) {
    //         updatedUserData.Lastname = user.Lastname;
    //     }
    //     if (user.Email && user.Email !== existingUserData.Email) {
    //         updatedUserData.Email = user.Email;
    //     }
    //     if (user.Phone && user.Phone !== existingUserData.Phone) {
    //         updatedUserData.Phone = user.Phone;
    //     }
    //     if (user.Company && user.Company !== existingUserData.Company) {
    //         updatedUserData.Company = user.Company;
    //     }
    //     if (user.Flat && user.Flat !== existingUserData.Flat) {
    //         updatedUserData.Flat = user.Flat;
    //     }
    //     if (user.Area && user.Area !== existingUserData.Area) {
    //         updatedUserData.Area = user.Area;
    //     }
    //     if (user.City && user.City !== existingUserData.City) {
    //         updatedUserData.City = user.City;
    //     }
    //     if (user.District && user.District !== existingUserData.District) {
    //         updatedUserData.District = user.District;
    //     }
    //     if (user.State && user.State !== existingUserData.State) {
    //         updatedUserData.State = user.State;
    //     }
    //     if (user.Country && user.Country !== existingUserData.Country) {
    //         updatedUserData.Country = user.Country;
    //     }
    //     if (user.Pin && user.Pin !== existingUserData.Pin) {
    //         updatedUserData.Pin = user.Pin;
    //     }
    //     if (user.PayMethod && user.PayMethod !== existingUserData.PayMethod) {
    //         updatedUserData.PayMethod = user.PayMethod;
    //     }
    //     // if (user.BillAdd && user.BillAdd !== existingUserData.BillAdd) {
    //     //     updatedUserData.BillAdd = user.BillAdd;
    //     // }

    //     // Check if there are any updates to save
    //     if (Object.keys(updatedUserData).length === 0) {
    //         setMessage("No changes to save , Refresh the page for updated Data"); // Set a message indicating no changes
    //         return;
    //     }

    //     console.log('updatedUser:', user);
    //     // Send the updated user data to the server
    //     axios.put(`https://apicloud.turfdev.in/api/${userId}`, updatedUserData)
    //         .then((response) => {
    //             console.log("Data saved successfully:", response.data);
    //             setMessage("Data saved successfully , Refresh the page for updated data"); // Set the success message
    //             // You can handle success or navigate to a different page here.
    //         })
    //         .catch((error) => {
    //             console.error("Error saving data:", error);
    //             setMessage("Error saving data , Refresh the page for updated Data"); // Set the error message
    //             // Handle errors here.
    //         });
    // };

    const [selectedCountry, setSelectedCountry] = useState(""); // Initialize with a default value
    const handleCountryChange = (e) => {
        const newCountry = e.target.value;
        // Update the user's country in the state
        setUsers({
            ...user,
            Country: newCountry,
        });
        // Update the selectedCountry in the state
        setSelectedCountry(newCountry);
    };

    // const [selectedpaymethod, setPayMethod] = useState(""); // Initialize with a default value
    // const handlePaymentMethod = (e) => {
    //     const newPaymentMethod = e.target.value;
    //     // Update the user's payment method in the state
    //     setUsers({
    //         ...user,
    //         PayMethod: newPaymentMethod,
    //     });
    //     // Update the selected payment method in the state (if needed)
    //     setPayMethod(newPaymentMethod);
    // };

    // SUBMIT DATA ENDS =======================================================

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedPayment, setSelectedPayment] = useState("");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [cvv, setCVV] = useState('');


    return (
        <>
            <Header />

            <div className="bg-color4 md:px-40 md:py-20 p-5 text-color2">
                <h1 className="pb-5 text-lg uppercase font-bold">Billing Details</h1>
                <div className="lg:px-20 lg:py-5 p-5 text-color2 text-left bg-color4 rounded-xl shadow-custom3">

                    <div className="flex text-color2 flex-col mt-4 md:gap-4 gap-2">

                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">First Name</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Firstname} name="Firstname" type="text" id="firstName" />
                            </div>

                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Last Name</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Lastname} name="Lastname" type="text" id="lastName" />
                            </div>
                        </div>
                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Email Address</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Email} name="Email" type="email" id="email" />
                            </div>

                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Phone Number</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Phone} name="Phone" type="tel" id="phone" />
                            </div>
                        </div>
                    </div>

                    <div className="flex text-color2 flex-col mt-8 md:gap-4 gap-2">
                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Company</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Company} name="Company" type="text" id="company" />
                            </div>
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Flat/Appartment/House/Plot No.</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Flat} name="Flat" type="text" id="houseno" />
                            </div>
                        </div>

                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Street/Area</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Area} name="Area" type="text" id="area" />
                            </div>
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">City/Village</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.City} name="City" type="text" id="city" />
                            </div>
                        </div>
                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">District</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.District} name="District" type="text" id="district" />
                            </div>
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">State</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.State} name="State" type="text" id="state" />
                            </div>
                        </div>
                        <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Country</h1>
                                <select className="border border-color3 rounded p-1 bg-transparent" value={user.Country} onChange={handleCountryChange}>
                                    {/* <option value="usersCountry">{user.Country}</option> */}
                                    <option value="undefined">-- Select country --</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="India">India</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                </select>
                            </div>

                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">ZIP/PIN Code</h1>
                                <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" style={{ MozAppearance: "textfield", appearance: "textfield" }} onChange={handleInputChange} value={user.Pin} name="Pin" type="number" id="pincode" />
                            </div>
                        </div>

                        <div className="flex flex-1 flex-col px-5 gap-2">

                            <div className="flex flex-col flex-1">
                                <h1 className="font-bold mb-1">Payment Method</h1>
                                <select className="md:w-[49.5%] border border-color3 rounded p-1 bg-transparent"
                                    name="PayMethod"
                                    type="text"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                >
                                    <option value="PayPal">PayPal</option>
                                    <option value="RazorPay">RazorPay</option>
                                    <option value="UPI">UPI</option>
                                    <option value="Card">Credit/Debit Card</option>
                                </select>
                            </div>

                            {selectedOption === "Card" && (
                                <div className="w-full mt-4 border border-color3 rounded-xl bg-transparent md:p-10 p-5">
                                    <p className="font-light font-sm">Pay securely using your Credit/Debit Card.</p>
                                    
                                    <div className="flex md:flex-row flex-col justify-center items-center mt-5 gap-2">

                                        <div className="flex flex-1 flex-col gap-1">
                                            <label htmlFor="cardNumber" className="text-sm font-bold uppercase">Card Number</label>
                                            <input
                                                type="text"
                                                id="cardNumber"
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)}
                                                className="w-full border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent"
                                            />
                                        </div>

                                        <div className="flex flex-1 flex-col gap-1">
                                            <label htmlFor="expiryDate" className="text-sm font-bold uppercase">Expiry Date</label>
                                            <input
                                                type="text"
                                                id="expiryDate"
                                                value={expiryDate}
                                                onChange={(e) => setExpiryDate(e.target.value)}
                                                className="w-full border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent"
                                            />
                                        </div>
                                    
                                    </div>

                                    <div className="flex md:flex-row flex-col justify-center items-center mt-5 gap-2">

                                        <div className="flex flex-1 flex-col gap-1">
                                            <label htmlFor="cardHolderName" className="text-sm font-bold uppercase">Card holder Name</label>
                                            <input
                                                type="text"
                                                id="cardHolderName"
                                                value={cardHolderName}
                                                onChange={(e) => setCardHolderName(e.target.value)}
                                                className="w-full border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent"
                                            />
                                        </div>

                                        <div className="flex flex-1 flex-col gap-1">
                                            <label htmlFor="cvv" className="text-sm font-bold uppercase">CVV</label>
                                            <input
                                                type="text"
                                                id="cvv"
                                                value={cvv}
                                                onChange={(e) => setCVV(e.target.value)}
                                                className="w-full border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent"
                                            />
                                        </div>
                                    
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>

                    <div className="mt-12 flex justify-center gap-2">
                        <button className="text-center border-2 border-color2 hover:bg-color2 hover:text-white rounded-full py-2 px-8 bg-transparent text-color2 uppercase" type="submit">Pay US$300</button>
                        {/* <button className="flex-1 text-center border-2 border-color2 bg-color2 text-white rounded-full py-2 hover:bg-transparent hover:text-color2 uppercase" type="submit">Cancel</button> */}
                    </div>
                    <div>
                        <h3 className="text-lg font-bold"> {message} </h3>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )

}

export default Checkout;