function Comparison() {
    
    return (
        <div className='lg:mt-10 px-5 lg:px-20 py-10 flex justify-center items-center'>
            <div className="w-full flex flex-col mx-0 sm:mx-32 p-5 text-white bg-black opacity-80 overflow-auto rounded-xl">
                <div className="sm:w-full w-[300%]">
                    <ul className="w-full flex text-sm font-bold">
                        <li className="w-[60%] py-2 px-4 glass-white border-white border-t border-l rounded-tl-md border-opacity-30">Feature</li>
                        <li className="w-[20%] py-2 px-4 glass-white border-white border-t border-l border-opacity-30">Competitors</li>
                        <li className="w-[20%] py-2 px-4 glass-white border-white border-t border-r border-l rounded-tr-md border-opacity-30">Poorva Cloud</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">AI Powered</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Managed Cloud Hosting</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">Yes</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Microservices</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Containerization</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Freedom from Monolithic Constraints</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Multiple PHP Versions on Cloud Server</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Multiple Web Servers on Cloud Server</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Multiple Database Servers on Cloud Server</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Multiple Databases for Perticular Application</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-l border-opacity-30">Unlimited Apps with Microservices on Cloud Server</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-l border-opacity-30">No</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-r border-l border-opacity-30">Yes</li>
                    </ul>

                    <ul className="w-full flex text-sm">
                        <li className="w-[60%] py-2 px-4 border-white border-t border-b border-l border-opacity-30 rounded-bl-md">Support 24/7/365</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-b border-l border-opacity-30">Ticket</li>
                        <li className="w-[20%] py-2 px-4 border-white border-t border-b border-r border-l border-opacity-30 rounded-br-md">Ticket & Chat</li>
                    </ul>
                </div>

            </div>
        </div>
    )
    
}

export default Comparison;

