import React, {useEffect} from "react";


import Header from "../../components/Header";

import Hero from "./components/Hero";
import YourPathToSuccess from "./components/YourPathToSuccess";
import EffortlessManagedHosting from "./components/EffortlessManagedHosting";
import SelectYourPlatform from './components/SelectYourPlatform';
import MaximizePerformance from './components/MaximizePerformance'
import WhatMakesUnique from './components/WhatMakesUnique'
import Comparison from './components/Comparison'
import SeamlessMigration from './components/SeamlessMigration'
import StayAhead from './components/StayAhead'
import AlwaysAvailable from './components/AlwaysAvailable'

import Footer from "../../components/Footer";


function Home(userData) {
    const user = userData.user;

    useEffect(() => {
        document.title = "Poorva Cloud- A leading cloud hosting company";
    }, []);
    
    // useEffect(() => {
    //     (function(c, l, a, r, i, t, y) {
    //         c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
    //         t = l.createElement(r);
    //         t.async = 1;
    //         t.src = "https://www.clarity.ms/tag/" + i;
    //         y = l.getElementsByTagName(r)[0];
    //         y.parentNode.insertBefore(t, y);
    //     })(window, document, "clarity", "script", "n4ldigfyyq");
    // }, []);
    
    return (
        <div className="bg-white">
            <Header userData={user}/>
            
            <Hero />
            <YourPathToSuccess />
            <EffortlessManagedHosting />
            <SelectYourPlatform />
            <MaximizePerformance />
            <WhatMakesUnique />
            <Comparison />
            <SeamlessMigration />
            <StayAhead />
            <AlwaysAvailable />

            <Footer />
        </div>
    )
    
}

export default Home;
