import React from "react";
import { useParams, useNavigate } from "react-router-dom";

function ServerOptions(props) {
    const { collectionName } = useParams();
    const navigate = useNavigate();
    // const location = useLocation();
    const application = props.application;
    const activeTab = props.activeAppTab;

    const handleSelectedOption = (optionValue) => {
        navigate(`/dashboard/manageserver/${collectionName}/app/${application.domain}?${optionValue}`);
    };

    return (
        <div className="text-black font-light px-5 lg:px-0 lg:pb-20 lg:pl-20 lg:pr-5 w-full">
            <div className="block items-center">
                <ul className="lg:text-left text-center font-light capitalize flex flex-col items-center w-full">
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("appDetails")} className={`${activeTab === 'appDetails' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Application Details</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("dbManager")} className={`${activeTab === 'dbManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Database Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("phpManager")} className={`${activeTab === 'phpManager' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>PHP Manager</div></li>
                    <li className="w-full flex items-center justify-start mt-1"><div onClick={() => handleSelectedOption("backupRestore")} className={`${activeTab === 'backupRestore' ? "bg-custom-green text-white" : "hover:bg-gray-300"} px-2 py-1 cursor-pointer rounded w-[100%]`}>Backup and Restore</div></li>
                </ul>
            </div>
        </div>
    )

}

export default ServerOptions;