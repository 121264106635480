import React, { useState } from "react";
import {
    useStripe,
    useElements,
    PaymentElement,
    // AddressElement,
    // LinkAuthenticationElement,
} from "@stripe/react-stripe-js";

const SetupForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [addingCard, setAddingCard] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        setErrorMessage(null);
        event.preventDefault();
        
        if (!stripe || !elements) {
            return null;
        }

        setAddingCard(true);

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: "https://poorva.cloud/dashboard/payment/success",
            },
        });

        if (error) {
            setErrorMessage(error.message);
            setAddingCard(false);
        } else {
            // Assuming the page reloads or redirects to the return_url, no need to set addingCard to false here.
        }
    };

    const payEleOptions = {
        layout: 'accordion',
    }

    return (
        <form onSubmit={handleSubmit}>
            {/* <LinkAuthenticationElement /> */}
            {/* <AddressElement options={{mode: 'billing'}} /> */}
            <PaymentElement options={payEleOptions}/>
            <div className="flex justify-center items-center w-full pt-5">
                <button
                    disabled={!stripe || addingCard}
                    className="elastic-button1 px-10"
                    type="submit"
                >
                    {addingCard ? <div className="flex items-center gap-2"> <div className='spinner2'></div>Adding...</div> : "+ Add Card"}
                </button>
            </div>
            {errorMessage && <div className="w-full text-center text-sm text-red-800 mt-5">{errorMessage}</div>}
        </form>
    );
};

export default SetupForm;
