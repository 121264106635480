import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function BackupRestore(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = "Backup and Restore your data";
    }, []);

    return (
        <div className="w-full">
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?appManager`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Backup & Restore
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">Backup & Restore</h1>
                <p className="font-light">Take a backup or restore the data of your website.</p>
            </div>

            <div className="flex flex-col mt-10">
                <div className="flex flex-col p-5">
                    <div>
                        <h1 className="text-lg font-bold uppercase mb-2">Backup</h1>
                        <div>
                            <p className="text-md font-bold uppercase mb-1">Select Domain:</p>
                            <select className="border-2 border-custom-green bg-black text-white rounded-md p-1 sm:w-[50%] w-full">
                                <option value="domain1">turfdev.in</option>
                                <option value="domain2">tdshop.com</option>
                                <option value="domain3">kb.turfdev.in</option>
                            </select>
                        </div>
                        <div className="flex flex-col md:flex-row mt-4 gap-2 md:w-[85%]">
                            <button className="elastic-button1 px-6" id="backupFiles" type="submit">Create File Backup</button>
                            <button className="elastic-button2 px-6" id="backupDatabase" type="submit">Create Database Backup</button>
                            <button className="elastic-button1 px-6" id="backupAll" type="submit">Create Full Backup</button>
                        </div>
                    </div>

                    <div className="mt-8 overflow-x-auto">
                        <h1 className="text-lg font-bold uppercase mb-2">Backups List</h1>
                        <table className="w-[250%] sm:w-full table-auto">
                            <thead className="">
                                <tr className="bg-gray-300 text-black border-t border-b border-gray-300">
                                    {/* Padding is not working on "tr" so it's implemented on "th" and "td" */}
                                    <th className="pl-4 py-2">Date</th>
                                    <th>Domain</th>
                                    <th>Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr className="p-4 border-b border-gray-300">
                                    <td className="pl-4 py-2">Sept 29, 2023</td>
                                    <td>turfdev.in</td>
                                    <td>Full</td>
                                    <td className="flex gap-2">
                                        <button className="fa-solid fa-arrow-rotate-left w-10 h-10 flex justify-center items-center p-4 border-2 border-green-700 rounded-full cursor-pointer text-green-700 hover:text-white hover:bg-green-700 transition duration-150 ease-in-out" type="submit" title="Restore backup"></button>
                                        <button className="fa-solid fa-trash-can w-10 h-10 flex justify-center items-center p-4 border-2 bg-red-700 border-red-700 rounded-full cursor-pointer text-white hover:text-red-700 hover:bg-transparent transition duration-150 ease-in-out" type="submit" title="Delete"></button>
                                    </td>
                                </tr>
                                <tr className="p-4 border-b border-gray-300">
                                    <td className="pl-4 py-2">Sept 29, 2023</td>
                                    <td>turfdev.in</td>
                                    <td>Database</td>
                                    <td className="flex gap-2">
                                        <button className="fa-solid fa-arrow-rotate-left w-10 h-10 flex justify-center items-center p-4 border-2 border-green-700 rounded-full cursor-pointer text-green-700 hover:text-white hover:bg-green-700 transition duration-150 ease-in-out" type="submit" title="Restore backup"></button>
                                        <button className="fa-solid fa-trash-can w-10 h-10 flex justify-center items-center p-4 border-2 bg-red-700 border-red-700 rounded-full cursor-pointer text-white hover:text-red-700 hover:bg-transparent transition duration-150 ease-in-out" type="submit" title="Delete"></button>
                                    </td>
                                </tr>
                                <tr className="p-4 border-b border-gray-300">
                                    <td className="pl-4 py-2">Sept 29, 2023</td>
                                    <td>turfdev.in</td>
                                    <td>Files</td>
                                    <td className="flex gap-2">
                                        <button className="fa-solid fa-arrow-rotate-left w-10 h-10 flex justify-center items-center p-4 border-2 border-green-700 rounded-full cursor-pointer text-green-700 hover:text-white hover:bg-green-700 transition duration-150 ease-in-out" type="submit" title="Restore backup"></button>
                                        <button className="fa-solid fa-trash-can w-10 h-10 flex justify-center items-center p-4 border-2 bg-red-700 border-red-700 rounded-full cursor-pointer text-white hover:text-red-700 hover:bg-transparent transition duration-150 ease-in-out" type="submit" title="Delete"></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BackupRestore;
