import React, { useRef } from "react";
import { Link } from 'react-router-dom';
// import html2pdf from 'html2pdf.js';

import companyLogo from '../../../../images/poorva-cloud-logo.png';

function InvoiceDetails(props) {
    const user = props.userData;
    const invoice = props.invoiceDetails;
    // console.log("III: ", props);

    const invoiceRef = useRef();

    const handlePrint = () => {
        window.print();
    };

    // const handleDownloadPDF = () => {
    //     const element = invoiceRef.current;
    //     const opt = {
    //         margin: 0,
    //         filename: 'poorva-invoice.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 4 },
    //         jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    //     };

    //     // Temporarily hide buttons for PDF generation
    //     const buttons = element.querySelectorAll('.no-print');
    //     buttons.forEach(button => button.style.display = 'none');

    //     html2pdf().from(element).set(opt).save().finally(() => {
    //         // Restore buttons after PDF generation
    //         buttons.forEach(button => button.style.display = 'flex');
    //     });
    // };

    const calculateGST = (amount) => {
        const gstRate = 0.18; // 18% GST
        const gstAmount = amount * gstRate;
        const totalAmountWithGST = amount + gstAmount;
        return { gstAmount: gstAmount.toFixed(2), totalAmountWithGST: totalAmountWithGST.toFixed(2) };
    };

    const { gstAmount, totalAmountWithGST } = calculateGST(invoice.amount);

    return (
        <>
            <div className="flex flex-wrap gap-2 items-center text-xs font-bold">
                <Link to="/dashboard/invoices" className="cursor-pointer hover:text-custom-green">
                    Invoices
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Invoice Details
                </p>
            </div>

            <div className="w-full bg-white">
                <div ref={invoiceRef} className="bg-white flex flex-col gap-2 rounded p-5 items-start w-auto">
                    <div className="print-class flex text-black flex-col border border-black border-opacity-70">
                        <div className="flex flex-col lg:flex-1">
                            <div className="p-5 bg-header text-black flex flex-1 items-center justify-between">
                                <div className="flex flex-1 items-start">
                                    <img className="w-[35%]" src={companyLogo} alt="TurfDev Pvt. Ltd." />
                                </div>
                                <div className="flex flex-col flex-1 items-end">
                                    <h3 className="uppercase font-bold text-lg text-custom-green">Turf Dev Pvt. Ltd.</h3>
                                    <p className="text-sm font-bold">Reg. No.: 081264</p>
                                    <p className="text-sm font-bold">GST No.: 08AAJCT3704C1ZL</p>
                                    <p className="text-sm font-bold">Tax ID.: 08AS7T65I4RQ76IZ32</p>
                                </div>
                            </div>
                            <div className="pl-5 flex-1 flex flex-col items-start mt-5">
                                <p className="text-sm">Invoice Date: {invoice.invoiceDate}</p>
                                <p className="text-sm mb-2">Due Date: {invoice.dueDate}</p>
                                <p className="font-bold">Invoiced to:</p>
                                <p className="font-bold text-custom-green">{user.fullName}
                                    {user.company &&
                                        <span className="ml-1 uppercase text-slate-600 text-sm">({user.company})</span>
                                    }
                                </p>
                                <p className=" text-xs text-slate-600">
                                    {user.flat &&
                                        <span>{user.flat},</span>
                                    }
                                    {user.area &&
                                        <span className="ml-1">{user.area},</span>
                                    }
                                    {user.city &&
                                        <span className="ml-1">{user.city},</span>
                                    }
                                    <br/>
                                    {user.district &&
                                        <span className="">{user.district},</span>
                                    }
                                    {user.state &&
                                        <span className="ml-1">{user.state},</span>
                                    }
                                    {user.country &&
                                        <span className="ml-1">{user.country},</span>
                                    }
                                    <br/>
                                    {user.zip &&
                                        <span className="">ZIP/PIN Code: {user.zip}</span>
                                    }
                                </p>
                            </div>
                            <div className="p-5 w-full">
                                <div className="text-xl flex w-[80%] mb-10">
                                    <h1 className="p-5 bg-slate-200 flex-1">PCI-{invoice.id}</h1>
                                    <h1 className={`${invoice.status === "paid" ? "bg-green-600" : "bg-red-700"} p-5 text-white font-bold uppercase`}>{invoice.status}</h1>
                                </div>
                                
                                {/* <div className="w-[50%] flex justify-between">
                                    <table className="flex-1">
                                        <tbody>
                                            {invoice.status === "paid" ? (
                                                <tr>
                                                    <td className="font-bold">Date Paid:</td>
                                                    <td>{invoice.datePaid}</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td className="font-bold">Due Date:</td>
                                                    <td>{invoice.dueDate}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div> */}
                                
                                {/* <p className="text-sm mt-10">For service used from <span className="font-bold">{invoice.dateFrom}</span> to <span className="font-bold">{invoice.dateTo}</span></p> */}
                                
                                <div className="p-3 mt-2 flex flex-col">
                                    <table className="w-full table-auto text-white">
                                        <thead className="bg-slate-200 text-black">
                                            <tr className="text-left">
                                                <th className="px-4 pt-4 pb-2">Product Description</th>
                                                <th className="px-4 pt-4 pb-2"></th>
                                                <th className="px-4 pt-4 pb-2 bg-custom-green text-white">Sub Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            <tr className="bg-slate-200 text-black">
                                                <td className="px-4 pb-4" style={{ whiteSpace: 'normal', overflowWrap: 'break-word', wordBreak: 'break-word' }}>{invoice.server} <span className="font-bold text-xs text-slate-600 uppercase">({invoice.plan})</span><br/>
                                                    <p className="text-sm">For service used from <span className="font-bold">{invoice.dateFrom}</span> to <span className="font-bold">{invoice.dateTo}</span></p>
                                                </td>
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 pb-4 bg-custom-green text-white">US ${invoice.amount}</td>
                                            </tr>
                                            <tr className="text-black">
                                                <td className="px-4 py-2"></td>
                                                <td className="px-4 py-2 font-bold">GST (18%)</td>
                                                <td className="px-4 py-2 font-bold">US ${gstAmount}</td>
                                            </tr>
                                            <tr className="font-bold">
                                                <td className="px-4 py-2 bg-custom-green"></td>
                                                <td className="px-4 py-2 bg-custom-green">Grand Total</td>
                                                <td className="px-4 py-2 bg-black text-white">US ${totalAmountWithGST}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="p-3 mt-4 flex flex-col">
                                    <p className="font-bold">Transactions</p>
                                    <table className="w-full table-auto text-white">
                                        <thead className="bg-slate-200 text-black">
                                            <tr className="text-left">
                                                <th className="px-4 pt-4 pb-2">Transaction Date</th>
                                                <th className="px-4 pt-4 pb-2">Gateway</th>
                                                <th className="px-4 pt-4 pb-2">Transaction ID</th>
                                                <th className="px-4 pt-4 pb-2 bg-custom-green text-white">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            <tr className="bg-slate-200 text-black text-sm">
                                                <td className="px-4 pb-4">{invoice.datePaid}</td>
                                                <td className="px-4 pb-4 capitalize">{invoice.gateWay}</td>
                                                <td className="px-4 pb-4">{invoice.transactionID}</td>
                                                <td className="px-4 pb-4 bg-custom-green text-base text-white">US ${totalAmountWithGST}</td>
                                            </tr>
                                            <tr className=" text-black">
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 pb-4 text-white"></td>
                                            </tr>
                                            <tr className="bg-slate-200 text-black">
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 pb-4"></td>
                                                <td className="px-4 py-2 font-bold text-right">Amount Due</td>
                                                <td className="px-4 py-2 bg-black text-white font-bold">
                                                    {invoice.status === "paid" ? (
                                                        <p>US $0</p>
                                                    ) : (
                                                        <p>US ${totalAmountWithGST}</p>
                                                    )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-end gap-2 mt-2 pr-5 no-print">
                        <button onClick={handlePrint} className="button-transparent border-2 border-black">Print</button>
                        {/* <button onClick={handleDownloadPDF} className="elastic-button2 px-4">Download as PDF</button> */}
                        <button 
                            type="submit"
                            className="elastic-button1 px-4"
                            disabled={invoice.status === "paid"}
                        >
                            {invoice.status === "paid" ? "Fully paid" : `Pay US ${totalAmountWithGST}`}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InvoiceDetails;
