import maxPerformImg from '../../../../../images/maximize-performance.png';

function MaximizePerformance() {

    return (
        <div className="mt-10 px-5 lg:px-20 py-10 lg:py-20 flex flex-col lg:flex-row gap-10 lg:gap-20 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${maxPerformImg})` }}>
            <div className='lg:text-left text-center flex flex-col justify-center items-start lg:gap-20 gap-10'>
                <div>
                    <li className='text-xl lg:text-2xl'>Maximize <span className='text-custom-green'>Performance with AI.</span></li>
                    <p className="mt-4 lg:pl-8">Harness cutting-edge AI-powered microservices architecture, ensuring unparalleled speed, efficiency, and optimization for your applications.</p>
                </div>
                <div className="lg:ml-8 border-t border-black border-opacity-50 w-[100%] lg:w-[60%]"></div>
                <div>
                    <li className='text-xl lg:text-2xl'>Reliability <span className='text-custom-green'>at its Core.</span></li>
                    <p className="mt-4 lg:pl-8">AI-driven architecture ensures consistent performance, keeping your applications highly reliable and always available.</p>
                </div>
            </div>

            <div className="lg:hidden border-t border-black border-opacity-50 w-[100%]"></div>

            <div className='lg:text-left text-center flex flex-col justify-center items-start lg:gap-20 gap-10'>
                <div>
                    <li className='text-xl lg:text-2xl'>Simplicity Meets <span className='text-custom-green'>Versatility.</span></li>
                    <p className="mt-4 lg:pl-8">AI-powered microservices provide both simplicity and flexibility, adapting to your changing needs with minimal complexity.</p>
                </div>
                <div className="lg:ml-8 border-t border-black border-opacity-50 w-[100%] lg:w-[60%]"></div>
                <div>
                    <li className='text-xl lg:text-2xl'>Feature-Rich, <span className='text-custom-green'>Cost-Effective with AI.</span></li>
                    <p className="mt-4 lg:pl-8">AI-powered optimizations drive efficiency, ensuring that your deployments are not only feature-rich but also cost-effective in the long run.</p>
                </div>
            </div>
        </div>
    )

}

export default MaximizePerformance;
