import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

import LeftDiv from "../components/LeftDiv";

import companyLogo from '../../../images/poorva-cloud-logo-transparent.png';
import Copyright from "../../private-routes/components/Copyright";

const SigninWithEmail = (userData) => {
	
	useEffect(() => {
        document.title = "Log in to your Poorva Cloud account";
    }, []);

	const userdata = userData.user;
	const navigate = useNavigate();
	
	useEffect(() => {
		if (userdata) {
			navigate("/dashboard"); // Redirect to the dashboard if the user is already signed in
		}
	});

	const [user, setUser] = useState({
		username: "",
		password: ""
	});

	const buttonRef = useRef(null); // Create a ref
    const [buttonWidth, setButtonWidth] = useState(null); // State to store button width

    useEffect(() => {
        if (buttonRef.current) {
            setButtonWidth(buttonRef.current.offsetWidth); // Calculate button width
        }
    }, [buttonRef]);

	// console.log(buttonWidth);

	const [error, setError] = useState("");
	const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (e) => {
		setError('');

		const { name, value } = e.target;

		setUser({
			...user,
			[name]: value
		});

	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			signinAPI();
		}
	};

	const signinAPI = async () => {
		setError('');
		
		setIsLoginButtonDisabled(true);
		setIsLoading(true);

		const { username, password } = user;

		if (!username || !password) {
			setError("Email and Password are required!");
			setIsLoading(false);
			setIsLoginButtonDisabled(false);
			return;
		}

		try {
			await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/signin`, user,
				{ withCredentials: true, }
			);

			setIsLoading(false);
			// navigate("/dashboard");
			window.location.reload();
		} catch (error) {
			// console.error("Signin error: ", error.response.data.message);
			setError(error.response.data.message);
			setIsLoading(false);
			setIsLoginButtonDisabled(false);
		};

	};

	const googleAuth = () => {
		window.open(
			`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/google/callback`,
			"_self"
		);
		// console.log(process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API)
	};


	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
			<div className="flex lg:flex-row flex-col min-h-screen">
				<LeftDiv />
				<div className="flex justify-center items-center flex-col lg:w-[50%] bg-white text-black p-5 gap-4">
					<div className="lg:absolute lg:top-10 flex justify-center lg:pt-0 pt-20">
						<Link to='/' className="w-[50%] lg:w-[25%] flex justify-center">
							<img className='rounded' alt='' src={companyLogo} />
						</Link>
					</div>

					<div className="mt-20 w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex justify-center items-center flex-col gap-3">
						{/* <h2 className="lg:text-2xl text-xl">Login with Email</h2> */}
						<input
							value={user.username}
							onChange={handleChange}
							// onBlur={() => checkUserExistence("username", user.username)}
							onKeyDown={handleKeyDown}
							className="mt-4 w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
							placeholder="Email"
							type="email"
							name="username"
						/>

						<input
							value={user.password}
							onChange={handleChange}
							// onBlur={() => checkUserExistence('password', user.password)}
							onKeyDown={handleKeyDown}
							className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
							placeholder="Password"
							type="password"
							name="password"
						/>

						<button
							onClick={signinAPI}
							disabled={isLoginButtonDisabled}
							className="elastic-button1 w-full"
							type="submit"
							ref={buttonRef}
						>
							{isLoading ? <div className="flex items-center gap-2"> <div className='spinner2'></div> Signing in...</div> : "Sign In"}
						</button>
					</div>

					<div>
						<p className="text-sm text-center text-red-700"> {error} </p>
					</div>

					<div className="md:w-[70%] lg:w-[50%] flex flex-col md:flex-row md:justify-between items-center">
						<div className="hover:scale-105">
							<Link to="/resetpassword" className="text-xs">
								Forgot Password?
							</Link>
						</div>
						<div className="">
							<Link
								to="/signup"
								className="text-xs text-slate-600 hover:text-black"
							>
								New To Poorva Cloud? SignUp
							</Link>
						</div>
					</div>

					<div className="my-5 w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex flex-row gap-4 justify-center items-center">
						<div className="flex-1 border-t border-black"></div>
						<div className="flex-1 flex justify-center items-center">OR</div>
						<div className="flex-1 border-t border-black"></div>
					</div>

					<div className="flex justify-center items-center w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%]">
						<GoogleLogin
							// CHECK THE PROPERTIES- https://www.npmjs.com/package/@react-oauth/google
							// clientId={process.env.REACT_APP_CLIENT_ID}
							width={buttonWidth}
							buttonText="signin_with"
							theme="filled_blue"
							// shape="pill"
							onSuccess={googleAuth}
							onError={() => {
								console.log("Login Failed");
							}}
						/>
					</div>
				</div>
			</div>
			<Copyright />
		</GoogleOAuthProvider>
	);
};

export default SigninWithEmail;
