import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import failedImage from "../../../images/PaymentError.gif"

const PaymentSuccess = () => {
    useEffect(() => {
        document.title = "Warning - The payment has failed";
    }, []);
    const navigate = useNavigate();

    setTimeout(() => {
        navigate('/dashboard/payment');
    }, 3000);

    return (
        <>
            <div className="h-screen w-full flex justify-center items-center">
                <div className="w-[250px] h-[250px] flex flex-col justify-center items-center gap-2">
                    <h3 className="text-[24px] font-bold text-red-700">Payment failed!</h3>
                    <img src={failedImage} className="rounded-xl shadow-custom3"/>
                </div>
            </div>
        </>
    );
};

export default PaymentSuccess;
