import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const DBManager = (userData) => {
    const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = "Databases - Create, Delete, Manage privileges";
    }, []);

    // const navigate = useNavigate()
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
    const [isPasswordVisible3, setIsPasswordVisible3] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    }

    const togglePasswordVisibility2 = () => {
        setIsPasswordVisible2(prevState => !prevState);
    }

    const togglePasswordVisibility3 = () => {
        setIsPasswordVisible3(prevState => !prevState);
    }

    const handleToggleDialog = () => {
        setShowDialog(prevState => !prevState);
    };

    return (
        <>              
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?appManager`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Database Manager
                </p>
            </div>
            
            <div>
                <h1 className="text-xl uppercase font-bold">Database Manager</h1>
                <p className="font-light">Manage your databases and database users- create, delete, grant, revoke, etc.</p>
            </div>
            
            <div className="mt-10">
                <h1 className="text-md uppercase font-bold">Databases' list</h1>
                
                <div className="mt-2 flex flex-col sm:flex-row gap-2 sm:gap-4">
                    <input
                        className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                        placeholder="Search Database" type="text" id="searchDB"
                    />
                    <button className="text-sm button1" type="submit">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>

                <div className="mt-4 overflow-x-auto">
                    <table className="w-[200%] sm:w-full table-auto">
                        <thead className="">
                            <tr className="bg-gray-300 text-black border-t border-b border-gray-300">
                                {/* Padding is not working on "tr" so it's implemented on "th" and "td" */}
                                <th className="pl-4 py-2">Database Name</th>
                                <th>Size</th>
                                <th>Privileged User</th>
                            </tr>
                        </thead>
                        
                        <tbody className="">
                            <tr className="p-4 border-b border-gray-300">
                                <td className="pl-4 py-2">database_1 <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Delete database"></i></td>
                                <td>97.48 MB</td>
                                <td>dbuser_1  <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Revoke privileges"></i></td>
                            </tr>
                            <tr className="p-4 border-b border-gray-300">
                                <td className="pl-4 py-2">database_2  <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Delete database"></i></td>
                                <td>74.01 MB</td>
                                <td>dbuser_2  <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Revoke privileges"></i></td>
                            </tr>
                            <tr className="p-4 border-b border-gray-300">
                                <td className="pl-4 py-2">database_3  <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Delete database"></i></td>
                                <td>34.83 MB</td>
                                <td>dbuser_3  <i className="text-red-600 cursor-pointer fa-solid fa-trash-can hover:scale-105" title="Revoke privileges"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="flex text-black md:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">

                    <div className="">
                        <h1 className="text-md font-bold uppercase mb-2">Create Database</h1>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="Database Name" type="text" id="dbName" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="mt-2 button1" type="submit">Create</button>
                    </div>

                    <div className="mt-8">
                        <h1 className="text-md font-bold uppercase mb-2">Create User</h1>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="Database User" type="text" id="dbUser" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="Password" type={isPasswordVisible ? "text" : "password"} id="password" />
                                    </td>
                                    <td>
                                        <i onClick={togglePasswordVisibility} className={isPasswordVisible ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="mt-2 button1" type="submit">Create</button>
                    </div>

                    <div className="mt-8">
                        <h1 className="text-md font-bold uppercase">Grant Privileges</h1>
                        <p className="text-sm mb-2">Grant all privileges on the database to the user.</p>
                        <div className="flex justify-between items-center">
                            <p className="text-sm flex-1">User:</p>
                            <select className="flex-1 border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                                <option value="database1">dbuser_1</option>
                                <option value="database2">dbuser_2</option>
                                <option value="database3">dbuser_3</option>
                            </select>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-sm flex-1">Database:</p>
                            <select className="flex-1 border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                                <option value="database1">database_1</option>
                                <option value="database2">database_2</option>
                                <option value="database3">database_3</option>
                            </select>
                        </div>
                        <button className="mt-2 button1" type="submit">Grant</button>
                    </div>
                    
                </div>

                <div className="flex flex-col lg:flex-1 p-5">
                    
                    <div className="">
                        <h1 className="text-md font-bold uppercase mb-2">Delete Database</h1>
                        <select className="w-full border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                            <option value="database1">database_1</option>
                            <option value="database2">database_2</option>
                            <option value="database3">database_3</option>
                        </select>
                        <button className="mt-2 uppercase text-center border-2 border-red-700 bg-red-700 text-white rounded-[6px] px-10 py-1 hover:shadow-custom3 transition duration-150 ease-in-out" type="submit">Delete</button>

                        <h1 className="text-md font-bold uppercase mb-2 mt-8">Users' List</h1>
                        <select className="w-full border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                            <option value="database1">dbuser_1</option>
                            <option value="database2">dbuser_2</option>
                            <option value="database3">dbuser_3</option>
                        </select>
                        <div className="flex flex-col sm:flex-row sm:gap-2">
                            <button className="mt-2 uppercase text-center border-2 border-red-700 bg-red-700 text-white rounded-[6px] px-5 py-1 hover:shadow-custom3 transition duration-150 ease-in-out" type="submit">Delete</button>
                            <button className="mt-2 elastic-button1 px-6" type="submit" onClick={handleToggleDialog}>Change Password</button>
                            {showDialog && (
                                <div className="fixed inset-0 flex items-center justify-center z-50 bg-transparent">
                                    <div className="w-[70vw] sm:w-auto bg-color4/30 backdrop-blur-xl rounded-xl shadow">
                                        <div className="text-center bg-color2 text-white p-5 rounded-t-xl">
                                            <h1 className="text-2xl uppercase">Change password for the user </h1>
                                        </div>
                                        <div className="flex flex-col px-10 py-4 gap-3">
                                            <div className="">
                                                <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="New Password" type={isPasswordVisible2 ? "text" : "password"} id="newDBPassword" />
                                                <i onClick={togglePasswordVisibility2} className={isPasswordVisible2 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                            </div>
                                            <div className="">
                                                <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="Confirm Password" type={isPasswordVisible3 ? "text" : "password"} id="confirmDBPassword" />
                                                <i onClick={togglePasswordVisibility3} className={isPasswordVisible3 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                            </div>
                                        </div>
                                        <div className="px-10 pb-4 text-sm flex gap-1 justify-end">
                                            <button className="mt-2 uppercase text-center border-2 border-red-700/50 hover:text-white hover:bg-red-700/50 backdrop-blur-xl rounded-[6PX] px-4 py-1 bg-transparent text-red-700/50 transition duration-150 ease-in-out" type="submit" onClick={handleToggleDialog}>Cancel</button>
                                            <button className="mt-2 uppercase text-center border-2 border-green-700/50 bg-green-700/50 backdrop-blur-xl text-white rounded-[6PX] px-4 py-1 hover:bg-transparent hover:text-green-700/50 transition duration-150 ease-in-out" type="submit" onClick={handleToggleDialog}>Change</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-8 md:mt-12">
                        <h1 className="text-md font-bold uppercase">Revoke Privileges</h1>
                        <p className="text-sm mb-2">Revoke all privileges on the database from the user.</p>
                        <div className="flex justify-between items-center">
                            <p className="text-sm flex-1">User:</p>
                            <select className="flex-1 border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                                <option value="database1">dbuser_1</option>
                                <option value="database2">dbuser_2</option>
                                <option value="database3">dbuser_3</option>
                            </select>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-sm flex-1">Database:</p>
                            <select className="flex-1 border-2 border-custom-green bg-black text-white rounded-md px-1 py-[4px]">
                                <option value="database1">database_1</option>
                                <option value="database2">database_2</option>
                                <option value="database3">database_3</option>
                            </select>
                        </div>
                        <button className="mt-2 button1" type="submit">Revoke</button>
                    </div>
                    
                </div>

            </div>
        </>
    )

}

export default DBManager;
