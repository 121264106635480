import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

import ourStory from '../../../images/our-story.png';
import ourStory1 from '../../../images/our-story-1.png';
import mission from '../../../images/mission.png';
import customerSuccess from '../../../images/customer-success.png';
import ourTeam from '../../../images/our-team.png';

function About(userData) {
    const user = userData.user;
    useEffect(() => {
        document.title = 'Poorva Cloud - About Us';
    }, []);

    return (
        <>
            <Header userData={user}/>
            <div className="flex flex-col items-center text-black px-5 py-20 md:p-20 md:text-left text-center">
                <h1 className="md:text-[80px] text-[50px] font-bold leading-tight mb-5 text-center tracking-tighter">Your Premier Destination for <span className="text-custom-green">Managed Cloud Servers!</span></h1>
                <p>Experience Reliable Hosting, Unrivaled Support, and Peak Performance.</p>
                <div className="w-[180px] h-12 cursor-pointer mt-10">
                    <Link to="/plans" className="flex justify-center items-center elastic-button2">Get Started</Link>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center p-5 md:px-20 md:pb-20 md:pt-5 gap-2 text-left">
                <div className="flex md:flex-row flex-col items-center justify-center mt-5 lg:px-60 px-10 py-10 md:py-2 bg-custom-green text-white rounded-3xl shadow-custom3">
                    <div className="flex-1">
                        <img className="" src={ourStory} alt="" />
                    </div>
                    <div className="flex-1">
                        <h1 className="text-[40px] font-bold pb-2 text-center">Our Story</h1>
                        <p className="text-center">We are more than just a hosting provider. We are your partners in digital success. Our journey began with a passion for technology and a vision to empower businesses with reliable, secure, and high-performance hosting solutions.</p>
                    </div>
                </div>
                
                <div className="mt-10 flex flex-col-reverse lg:flex-row justify-center items-center">
                    <div className="flex-1 lg:pr-20">                    
                        <h1 className="text-xl font-bold mb-2 text-custom-green">Dedication to Excellence</h1>
                        <p className="mb-10">From day one, we've been relentless in our pursuit of excellence. Our team comprises industry experts with years of experience in server management, and we've consistently pushed the boundaries to deliver outstanding hosting services.</p>
                        <h1 className="text-xl font-bold mb-2 text-custom-green">From Vision to Reality</h1>
                        <p>Founded by a team of tech enthusiasts, Turdev was established with a single goal in mind: to offer businesses a reliable, secure, and high-performance hosting solution that would enable them to thrive online.</p>
                    </div>

                    <div className="flex flex-1 justify-center">
                        <img className="lg:w-[80%]" src={ourStory1} alt="" />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-center items-center">
                    <div className="flex flex-1 justify-center">
                        <img className="lg:w-[80%]" src={customerSuccess} alt="" />
                    </div>

                    <div className="flex-1 lg:pl-20">
                        <h1 className="text-xl font-bold mb-2 text-custom-green">Customer Success</h1>
                        <p className="mb-10">Our journey has been driven by a singular focus—your success. We believe that your achievements are a reflection of our own, and this customer-centric approach has been at the heart of everything we do. Your satisfaction is our measure of success.</p>
                        <h1 className="text-xl font-bold mb-2 text-custom-green">Constant Innovation</h1>
                        <p>In a rapidly evolving digital landscape, we've kept pace with technology, ensuring that your hosting solutions remain at the forefront of innovation. Our commitment to staying up-to-date with the latest advancements in server hosting has allowed our clients to leverage cutting-edge solutions.</p>
                    </div>
                </div>
            </div>

            <div className="flex md:flex-row flex-col items-center justify-center m-5 md:m-20 lg:px-60 px-4 md:px-10 py-10 md:py-2 bg-white rounded-3xl shadow-custom3">
                <div className="flex-1">
                    <img className="" src={mission} alt="" />
                </div>
                <div className="flex-1">
                    <h1 className="text-[40px] font-bold pb-2 text-center text-custom-green">Mission</h1>
                    <p className="text-center">Our mission is simple: to provide businesses of all sizes with the tools and support they need to thrive in the digital landscape. We are dedicated to delivering top-notch managed Linux server hosting and exceptional customer service.</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-10 lg:px-60 px-10 md:px-10 py-10">
                <div className="flex-1">
                    <h1 className="text-[40px] font-bold pb-2 text-center text-custom-green">Our Commitment</h1>
                    <ul className="list-disc disc-custom-green leading-8 text-left">
                        <li><span className="font-bold text-custom-green">Excellence:</span> We are committed to excellence in everything we do. From server performance to customer support, we strive for the highest standards.</li>
                        <li><span className="font-bold text-custom-green">Customer-Centric:</span> Your success is our success. We prioritize understanding your unique needs and providing tailored solutions to meet them.</li>
                        <li><span className="font-bold text-custom-green">Innovation:</span> We stay on the cutting edge of technology to ensure our customers benefit from the latest advancements in server hosting.</li>
                    </ul>
                </div>
            
                <div className="flex-1">
                    <h1 className="text-[40px] font-bold pb-2 text-center text-custom-green">Why Choose Us</h1>
                    <ul className="list-decimal disc-custom-green leading-8 text-left">
                        <li><span className="font-bold text-custom-green">Expertise:</span> Our team consists of seasoned professionals with years of experience in server management. We know the ins and outs of Linux servers and can handle any challenge.</li>
                        <li><span className="font-bold text-custom-green">Reliability:</span> Our servers are hosted in state-of-the-art data centers, guaranteeing a 99.9% uptime. You can count on us to keep your online presence accessible at all times.</li>
                        <li><span className="font-bold text-custom-green">Security:</span> We take security seriously. Our servers are fortified against threats, and we implement proactive measures to safeguard your data.</li>
                        <li><span className="font-bold text-custom-green">Customer Support:</span> Our 24/7 customer support team is always ready to assist you. Whether you have questions, need help, or encounter issues, we're here for you.</li>
                        <li><span className="font-bold text-custom-green">Transparency:</span> We believe in clear and honest communication. Our pricing is straightforward, with no hidden fees, ensuring you know what to expect.</li>
                    </ul>
                    
                </div>
            </div>

            <div className="p-5 md:p-20">
                <div className="flex flex-col items-center justify-center lg:px-60 px-4 md:px-10 py-10 rounded-3xl shadow-custom3">
                    <div className="flex-1 flex justify-center">
                        <img className="md:w-[70%]" src={ourTeam} alt="" />
                    </div>
                    <div className="flex-1">
                        <h1 className="text-[40px] font-bold pb-2 text-center text-custom-green">Our Team</h1>
                        <p className="text-center">Meet the dedicated professionals who drive Poorva Cloud forward. Our team is passionate about what we do, and we're proud to have a group of experts who share the same commitment to excellence and customer satisfaction.</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-center items-center flex-col p-5 md:p-20 gap-4 text-center">
                <h1 className="text-3xl font-bold text-custom-green">Join Us in the Journey</h1>
                <p>As we continue our journey, we invite you to be a part of it. Experience the Poorva Cloud difference and let us be your partners in success.</p>
                <p className="-mt-4">Ready to take the next step? Contact us today and discover how Poorva Cloud can help your business excel in the digital world.</p>
                
                <ContactForm />
            </div>

            <Footer />
        </>
    )
}

export default About;