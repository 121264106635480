import React, { useState, useEffect } from "react";
import axios from "axios"
import Select from 'react-select';
import { Country, State } from 'country-state-city';

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

const customStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? 'black' : '#528F6E',
        borderWidth: 2,
        backgroundColor: 'black',
        borderRadius: 6,
        fontSize: 14,
        '&:hover': {
            borderColor: state.isFocused ? 'black' : '#528F6E',
        },
        // boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#528F6E' : state.isFocused ? 'lightgray' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:hover': {
            backgroundColor: 'lightgray',
        },
    }),
    singleValue: (base) => ({
        ...base,
        color: 'white', // Set text color to white
    }),
    placeholder: (base) => ({
        ...base,
        color: 'white', // Tailwind color for gray-500
        fontStyle: 'italic',
        opacity: 0.5, // Adjust opacity as needed
    }),
    input: (base) => ({
        ...base,
        color: 'white', // Ensure typed text is visible
    }),
};

function Profile(userData) {
    const user = userData.user;
    const compartment = "dashboard";
    // console.log("User:", user);
    useEffect(() => {
        document.title = 'Poorva Cloud - User Profile';
    }, []);

    // SUBMIT DATA STARTS =======================================================

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const [profile, setProfile] = useState({
        fullName: user.fullName,
        userName: user.userName,
        phone: user.phone,
        company: user.company,
        flat: user.flat,
        area: user.area,
        city: user.city,
        district: user.district,
        state: user.state,
        country: user.country,
        zip: user.zip,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setProfile({
            ...profile,
            [name]: value,
            // [name]: name === 'country' || name === 'state' ? value.label : value,
        });
    }


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(profile.country);
    const [selectedState, setSelectedState] = useState(profile.state);

    // Load countries when the component mounts
    useEffect(() => {
        const countriesList = Country.getAllCountries().map((country) => ({
            value: country.isoCode,
            label: country.name,
        }));
        setCountries(countriesList);

        // Initialize the selected country and state based on the profile data
        if (profile.country) {
            const country = countriesList.find(c => c.label === profile.country);
            setSelectedCountry(country);
    
            const statesList = State.getStatesOfCountry(country?.value || '').map((state) => ({
                value: state.isoCode,
                label: state.name,
            }));
            setStates(statesList);
    
            if (profile.state) {
                const state = statesList.find(s => s.label === profile.state);
                setSelectedState(state);
            }
        }
    }, [profile.country]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        profile.country = selectedOption.label;

        // console.log('COUNTRY: ', profile.country);

        const statesList = State.getStatesOfCountry(selectedOption.value).map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
        setStates(statesList);
        setSelectedState(null); // Reset state selection
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        profile.state = selectedOption.label;
        // console.log('STATE: ', selectedOption.label);
    };

    const handleSaveChanges = async () => {

        console.log('Profile: ', user);
        setIsLoading(true);

        try {
            const res = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/update-profile`, profile, {
                withCredentials: true
            });

            // console.log('Response: ', res.data.message);
            setMessage(res.data.message);
        } catch (error) {
            setError(error.response.data.message);
        }

        setIsLoading(false);

    };

    if (error) {
        setTimeout(() => {
            setError('');
        }, 5000); // 5000 milliseconds = 5 seconds
    }

    if (message) {
        setTimeout(() => {
            setMessage('');
        }, 5000); // 5000 milliseconds = 5 seconds
    }

    // To Debug-
    // console.log('DATA: ', profile);
    // console.log('STATE: ', selectedState);
    // console.log('COUNTRY: ', selectedCountry);
    // console.log(profile.country, profile.state)

    // SUBMIT DATA ENDS =======================================================

    return (
        <>
            <DashboardHeader userData={user} compartment={compartment} />
            <div className="flex lg:flex-row flex-col">
                <div className="hidden md:block lg:w-[27%]">
                    <DashboardOptions0 />
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%]">
                    <div className="flex flex-col p-5">
                        <div className="p-5 text-white text-center bg-custom-green rounded-t rounded-b-none shadow-custom3">
                            <h1 className="text-xl text-center uppercase">User Profile</h1>
                            {/* <p className="uppercase font-light text-sm"></p> */}
                        </div>

                        <div className="lg:px-20 lg:py-5 p-5 text-custom-green text-left bg-white rounded-b rounded-t-none shadow-custom3">
                            {/* The content goes here */}

                            <div className="flex flex-col mt-4 md:gap-4 gap-2">
                                <h1 className="text-lg uppercase font-bold text-black">Personal Information</h1>

                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    {/* <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">First Name</h1>
                                        <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Firstname} name="Firstname" type="text" id="firstName" />
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Last Name</h1>
                                        <input className="border border-color3 rounded placeholder:italic focus:ring-color3 focus:ring-1 placeholder:text-color3 focus:outline-none p-1 justify-center items-center bg-transparent" onChange={handleInputChange} value={user.Lastname} name="Lastname" type="text" id="lastName" />
                                    </div> */}
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Name</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.fullName}
                                            name="fullName"
                                            type="text"
                                            id="fullName"
                                            placeholder="Fullname"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Email Address/Username</h1>
                                        <input className="opacity-70 placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm cursor-not-allowed"
                                            // onChange={handleChange}
                                            value={profile.userName}
                                            name="userName"
                                            type="email"
                                            id="email"
                                            disabled
                                        />
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Phone Number</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.phone}
                                            name="phone"
                                            type="tel"
                                            id="phone"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col mt-8 md:gap-4 gap-2">
                                <h1 className="text-lg uppercase font-bold text-black">Billing Address</h1>
                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Company</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.company}
                                            name="company"
                                            type="text"
                                            id="company"
                                            placeholder="Company"
                                        />
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Flat/House/Plot No.</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.flat}
                                            name="flat"
                                            type="text"
                                            id="flat"
                                            placeholder="Flat Number"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Street/Area</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.area}
                                            name="area"
                                            type="text"
                                            id="area"
                                            placeholder="Area"
                                        />
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">City/Village</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.city}
                                            name="city"
                                            type="text"
                                            id="city"
                                            placeholder="City"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">District</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.district}
                                            name="district"
                                            type="text"
                                            id="district"
                                            placeholder="District"
                                        />
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <label className="font-bold mb-1">State</label>
                                        <Select
                                            // className="bg-black text-white border-2 border-custom-green rounded-md py-[5.5px] px-3"
                                            styles={customStyles}
                                            value={selectedState}
                                            // value={profile.state}
                                            onChange={handleStateChange}
                                            options={states}
                                            placeholder="Select State"
                                            isDisabled={!selectedCountry}
                                        />

                                        {/* <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            onChange={handleChange}
                                            value={profile.state}
                                            name="state"
                                            type="text"
                                            id="state"
                                            placeholder="State"
                                        /> */}
                                    </div>
                                </div>
                                <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <label className="font-bold mb-1">Country</label>
                                        <Select
                                            // className="bg-black text-white border-2 border-custom-green rounded-md py-[5.5px] px-3"
                                            // value={profile.country}
                                            // onChange={handleChange}
                                            // name="country"
                                            styles={customStyles}
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            options={countries}
                                            placeholder="Select Country"
                                        />

                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">ZIP/PIN Code</h1>
                                        <input className="placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            style={{ MozAppearance: "textfield", appearance: "textfield" }}
                                            onChange={handleChange}
                                            value={profile.zip}
                                            name="zip"
                                            type="number"
                                            id="zipcode"
                                            placeholder="ZIP"
                                        />
                                    </div>
                                </div>
                                {/* <div className="flex flex-1 md:flex-row flex-col px-5 gap-2">
                                    <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Payment Method</h1>
                                        <select className="border border-color3 rounded p-1" value={user.PayMethod} name="PayMethod" type="text" onChange={handlePaymentMethod}>
                                            <option value="PayPal">PayPal</option>
                                            <option value="RazorPay">RazorPay</option>
                                        </select>
                                    </div> */}
                                {/* <div className="flex flex-col flex-1">
                                        <h1 className="font-bold mb-1">Default Billing Contact</h1>
                                        <select className="border border-color3 rounded p-1">
                                            <option value="PayPal">Same As Above</option>
                                        </select>
                                    </div> */}
                                {/* </div> */}
                            </div>

                            {/* <div className="flex text-color2 flex-col mt-8 md:gap-4 gap-2">
                                <div>
                                    <h1 className="text-lg uppercase font-bold">Get Notified</h1>
                                    <p className="text-sm">We would like to send you occasional news, information and special offers by email. To join our mailing list, simply tick the box below. You can unsubscribe at any time.</p>
                                </div>
                                <div className="flex border border-color3 w-48 px-2 py-4 gap-2 rounded">
                                    <input className="cursor-pointer" type="checkbox" id="receiveEmail" name="receiveEmail" />
                                    <label htmlFor="receiveEmail">Receive Emails</label>
                                </div>
                            </div> */}

                            <div className="px-5 sm:px-0 mt-12 flex justify-center gap-2">
                                <button className={`${isLoading ? 'cursor-not-allowed opacity-50' : ''} elastic-button1 w-full sm:w-40`}
                                    type="submit"
                                    onClick={handleSaveChanges}
                                    disabled={isLoading}
                                >{isLoading ? 'Saving Changes...' : 'Save Changes'}
                                </button>
                                {/* <button className="flex-1 text-center border-2 border-color2 bg-color2 text-white rounded-full py-2 hover:bg-transparent hover:text-color2 uppercase" type="submit">Cancel</button> */}
                            </div>
                            <div className="w-full mt-2 flex justify-center items-center">
                                <h3 className="text-sm text-green-600"> {message} </h3>
                            </div>
                            <div className="w-full mt-2 flex justify-center items-center">
                                <h3 className="text-sm text-red-600"> {error} </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Copyright />
        </>
    )

}

export default Profile;
