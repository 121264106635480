import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

import LeftDiv from "../components/LeftDiv";
import ContactForm from "../components/ContactForm";

import companyLogo from '../../../images/poorva-cloud-logo-transparent.png';
import Copyright from "../../private-routes/components/Copyright";

function Contact() {
    useEffect(() => {
        document.title = "Get in touch with Poorva Cloud";
    }, []);


    return (
        <>
            <div className="flex justify-center lg:flex-row flex-col min-h-screen">
                <LeftDiv />

                <div className="flex justify-center items-center flex-col lg:w-[50%] bg-white text-black p-5 gap-4">
                    <div className="lg:absolute lg:top-10 flex justify-center lg:pt-0 pt-20">
						<Link to='/' className="w-[50%] lg:w-[25%] flex justify-center">
							<img className='rounded' alt='' src={companyLogo} />
						</Link>
					</div>

                    <h1 className="mt-20 text-3xl font-bold">Get In Touch</h1>
                    <p className="font-light text-sm -mt-4">
                        Submit the form below to get in touch with us.
                    </p>

					<ContactForm />
                </div>
            </div>

            <Copyright />
        </>
    );
}

export default Contact;
