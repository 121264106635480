import React from "react";
import { Link } from 'react-router-dom';

import companyLogo from '../../../images/poorva-cloud-logo.png';

function Footer({ user }) {
    return (
        <>
            <footer className="bg-black text-left md:px-20 md:pb-10 pt-5 px-5 pb-5 w-full text-white">
                <div className="">
                    <div className="w-full py-10 flex flex-col lg:flex-row gap-10 border-t border-b border-white border-opacity-50 text-sm font-light">
                        <div className="flex-1">
                            <img className="w-[40%] rounded" src={companyLogo} alt="Poorva Cloud" />
                            <p className="my-10">Founded in 2024, our mission is to deliver unparalleled managed cloud hosting solutions, designed for unmatched speed, high security, and 24/7  support. As a leading provider specializing in microservices  architecture, we empower cloud providers worldwide with scalable,  reliable, and accessible services anytime, anywhere.</p>
                            <div className="flex gap-4 justify-start items-center text-4xl text-header">
                                <i className="cursor-pointer hover:scale-105 fa-brands fa-square-facebook"></i>
                                <i className="cursor-pointer hover:scale-105 fa-brands fa-square-youtube"></i>
                                <i className="cursor-pointer hover:scale-105 fa-brands fa-square-instagram"></i>
                                <i className="cursor-pointer hover:scale-105 fa-brands fa-square-x-twitter"></i>
                                <i className="cursor-pointer hover:scale-105 fa-brands fa-linkedin"></i>
                                {/* <i className="cursor-pointer hover:scale-105 fa-brands fa-square-pinterest"></i> */}
                            </div>
                        </div>

                        <div className="flex-1 flex justify-center flex-col">
                            <h1 className="text-2xl mb-4">Cloud Hosting</h1>
                            <ul className="pl-5 leading-8 list-disc">
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Managed Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Cheap Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Wordpress Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Magento Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Laravel Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">WooCommerce Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">PHP Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Drupal Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Joomla Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Opencart Cloud Hosting</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">One-Click Deployment for Leading CMS Applications</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">All-in-One Cloud Hosting Solutions for Businesses of Every Size</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Free Website Migration for New Customers</Link></li>
                            </ul>
                        </div>

                        <div className="flex-1">
                            <h1 className="text-2xl mb-4">Company</h1>
                            <ul className="pl-5 leading-6 list-disc">
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="/about">About Us</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Careers</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Terms & Conditions</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Privacy Policy</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Partners</Link></li>
                            </ul>

                            <h1 className="mt-10 md:mt-20 text-2xl mb-4">Support</h1>
                            <ul className="pl-5 leading-6 list-disc">
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="/contact">Contact Us</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Blog</Link></li>
                                <li><Link className="hover:text-gray-300 transition duration-150 ease-out" to="#">Case Studies</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-5 md:mt-10 flex justify-center text-center">
                        &copy; 2024 Poorva Cloud. All rights reserved.
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
