import React from 'react';
// import LoadingImage from '../images/loading-gif-5.gif';
import companyLogo from '../images/poorva-cloud-logo-transparent.png';

const Loading = () => {
    return (
        // <div className='h-screen bg-custom-green flex flex-col justify-center items-center'>
        //     <img className="absolute top-20 w-[30%] lg:w-[14%] rounded" src={companyLogo} alt="" />
        //     <div className='bg-white backdrop-blur-sm p-5 rounded-xl flex justify-center items-center gap-6'>
        //         <img className="w-full" src={LoadingImage} alt="" />
        //         {/* <h1 className='flex justify-center items-center text-3xl font-bold text-color1'>Loading...</h1> */}
        //     </div>
        // </div>
        <div className='h-screen bg-white flex flex-col justify-center items-center'>
            <img className="absolute top-20 w-[30%] lg:w-[12%] rounded" src={companyLogo} alt="" />
            <div className="flex justify-center items-center gap-2">
                <div className="spinner"></div>
                <p className='font-bold'>Loading...</p>
            </div>
        </div>
    )
}

export default Loading;