import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function Monitor(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;
    
    useEffect(() => {
        document.title = 'Monitor your cloud server with real-time logs';
    }, []);
    
    return (
        <>
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Monitoring & Logs
                </p>
            </div>
                            
            <div>
                <h1 className="text-xl uppercase font-bold">Monitoring and Logs</h1>
                <p className="font-light">This interface lets you keep an eye on your server's real-time logs and resource usage.</p>
            </div>

            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">
                    <h1 className="text-md font-bold uppercase mb-2">(Dummy Heading)</h1>
                </div>
                <div className="flex flex-col lg:flex-1 p-5">
                    
                </div>

            </div>
        </>
    )

}

export default Monitor;
