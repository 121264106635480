import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";

import PageNotFound from '../../../images/404.png';

function NotFound(userData) {
    const user = userData.user;

    useEffect(() => {
        document.title = "404 - Page Not Found";
    }, []);

    return (
        <>
            <Header userData={user}/>

            <div className="px-5 py-5 md:px-20 flex lg:flex-row-reverse flex-col justify-center items-center gap-16 lg:gap-8 text-black">

                <div className="w-full lg:w-[60%] flex justify-center items-center"><img className="w-full md:w-[80%] lg:w-full" src={PageNotFound} alt="" /></div>

                <div className="w-full lg:w-[40%]">
                    <h1 className="text-[90px] font-bold text-center leading-14 text-custom-green">404<br/>error</h1>
                    <p className="text-sm text-center mt-10">It seems the page you were looking for has<br/>taken a detour or is on vacation.<br/>Don't worry, though – even the best explorers<br/>find themselves off the beaten path from<br/>time to time.</p>
                    <Link to="/" className="md:mx-40 lg:mx-20 mt-10 elastic-button2 flex justify-center items-center gap-2">
                        <i className="fa-solid fa-home"></i>Go to Homepage
                    </Link>
                </div>

            </div>

            <Footer />
        </>
    )

}

export default NotFound;