import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';


function UpgradePlan(userData) {
    const server = userData.server;
    const serverDetails = userData.serverDetails;
    const instanceTypes = userData.instanceTypes;

    useEffect(() => {
        document.title = 'Enhance Your Subscription';
    }, []);

    const [selectedPlan, setSelectedPlan] = useState("");
    const [selectedVolumeSize, setSelectedVolumeSize] = useState(parseInt(server.volumeSize, 10) || 0);

    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.value);
    };

    const upgradePlan = () => {
        if (window.confirm(`Are you sure you want to upgrade the server ${server.serverName} to ${selectedPlan} RAM?`)) {
            // Handle RAM upgrade logic here
            console.log(`Upgrading the server ${server.serverName} to ${selectedPlan}`);
        }
    };

    // useEffect(() => {
    //     setSelectedVolumeSize(parseInt(server.volumeSize, 10));
    // }, [server.volumeSize]);

    const upgradeVolume = () => {
        if (window.confirm(`Are you sure you want to upgrade the server ${server.serverName} to ${selectedVolumeSize} GB Storage?`)) {
            // Handle Volume upgrade logic here
            console.log(`Upgrading the server ${server.serverName} to ${selectedVolumeSize} Storage`);
        }
    };

    const handlevolumeSizeChange = (e) => {
        const inputValue = parseInt(e.target.value, 10);

        if (inputValue < 20 || inputValue > 2000 || isNaN(inputValue) || e.target.value.trim() === "") {
            setSelectedVolumeSize(inputValue);
        } else {
            setSelectedVolumeSize(inputValue);
        };
    };

    // console.log("SELECTED: ", selectedVolumeSize);
    // console.log("CURRENT: ", server.volumeSize);

    return (
        <>
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Change Plan
                </p>
            </div>

            {/* The content goes here */}
            <div>
                <h1 className="text-xl uppercase font-bold">Current Plan: <span className="text-custom-green">PC {serverDetails?.name}</span></h1>
                <p className="font-light">Upgrade or downgrade your plan according to your need.</p>
            </div>

            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">
                    <div className="flex flex-col flex-1">
                        <h1 className="text-md font-bold uppercase mb-2">Current Server Specs</h1>
                        <table className="text-sm">
                            <tbody>
                                <tr>
                                    <td>Memory:</td>
                                    <td>{serverDetails?.details?.Memory}</td>
                                </tr>
                                <tr>
                                    <td>CPU:</td>
                                    <td>{serverDetails?.details?.Core}</td>
                                </tr>
                                <tr>
                                    <td>Storage:</td>
                                    <td>{server.volumeSize} GB</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-10 flex flex-col flex-1">
                        <h1 className="font-bold uppercase mb-2">Current Pricing</h1>
                        <table className="text-sm w-full">
                            <tbody>
                                <tr>
                                    <td>Hourly:</td>
                                    <td>US <span className="font-bold">${serverDetails?.price?.Hourly}</span></td>
                                </tr>
                                <tr>
                                    <td>Monthly:</td>
                                    <td>US <span className="font-bold">${serverDetails?.price?.Monthly}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-10 flex flex-col flex-1">
                        <h1 className="text-md font-bold uppercase mb-2">Storage Pricing</h1>
                        <table className="text-sm">
                            <tbody className="w-full">
                                <tr>
                                    <td>Current Volume Size:</td>
                                    <td>{server.volumeSize} GB</td>
                                </tr>
                                <tr>
                                    <td>Payable Volume Size:</td>
                                    <td>{server.volumeSize - 20} GB</td>
                                </tr>
                                <tr>
                                    <td>Volume Pricing (Hourly):</td>
                                    <td>US <span className="font-bold">${(server.volumeSize - 20) * 0.00022}</span></td>
                                </tr>
                                <tr>
                                    <td>Volume Pricing (Monthly):</td>
                                    <td>US <span className="font-bold">${(server.volumeSize - 20) * 0.00022 * 24 * 30}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-1 p-5">
                    <div className="w-full flex flex-col gap-5 items-center">
                        <div className="w-full">
                            <div className="flex flex-col">
                                <label className="text-md font-bold text-left uppercase" htmlFor="ramSelect">Change Plan:</label>
                                <select
                                    className="mt-5 placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    id="planSelect"
                                    value={selectedPlan || serverDetails?.value}
                                    onChange={handlePlanChange}
                                >
                                    <option value={serverDetails?.value} disabled>PC {serverDetails?.name}</option>
                                    {instanceTypes.map((plan, index) => (
                                        plan.name !== serverDetails?.name && (
                                            <option key={index} value={plan.value}>PC {plan.name}</option>
                                        )
                                    ))}
                                </select>
                            </div>
                            <div>
                                {selectedPlan && selectedPlan !== serverDetails?.value &&
                                    (() => {
                                        const selectedPlanDetails = instanceTypes.find(plan => plan.value === selectedPlan);
                                        return (
                                            <div className="mt-2 p-2 border border-custom-green border-opacity-50 rounded text-xs font-bold flex flex-col">
                                                <div><span className="text-sm">{selectedPlanDetails.details.Memory}, {selectedPlanDetails.details.Core}</span></div>
                                                <div>
                                                    US <span className="text-base">${selectedPlanDetails.price.Hourly}</span>/Hour,
                                                    US <span className="text-base">${selectedPlanDetails.price.Monthly}</span>/Month
                                                </div>
                                            </div>
                                        );
                                    })()
                                }
                            </div>
                            <button className={`${!selectedPlan ? "opacity-50" : ""} mt-2 elastic-button1 px-8`} onClick={upgradePlan} disabled={!selectedPlan}>
                                Submit
                            </button>
                        </div>

                    </div>

                    <div className="mt-10 w-full flex flex-col gap-5 items-center">
                        <h1 className="w-full -mb-3 text-md font-bold text-left uppercase">Increase Storage</h1>
                        <p className="w-full -mt-2 text-sm font-light text-left">Scale up your storage size. You cannot decrease the volume size.</p>
                        <div className="w-full flex flex-col md:flex-row gap-5 items-center">
                            <div className='w-full lg:w-[68%]'>
                                <input
                                    type="range"
                                    min={server.volumeSize}
                                    max="2000"
                                    step="10"
                                    value={selectedVolumeSize}
                                    onChange={handlevolumeSizeChange}
                                    className="w-full accent-custom-green"
                                />

                                <div className="w-full flex justify-between">
                                    <span className="flex-1 text-xs text-left">{server.volumeSize} GB</span>
                                    <span className="flex-1 text-xs text-right">2000 GB</span>
                                </div>
                            </div>

                            <div className="w-full lg:w-[32%] flex items-center gap-2">
                                <input
                                    onFocus={(e) => e.target.blur()}
                                    type="number"
                                    min={server.volumeSize}
                                    max="2000"
                                    step="10"
                                    value={selectedVolumeSize}
                                    onChange={handlevolumeSizeChange}
                                    className="w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                />
                                <p>GB</p>
                            </div>
                        </div>
                        {selectedVolumeSize && selectedVolumeSize !== server.volumeSize &&
                            <div className="w-full mt-2 p-2 border border-custom-green border-opacity-50 rounded text-xs font-bold flex flex-col">
                                <div>
                                    US <span className="font-bold text-base">${((selectedVolumeSize - server.volumeSize) * 0.00022).toFixed(4)}</span>/Hour,
                                    US <span className="font-bold text-base">${((selectedVolumeSize - server.volumeSize) * 0.00022 * 24 * 30).toFixed(4)}</span>/Month,
                                </div>
                            </div>
                        }
                        <div className="w-full flex justify-start">
                            <button
                                className={`${selectedVolumeSize === parseInt(server.volumeSize, 10) ? "opacity-50" : ""} elastic-button1 px-8`}
                                onClick={upgradeVolume}
                                disabled={selectedVolumeSize === parseInt(server.volumeSize, 10)}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default UpgradePlan;
