function Copyright() {
    return (
        <>
            <div className="p-4 flex justify-center text-center text-black">
                Copyright &copy; 2024 Poorva Cloud. All rights reserved.
            </div>
        </>
    )
}

export default Copyright;