import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"

import companyLogo from '../../../images/poorva-cloud-logo.png';
// import tick from '../../../images/tick.gif';

import DashBoardOptions from './Dashboard-Options-0'; 
import ServerOptions from './Server-Options';
import AppsOptions from './Apps-Options';

const DashHeader = (props) => {
    const user = props.userData;
    const userImage = user.profileImage;
    const selectedItem0 = props.selectedItem0;
    const selectedItem2 = props.selectedItem2;
    const compartment = props.compartment;
    const application = props.application;
    const activeAppTab = props.activeAppTab;
    const activeServerTab = props.activeServerTab;
    
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSwitched, setIsSwitched] = useState(true);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    // const [logOut, setLogOut] = useState(false);
    const [isSigningOut, setIsSigningOut] = useState(false);

    const switchMenu = () => {
        setIsSwitched(prevState => !prevState);
    };

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };
    
    const openDropdown = () => {
        if (!isDropdownOpen) {
            setDropdownOpen(true);
        }
    };

    const closeDropdown = () => {
        if (isDropdownOpen) {
            setDropdownOpen(false);
        }
    };

    const handleSignOut = async () => {
        setIsSigningOut(true);
        closeDropdown();
        
        try {
            const signoutApiUrl = `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/signout`;
            await axios.get(signoutApiUrl, { withCredentials: true, })
            
            // setLogOut(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.log("An error occurred during signout: ", error);
            setIsSigningOut(false);
        };
    };

    // useEffect(() => {
    //     if (logOut) {
    //         // Add a class to the body element to disable scrolling
    //         document.body.style.overflow = 'hidden';
    //     }
    // }, [logOut]);

    return (
        <>
            {isSigningOut &&
                <div className="w-full h-screen fixed inset-0 z-50 bg-white flex justify-center items-center">
                    <img className="absolute top-20 w-[30%] lg:w-[12%] rounded" src={companyLogo} alt="" />
                    <div className="flex justify-center items-center gap-2">
                        <div className="spinner"></div>
                        <p className='font-bold'>Signing out...</p>
                    </div>
                </div>
            }
            <nav className="sticky top-0 bg-header w-[full] py-2 lg:flex justify-between px-4 text-black z-40 shadow">
                {/* {logOut &&
                    <div className="z-[55] -m-4 w-full h-full absolute backdrop-blur-lg overflow-hidden flex justify-center items-center">
                        <div className="bg-blue-300 w-[450px] h-[200px] rounded-3xl z-[60] shadow-custom3 flex justify-center items-center">
                            <div className="flex items-center gap-2">
                                <img className="rounded-full w-[20%] border" src={tick} />
                                <p className="text-[24px] font-bold text-color1">Logout success</p>
                            </div>
                        </div>
                    </div>
                } */}
                <div className="flex flex-1 justify-between items-center lg:w-[10%] flex-row-reverse w-full">
                    {/* CLIENT OPTIONS FOR SMALL DEVICES (TABLETS, MOBILES) START*/}
                    <div className="flex flex-1 lg:hidden w-[15%] justify-end z-50">
                        {!userImage &&
                            <i className="cursor-pointer flex justify-center items-center fa-solid fa-user text-xl text-custom-green w-[45px] h-[45px] rounded-full border-2 border-custom-green hover:scale-105"
                                onMouseEnter={openDropdown}
                                onMouseLeave={closeDropdown}>
                            </i>
                        }
                        {userImage &&
                            <div className="cursor-pointer flex justify-center items-center text-xl w-[45px] h-[45px] rounded-full border-2 border-custom-green hover:scale-105"
                                onMouseEnter={openDropdown}
                                onMouseLeave={closeDropdown}
                            >
                                <img src={userImage} className="rounded-full"/>
                            </div>
                        }
                        <div className={isDropdownOpen ? "absolute mt-12 shadow-custom3 bg-header font-light text-sm leading-8 px-8 py-4 rounded" : "hidden"}>
                            <ul className="text-left">
                                {userImage &&
                                    <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><img src={userImage} className="w-8 h-8 rounded-full"/><p>{user.fullName}</p></li>
                                }
                                {!userImage &&
                                    <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><i className="text-2xl fa-solid fa-circle-user"></i><p>{user.fullName}</p></li>
                                }
                                <li className="hover:scale-105"><Link to="/user/profile" className="flex items-center gap-4 "><i className="fa-regular fa-address-book"></i><p>Profile</p></Link></li>
                                <li className="hover:scale-105"><Link to="/dashboard" className="flex items-center gap-4 "><i className="fa-solid fa-table-columns"></i><p>Dashboard</p></Link></li>
                                <li className="hover:scale-105"><Link to="/user/settings" className="flex items-center gap-4 "><i className="fa-solid fa-gear"></i><p>Security Settings</p></Link></li>
                                <li className="hover:scale-105"><Link to="/user/changepassword" className="flex items-center gap-4 "><i className="fa-solid fa-lock"></i><p>Change Password</p></Link></li>
                                <li className="hover:scale-105"><button className="flex items-center gap-4 " onClick={handleSignOut} type="submit"><i className="fa-solid fa-right-from-bracket"></i><p>Sign Out</p></button></li>
                            </ul>
                        </div>
                    </div>
                    {/* CLIENT OPTIONS FOR SMALL DEVICES (TABLETS, MOBILES) END*/}

                    <div className="flex flex-1 items-center justify-center lg:justify-start">
                        <Link className="lg:w-[35%] md:w-[50%] w-[90%] leading-4 cursor-pointer" to="/">
                            <img src={companyLogo} alt="TurfDev Pvt. Ltd." />
                        </Link>
                    </div>
                    <div className="flex-1 lg:hidden cursor-pointer" onClick={toggleMenu}>
                        <i className={isMenuOpen ? "text-2xl fa-solid fa-x" : "text-2xl fa-solid fa-bars"}></i>
                    </div>
                </div>
                
                <ul className={isMenuOpen ? "hidden" : "hidden lg:flex flex-1 items-center justify-center gap-6"}>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/support">Support</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/privacy">Privacy</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/plans">Price</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/about">About</Link></li>
                </ul>
                
                <div className={isMenuOpen ? "md:hidden mt-[50px] flex flex-col items-center justify-center gap-4" : "hidden"}>
                    <div className="flex items-center justify-center w-full px-5">
                        <div className="flex justify-center items-center gap-1">
                            <p className={`${isSwitched ? "" : "text-custom-green font-bold"}`}>Links</p>
                            <i className={`${isSwitched ? "" : "rotate-180"} fa-solid fa-toggle-on text-3xl text-custom-green`} onClick={switchMenu}></i>
                            <p className={`${!isSwitched ? "" : "text-custom-green font-bold"}`}>Menu</p>
                        </div>
                    </div>
                    {!isSwitched &&
                        <ul className="leading-10 flex-col items-center justify-center font-bold text-[24px] uppercase py-4 text-center">
                            <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/support">Support</Link></li>
                            <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/privacy">Privacy</Link></li>
                            <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/plans">Price</Link></li>
                            <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/about">About</Link></li>
                        </ul>
                    }
                    {isSwitched &&
                        <>
                            { compartment === "dashboard" && 
                                <DashBoardOptions selectedItem0={selectedItem0} selectedItem2={selectedItem2}/>
                            }
                            { compartment === "server" &&
                                <>
                                    <h1 className="lg:text-left text-center uppercase text-xl font-bold">Server Management</h1>
                                    <div className="w-full" onClick={toggleMenu}>
                                        <ServerOptions activeServerTab={activeServerTab}/>
                                    </div>
                                </>
                            }
                            { compartment === "application" &&
                                <>
                                    <h1 className="lg:text-left text-center uppercase text-xl font-bold">App Management</h1>
                                    <div className="w-full" onClick={toggleMenu}>
                                        <AppsOptions application={application} activeAppTab={activeAppTab}/>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>

                {/* CLIENT OPTIONS FOR LARGE DEVICES (PCs, LAPTOPS) START*/}
                <div className="hidden lg:flex flex-1 justify-end gap-4">
                    <div className="mt-2">
                        <div className="flex gap-2 items-center">
                            <i className="cursor-pointer fa-solid fa-magnifying-glass text-custom-green"></i>
                            <input className="bg-transparent outline-none placeholder:text-grey-400 placeholder:text-xs" type="text" placeholder="Search Server or Application" />
                        </div>
                        <div className="border-t-[1px] border-custom-green mt-2 md:mx-50"></div>
                    </div>
                    <div className="z-50" onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
                        {!userImage &&
                            <i className="cursor-pointer flex justify-center items-center fa-solid fa-user text-custom-green text-xl w-[45px] h-[45px] rounded-full border-2 border-custom-green hover:scale-105"></i>
                        }
                        {userImage &&
                            <div className="cursor-pointer flex justify-center items-center text-xl w-[45px] h-[45px] rounded-full border-2 border-black hover:scale-105">
                                <img className="rounded-full" src={userImage}/>
                            </div>
                        }
                        <div className={isDropdownOpen ? "block absolute -ml-[170px] shadow-custom3 bg-header font-normal text-sm leading-8 px-8 py-4 rounded" : "hidden"}>
                            <ul className="text-left">
                                {userImage &&
                                    <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><img src={userImage} className="w-8 h-8 rounded-full"/><p>{user.fullName}</p></li>
                                }
                                {!userImage &&
                                    <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><i className="text-2xl fa-solid fa-circle-user"></i><p>{user.fullName}</p></li>
                                }
                                <li className="hover:scale-105"><Link to="/user/profile" className="flex items-center gap-4 "><i className="fa-regular fa-address-book"></i><p>Profile</p></Link></li>
                                <li className="hover:scale-105"><Link to="/dashboard" className="flex items-center gap-4 "><i className="fa-solid fa-table-columns"></i><p>Dashboard</p></Link></li>
                                <li className="hover:scale-105"><Link to="/user/settings" className="flex items-center gap-4 "><i className="fa-solid fa-gear"></i><p>Security Settings</p></Link></li>
                                <li className="hover:scale-105"><Link to="/user/changepassword" className="flex items-center gap-4 "><i className="fa-solid fa-lock"></i><p>Change Password</p></Link></li>
                                <li className="hover:scale-105"><button className="flex items-center gap-4 " onClick={handleSignOut} type="submit"><i className="fa-solid fa-right-from-bracket"></i><p>Sign Out</p></button></li>
                                {/* <Signout handleSignOut={handleSignOut} /> */}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* CLIENT OPTIONS FOR LARGE DEVICES (PCs, LAPTOPS) END*/}
            </nav>
        </>
    );
}

export default DashHeader;
