import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

function AppManager(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;
    const server = userData.server;

    useEffect(() => {
        document.title = "Manage your application";
    }, []);

    const [showDomainInput, setShowDomainInput] = useState(false);
    const [tempDomain, setTempDomain] = useState(false);
    const [needDatabase, setNeedDatabase] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [appType, setAppType] = useState("");
    const [dbName, setDbName] = useState("");
    const [dbUser, setDbUser] = useState("");
    const [dbPassword, setDbPassword] = useState("");
    const [adminUser, setAdminUser] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [error, setError] = useState("");
    const [dnsError, setDNSError] = useState("");
    const [domainError, setDomainError] = useState("");
    const [appError, setAppError] = useState("");
    const [dbPasswordVisible, setDbPasswordVisible] = useState(false);
    const [adminPasswordVisible, setAdminPasswordVisible] = useState(false);
    const [dbNameError, setDbNameError] = useState("");
    const [dbUserError, setDbUserError] = useState("");
    const [dbPasswordError, setDbPasswordError] = useState("");
    const [adminUserError, setAdminUserError] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [containerPort, setContainerPort] = useState();
    const [phpVersion, setPhpVersion] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [creatingApp, setCreatingApp] = useState(false);

    const handleCreateApplicationClick = () => {
        setShowDomainInput(true);
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleDomainTypeChange = (event) => {
        const { value } = event.target;

        if (value === "temporary") {
            setDomainName(`temp-${generateRandomString(8)}.poorva.cloud`);
            setDomainError("");
            setTempDomain(true);
        } else {
            setDomainName("");
            setTempDomain(false);
        }
    };

    const handleNeedDatabase = () => {
        setNeedDatabase(!needDatabase);
        setDbNameError("");
        setDbUserError("");
        setDbPasswordError("");
        setDbName("");
        setDbUser("");
        setDbPassword("");
    }

    const validateEmail = (value) => {
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(value)) {
            return "Please enter a valid email address.";
        }
        return "";
    };
    
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value); // Always update the state with the input value
        const errorMessage = validateEmail(value);
        setEmailError(errorMessage); // Conditionally set the error message
    };
    

    const togglePasswordVisibility = (string) => {
        if (string === "db") {
            setDbPasswordVisible(!dbPasswordVisible);
        };

        if (string === "admin") {
            setAdminPasswordVisible(!adminPasswordVisible);
        };
    };

    const validateInput = (value) => {
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }

        const regex = /^[a-zA-Z0-9_]*$/;
        if (!regex.test(value)) {
            return "Only alphanumeric characters and underscores are allowed.";
        }
        
        if (value.length < 6 || value.length > 12) {
            return "Input must be between 6 and 12 characters long.";
        }
        return "";
    };

    const handleDbNameChange = (e) => {
        const value = e.target.value;
        setDbName(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setDbNameError(errorMessage); // Conditionally set the error message
    };
    
    const handleDbUserChange = (e) => {
        const value = e.target.value;
        setDbUser(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setDbUserError(errorMessage); // Conditionally set the error message
    };

    const handleAdminUserChange = (e) => {
        const value = e.target.value;
        setAdminUser(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setAdminUserError(errorMessage); // Conditionally set the error message
    };

    const validatePassword = (value) => {
        const regex = /^[a-zA-Z0-9_!@#$%^&*~(){}[\]_\-+=<>?]{8,24}$/;
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }
        
        if (!regex.test(value)) {
            return "Password must be 8-24 characters long and can only contain alphanumeric characters and specific special characters.";
        }
        return "";
    };

    const handleDbPasswordChange = (e) => {
        const value = e.target.value;
        setDbPassword(value); // Always update the state with the input value
        const errorMessage = validatePassword(value);
        setDbPasswordError(errorMessage); // Conditionally set the error message
    };

    const handleAdminPasswordChange = (e) => {
        const value = e.target.value;
        setAdminPassword(value); // Always update the state with the input value
        const errorMessage = validatePassword(value);
        setAdminPasswordError(errorMessage); // Conditionally set the error message
    };

    const generateRandomPassword = (string) => {
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";
        const specialChars = "!@#$%^&*(){}[]~_-+=<>?";
    
        const getRandomChar = (charSet) => {
            const randomIndex = Math.floor(Math.random() * charSet.length);
            return charSet.charAt(randomIndex);
        };
    
        let password = '';
    
        // Ensure at least one uppercase, one lowercase, and one number
        password += getRandomChar(upperCaseChars);
        password += getRandomChar(lowerCaseChars);
        password += getRandomChar(numbers);
        password += getRandomChar(specialChars);
    
        // Generate the rest of the password
        for (let i = 0; i < 9; i++) {
            const randomCharSet = upperCaseChars + lowerCaseChars + numbers + specialChars;
            password += getRandomChar(randomCharSet);
        }
    
        // Shuffle the password characters
        password = password.split('').sort(() => Math.random() - 0.5).join('');
        if (string === "db") {
            setDbPassword(password);
            setDbPasswordError("");
        };

        if (string === "admin") {
            setAdminPassword(password);
            setAdminPasswordError("");
        }
    };

    const findPort = () => {
        if (server.applications.length === 0) {
            return 8081;
        };

        const minPort = 8081;
        const maxPort = 9999;

        for (let port = minPort; port <= maxPort; port++) {
            let portInUse = false;
            
            for (let app in server.applications) {
                if (server.applications[app].port === port) {
                  portInUse = true;
                  break;
                }
            }

            if (!portInUse) {
                console.log(`Available port found: ${port}`);
                return port; // You can return the port or set it to a container
            }
        }

        console.log("No available ports in the specified range.");
        return null; // No port found in the range

    };

    useEffect(() => {
        if (!containerPort) {
            setContainerPort(findPort());
        }
    }, [containerPort]);

    // console.log("PORT: ", containerPort);
    // console.log("SUBD: ", domainName.replace(".poorva.cloud", ""));
    // console.log("IPADD: ", server.publicIpAddress)

    const handleSubmit = async () => {
        setIsButtonDisabled(true);
        setCreatingApp(true);
        setError(""); // Clear previous errors

        let hasError = false;

        if (!domainName) {
            setDomainError("Domain name is required.");
            hasError = true;
        };

        if (appType === "") {
            setAppError("Select application type");
            hasError = true;
        };

        if (appType === "basic" && needDatabase) {
            if (dbName.length === 0) {
                setDbNameError("Database name is required");
                hasError = true;
            };
            if (dbUser.length === 0) {
                setDbUserError("Database user is required");
                hasError = true;
            };
            if (dbPassword.length === 0) {
                setDbPasswordError("Database user password is required");
                hasError = true;
            };
        }

        if (appType && appType !== "basic") {
            if (dbName.length === 0) {
                setDbNameError("Database name is required");
                hasError = true;
            };
            if (dbUser.length === 0) {
                setDbUserError("Database user is required");
                hasError = true;
            };
            if (dbPassword.length === 0) {
                setDbPasswordError("Database user password is required");
                hasError = true;
            };
            if (adminUser.length === 0) {
                setAdminUserError("Admin user is required");
                hasError = true;
            };
            if (adminPassword.length === 0) {
                setAdminPasswordError("Admin user password is required");
                hasError = true;
            };
            if (email.length === 0) {
                setEmailError("Email address is required");
                hasError = true;
            };
        }

        // if (domainError || appError || dbNameError || dbUserError || dbPasswordError || adminUserError || adminPasswordError || emailError) {
        if (hasError || domainError || appError || dbNameError || dbUserError || dbPasswordError || adminUserError || adminPasswordError || emailError) {
            // console.log("EXECUTED");
            setIsButtonDisabled(false);
            setCreatingApp(false);
            return;
        };

        // setContainerPort(findPort());

        if (!containerPort) {
            setError("No container port available.");
            return;
        }

        const payload = {
            tempDomain: tempDomain,
            applicationType: appType,
            applicationName: domainName,
            applicationPort: containerPort,
            applicationDbName: dbName,
            applicationDbUser: dbUser,
            applicationDbUserPassword: dbPassword,
            wpSiteTitle: domainName,
            wpAdminUser: adminUser,
            wpAdminPassword: adminPassword,
            admin_email: email,
            wpAdminEmail: email,
        };

        // console.log("PAYLOAD: ", payload);

        if (tempDomain) {
            const subDomain = domainName.replace('.poorva.cloud', '')

            try {
                const res_dns = await axios.post(`${process.env.REACT_APP_DNS_SSL_MANAGEMENT_API}/auth/addDnsRecord`,
                    {
                        subDomain: subDomain,
                        ipAddress: server.publicIpAddress,
                    },
                    { withCredentials: true }
                );
                // console.log(res_dns.data);

            } catch (err) {
                setDNSError("An error occurred while making changes to the DNS zone or applying the SSL certificate.");
                setTimeout(() => setDNSError(""), 5000);
            };
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/createApplication`,
                payload,
                { withCredentials: true }
            );
            // console.log(response.data);

        } catch (err) {
            setError("An error occurred while creating the application.");
            setTimeout(() => setError(""), 5000);
        };
        
        setCreatingApp(false);
        setIsButtonDisabled(false);
    };

    const handleCancel = () => {
        setShowDomainInput(false); // Hide the domain input section
        setTempDomain(false);
        setDomainName(""); // Optionally reset domain name
        setAppType(""); // Optionally reset application type
        setDbName(""); // Optionally reset database name
        setDbUser(""); // Optionally reset database user
        setDbPassword(""); // Optionally reset database password
        setAdminUser("");
        setAdminPassword("");
        setEmail("");
        setAdminUserError("");
        setAdminPasswordError("");
        setEmailError("");
        setAppError("");
        setDomainError("");
        setPhpVersion("");
        setError(""); // Clear any existing error message
        setDbNameError(""); // Clear database name error
        setDbUserError(""); // Clear database user error
        setDbPasswordError(""); // Clear database password error
        setIsButtonDisabled(false); // Disable the create button
    };

    // console.log("SSSSSSS: ", collectionName);

    //////////////  DUMMY DATA //////////////
    const applications = [
        {
            domain: "turfdev.in",
            appType: "wordpress",
            status: "running"
        },
        {
            domain: "poorva.cloud",
            appType: "magento",
            status: "stopped"
        }
    ];

    // console.log("SSSSSSS: ", applications.status);
    ////////////// //////////// //////////////

    return (
        <>
            <div>
                <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                    <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                        Dashboard
                    </Link>
                    <i className="fa-solid fa-chevron-right"></i>
                    <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                        Products
                    </Link>
                    <i className=" fa-solid fa-chevron-right"></i>
                    <p className="text-custom-green">
                        Application Manager
                    </p>
                </div>

                <div>
                    <h1 className="text-xl uppercase font-bold">Application Manager</h1>
                    <p className="font-light">Manage your applications- their domains, software versions, etc.</p>
                </div>

                {!showDomainInput && (
                    <button className="mt-8 elastic-button1 px-4" onClick={handleCreateApplicationClick}>
                        Create Application
                    </button>
                )}

                {showDomainInput && (
                    <div className="mt-8">
                        <h1 className="mb-4 text-md font-bold uppercase">Create A New Application</h1>

                        <div className="flex items-center gap-6">
                            <label className="text-xs sm:text-sm flex items-center">
                                <input
                                    type="radio"
                                    value="own"
                                    checked={tempDomain === false}
                                    onChange={handleDomainTypeChange}
                                    className="mr-1"
                                />
                                Own A Domain
                            </label>
                            <label className="text-xs sm:text-sm flex items-center">
                                <input
                                    type="radio"
                                    value="temporary"
                                    checked={tempDomain === true}
                                    onChange={handleDomainTypeChange}
                                    className="mr-1"
                                />
                                Temporary Domain
                            </label>
                        </div>
                        {!tempDomain && (
                            <input
                                type="text"
                                placeholder="Enter your domain name"
                                value={domainName}
                                onChange={(e) => {
                                    setDomainName(e.target.value);
                                    setDomainError("");
                                }}
                                className="mt-2 md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                            />
                        )}
                        {tempDomain && (
                            <input
                                type="text"
                                value={domainName}
                                readOnly
                                className="mt-2 md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                            />
                        )}
                        {domainError && <div className="text-red-700 text-xs font-bold">{domainError}</div>}

                        <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">Select Application Type</label>
                            <select
                                value={appType}
                                onChange={(e) => {
                                    setAppType(e.target.value)
                                    setAppError("");
                                }}
                                className="md:w-[50%] w-full border-2 border-custom-green bg-black text-white text-sm rounded-md px-1 py-[5.5px]"
                            >
                                <option value="" style={{ opacity: 0.5 }}>Select application type</option>
                                <option value="basic">Basic HTML/PHP</option>
                                <option value="wordpress">WordPress</option>
                                <option value="magento">Magento</option>
                                <option value="laravel">Laravel</option>
                                <option value="opencart">Opencart</option>
                            </select>
                            {appError && <div className="text-red-700 text-xs font-bold mt-1">{appError}</div>}
                        </div>

                        { appType === "basic" &&
                            <div className="mt-2">

                                <div className="">
                                    <label className="block text-sm font-medium text-gray-700">Select PHP Version (Optional)</label>
                                    <select
                                        value={phpVersion}
                                        onChange={(e) => setPhpVersion(e.target.value)}
                                        className="md:w-[50%] w-full border-2 border-custom-green bg-black text-white text-sm rounded-md px-1 py-[5.5px]"
                                    >
                                        <option value="" style={{ opacity: 0.5 }}>Select PHP Version</option>
                                        <option value="php74">PHP 7.4</option>
                                        <option value="php80">PHP 8.0</option>
                                        <option value="php81">PHP 8.1</option>
                                        <option value="php82">PHP 8.2</option>
                                        <option value="php83">PHP 8.3</option>
                                    </select>
                                </div>

                                <div className="mt-2">
                                    <label className="text-xs sm:text-sm flex items-center">
                                    <input
                                        type="checkbox"
                                        value=""
                                        checked={needDatabase}
                                        onChange={handleNeedDatabase}
                                        className="mr-1"
                                    />
                                    Need a database
                                    </label>
                                </div>

                                { needDatabase && 
                                    <div className="pl-4 mt-2">
                                        <label className="block text-sm font-medium text-gray-700">Database Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter database name"
                                            value={dbName}
                                            onChange={handleDbNameChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        {dbNameError && <div className="text-red-700 text-xs font-bold">{dbNameError}</div>}

                                        <label className="block text-sm font-medium text-gray-700 mt-2">Database User</label>
                                        <input
                                            type="text"
                                            placeholder="Enter database user"
                                            value={dbUser}
                                            onChange={handleDbUserChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        {dbUserError && <div className="text-red-700 text-xs font-bold">{dbUserError}</div>}

                                        <label className="block text-sm font-medium text-gray-700 mt-2">Database Password</label>
                                        <div className="flex items-center">
                                            <input
                                                type={dbPasswordVisible ? "text" : "password"}
                                                placeholder="Enter database password"
                                                value={dbPassword}
                                                onChange={handleDbPasswordChange}
                                                className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            />
                                            <i onClick={() => togglePasswordVisibility("db")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${dbPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                        </div>
                                        {dbPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{dbPasswordError}</p>}
                                        
                                        <button
                                            type="submit"
                                            onClick={() => generateRandomPassword("db")}
                                            className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                        >
                                            <i className="fa-solid fa-rotate"></i> Generate Password
                                        </button>
                                    </div>
                                }

                            </div>
                        }

                        { appType !== "" && appType !== "basic" &&
                            <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">Database Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter database name"
                                    value={dbName}
                                    onChange={handleDbNameChange}
                                    className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                />
                                {dbNameError && <div className="text-red-700 text-xs font-bold">{dbNameError}</div>}

                                <label className="block text-sm font-medium text-gray-700 mt-2">Database User</label>
                                <input
                                    type="text"
                                    placeholder="Enter database user"
                                    value={dbUser}
                                    onChange={handleDbUserChange}
                                    className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                />
                                {dbUserError && <div className="text-red-700 text-xs font-bold">{dbUserError}</div>}

                                <label className="block text-sm font-medium text-gray-700 mt-2">Database Password</label>
                                <div className="flex items-center">
                                    <input
                                        type={dbPasswordVisible ? "text" : "password"}
                                        placeholder="Enter database password"
                                        value={dbPassword}
                                        onChange={handleDbPasswordChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    <i onClick={() => togglePasswordVisibility("db")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${dbPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                {dbPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{dbPasswordError}</p>}
                                
                                <button
                                    type="submit"
                                    onClick={() => generateRandomPassword("db")}
                                    className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                >
                                    <i className="fa-solid fa-rotate"></i> Generate Password
                                </button>

                                <label className="block text-sm font-medium text-gray-700 mt-2">Admin User</label>
                                <div className="flex items-center">
                                    <input
                                        placeholder="Enter admin username"
                                        value={adminUser}
                                        onChange={handleAdminUserChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                </div>
                                {adminUserError && <div className="text-red-700 text-xs font-bold">{adminUserError}</div>}

                                <label className="block text-sm font-medium text-gray-700 mt-2">Admin Password</label>
                                <div className="flex items-center">
                                    <input
                                        type={adminPasswordVisible ? "text" : "password"}
                                        placeholder="Enter admin password"
                                        value={adminPassword}
                                        onChange={handleAdminPasswordChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    <i onClick={() => togglePasswordVisibility("admin")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${adminPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                {adminPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{adminPasswordError}</p>}

                                <button
                                    type="submit"
                                    onClick={() => generateRandomPassword("admin")}
                                    className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                >
                                    <i className="fa-solid fa-rotate"></i> Generate Password
                                </button>
                                
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700">Email Address</label>
                                    <input
                                        type="email"
                                        placeholder="Enter your email address"
                                        value={email}
                                        onChange={handleEmailChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    {emailError && <div className="text-red-700 text-xs font-bold">{emailError}</div>}
                                </div>
                            
                            </div>
                        }

                        <div className="mt-8 flex flex-col gap-4">
                            {dnsError &&
                                <div className="text-red-700 text-xs font-bold">{dnsError}</div> // Display error message
                            }
                            {error && (
                                <div className="text-red-700 text-xs font-bold">{error}</div> // Display error message
                            )}
                            <div className="flex justify-start items-center gap-2">
                                <button
                                    // className="button1"
                                    className="elastic-button1 w-32"
                                    onClick={handleSubmit}
                                    disabled={isButtonDisabled}
                                >
                                    {!creatingApp ? (
                                        <p>Create</p>
                                    ) : (
                                        <div className="spinner2"></div>
                                    )}
                                    
                                </button>
                                <button className="button2" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="w-auto py-5 m-5 flex flex-col gap-4">
                    <h1 className="text-md font-bold uppercase">Apps' list</h1>
                    {applications.map(app => (
                        <Link 
                            key={app.domain}
                            to={`/dashboard/manageserver/${collectionName}/app/${app.domain}?appDetails`}
                            className="w-full"
                        >
                            <div className="bg-gray-200 p-4 rounded drop-shadow cursor-pointer flex justify-between items-center">
                                <div className="flex flex-1 flex-col flex-wrap justify-start">
                                    <h1 className="text-lg uppercase font-bold">{app.domain}</h1>
                                    <p className="text-sm">{app.appType}</p>
                                </div>
                                <div className="flex flex-1 md:justify-start justify-end">
                                    <div className="flex items-center gap-2 uppercase text-sm">
                                        {app.status === "running" &&
                                            <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>
                                        }
                                        {app.status !== "running" &&
                                            <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>
                                        }
                                        <p className="hidden sm:block">{app.status}</p>
                                    </div>
                                </div>
                                <div className="hidden md:flex flex-1 justify-end">
                                    <div className="button-transparent border-2 border-black uppercase">Manage</div>
                                </div>
                            </div>
                        </Link>
                    ))}

                </div>
            </div>
        </>
    );
}

export default AppManager;
