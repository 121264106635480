import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

function Settings(userData) {
    const user = userData.user;

    useEffect(() => {
        document.title = 'Secure Account Login with 2FA';
    }, []);

    // const navigate = useNavigate()
    return (
        <>
            <DashboardHeader userData={user}/>
            <div className="flex lg:flex-row flex-col">
                <div className="lg:w-[27%]">
                    <DashboardOptions0 />
                </div>
                
                <div className="h-screen overflow-y-auto lg:w-[73%] text-black">
                    <div className="flex flex-col p-5">
                        <div className="p-5 text-white text-center bg-custom-green rounded-t rounded-b-none shadow-custom3">
                            <h1 className="text-xl text-center uppercase">Security Settings</h1>
                            {/* <p className="uppercase font-light text-sm"></p> */}
                        </div>
                        
                        <div className="lg:px-20 lg:py-5 p-5 text-left bg-white rounded-b rounded-t-none shadow-custom3">
                            {/* The content goes here */}

                            <div className="flex flex-col mt-4 md:gap-4 gap-2">
                                <div>
                                    <h1 className="text-lg uppercase font-bold">Two Factor Authentication</h1>
                                    <p className="">Two-factor authentication is currently <span className="font-bold text-red-600">disabled.</span></p>
                                    <p className="hidden">Two-factor authentication is currently <span className="font-bold text-green-600">enabled.</span></p>
                                    <p className="text-sm mt-2">We recommend enabling two-factor authentication to provide an extra layer of security to your account.</p>
                                </div>
                                
                            </div>

                            <div className="mt-12 flex md:w-[50%] gap-2">
                                <button className="button1 uppercase" type="submit">Enable 2FA</button>
                                <button className="hidden text-center border-2 border-color2 hover:bg-color2 hover:text-white rounded-full px-10 py-2 bg-transparent text-color2 uppercase" type="submit">Disable 2FA</button>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>

            <Copyright />
        </>
    )

}

export default Settings;
