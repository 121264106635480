import React, { useState } from "react";
import { Link, useSearchParams } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";

function Privacy(userData) {
    const user = userData.user;

    const [expand, setExpand] = useState("");

    const handleExpand = (name) => {
        setExpand(prevExpand => prevExpand === name ? "" : name);
    };

    return (
        <>
            <Header userData={user} />

            <div className="px-5 py-20 md:px-20 md:py-32 text-center">
                <h1 className="md:text-[80px] text-[50px] font-bold leading-tight tracking-tighter mb-5">Your <span className="text-custom-green">Privacy Matters</span> to Us</h1>
                <p>Understanding How We Safeguard Your Information.</p>
            </div>

            <div className="flex justify-center items-center bg-custom-green md:p-20 p-5">
                <div className="md:w-[80%] md:p-10 p-5 bg-white flex flex-col gap-5 rounded-3xl shadow-custom3">

                    <h1 className="text-[36px] font-bold">Privacy Policy</h1>
                    <p className="mb-10 text-sm">Last Updated on 28th Sep 2024</p>

                    <div className="text-sm leading-6">

                        <h3 className="text-base font-bold mb-6">INTRODUCTION</h3>
                        <p>This Privacy Policy <span className="font-bold">(“Privacy Policy”)</span> relates to the website <Link to="https://poorva.cloud">poorva.cloud</Link> and/or any sub-websites and/or associated domains (and/or sub-domains) of poorva.cloud (hereinafter referred to as the <span className="font-bold">“Site”</span>), the services provided by <span className="font-bold">Turf Dev Pvt. Ltd.</span>, the owner of the Site, (<span className="font-bold">“We“, “Us“, “Our“, “Ourselves”</span> and/or <span className="font-bold">“Poorva Cloud”</span>) and any related software applications (‘Apps’), where Personal Data is processed by the same (via the Site, any of Our Apps or otherwise) relating to You. In this Privacy Policy, <span className="font-bold">“You”</span> and <span className="font-bold">“Your”</span> and <span className="font-bold">“User”</span> refer to an identified or identifiable natural person being the User of the Site and/or client (or prospective client) of any of Our services. Our full details, including contact details, can be read below.</p>
                        <p className="mt-4 font-bold">You may be reading this Privacy Policy as a User or visitor of the Site or You may have been directed here by one (or more) of Our agreements, Our condensed policies or Our other notices (digital or otherwise).</p>
                        <p className="mt-4">Although this Privacy Policy provides detailed, layered information on how and why We generally process Personal Data (via the Site, any of Our Apps, or otherwise) as well as detailed information about Your various rights, the specific and tailor-made content of such agreements, condensed policies or other notices will, in most cases, provide You with more focused and detailed information on specific processing operations (for example, the specific legal basis for processing certain categories of Personal Data and the specific purpose for doing so depending on the matter at hand).</p>
                        <p className="mt-4">Although Our goal is to always be as clear and transparent as possible, We appreciate that legal documents can sometimes be difficult to read. However, We strongly encourage You to read this Privacy Policy (which is layered for Your convenience) with care. <span className="font-bold">Please do not hold back from contacting Us for any clarification You may need.</span> For example, if You need clarification on a specific legal basis We are relying on to process Your Personal Data for a specific processing operation, We would be happy to provide You with any such information You may need.</p>

                        {/* <h3 className="text-base font-bold mt-10 mb-6">POORVA CLOUD AS A DATA PROCESSOR</h3>
                        <p>If you are a legal or natural person acting as a DATA CONTROLLER seeking our services as a DATA PROCESSOR, please Click HERE. to view our Data Processing Agreement.</p>
                        <p className="mt-4">Please note that the general terms of how we process personal data (for example, the technical and organisational measures (security measures) we implement to protect personal data we process) are the same whether we are doing this in our capacity as a data controller or as a data processor on behalf of a data controller.</p> */}

                        {/* <h3 className="text-base font-bold mt-10 mb-6">APPLICABLE LAWS</h3>
                        <p>As an entity established in India, SAARC, the main privacy laws that are applicable to Us in so far as You are concerned, are as follows:</p>
                        <ul className="list-disc flex flex-col gap-2 ml-10 mt-4">
                            <li>The (Chapter 586 of the Laws of Malta) as well as the various subsidiary legislation issued under the same – the <span className="font-bold">‘DPA’</span>;</li>
                            <li>The <span className="font-bold">Regulation (EU) 2016/679</span> of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data, and repealing Directive 95/46/EC <span className="font-bold">(General Data Protection Regulation)</span> – the <span className="font-bold">‘GDPR’</span>.</li>
                        </ul>
                        <p className="mt-4">All the above referred to together as the <span className="font-bold">“Data Protection Laws“</span></p> */}

                        <h3 className="text-base font-bold mt-10 mb-6">WHAT IS PERSONAL DATA?</h3>
                        <p><span className="font-bold">“PERSONAL DATA”</span> means any information that identifies You as an individual or that relates to an identifiable individual.</p>
                        <p className="mt-4">Whenever it is not possible or feasible for Us to make use of anonymous and/or anonymised data (in a manner that does not identify any Users of the Site or customers of Our services), We are nevertheless <span className="font-bold">committed to protecting Your privacy and the security of Your Personal Data at all times.</span></p>
                        <p className="mt-4">We collect Personal Data in various ways both digitally via the Site (either when You choose to provide Us with certain data or in some cases, automatically or from third parties) as well as non-digitally (for example when You fill in a physical form to benefit from one or more of Our services).</p>

                        <h3 className="text-base font-bold mt-10 mb-6">PERSONAL DATA WE COLLECT ABOUT YOU</h3>
                        <p>There are various categories of Personal Data that We collect about You, namely:</p>
                        <div className="pl-4 flex flex-col gap-4 mt-4">
                            <div>
                                <div onClick={() => handleExpand("contact")} className="cursor-pointer"><i className={`${expand === "contact" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>CONTACT DETAILS:</div>
                                {expand === "contact" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>Full Name</li>
                                        <li>Mailing address</li>
                                        <li>Telephone or mobile number</li>
                                        <li>Email address</li>
                                        <li>Collaboration and Instant messaging</li>
                                    </ul>
                                }
                            </div>
                            <div>
                            <div onClick={() => handleExpand("registration")} className="cursor-pointer"><i className={`${expand === "registration" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>REGISTRATION DATA:</div>
                                {expand === "registration" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>Full Name</li>
                                        <li>Email address</li>
                                        <li>Business description</li>
                                        <li>Usernames</li>
                                        <li>Passwords</li>
                                        <li>Country and City of residence</li>
                                    </ul>
                                }
                            </div>
                            <div>
                                <div onClick={() => handleExpand("marketing")} className="cursor-pointer"><i className={`${expand === "marketing" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>MARKETING DATA:</div>
                                {expand === "marketing" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>Email address</li>
                                        <li>Mailing address</li>
                                        <li>Business description</li>
                                        <li>Interests</li>
                                        <li>Website data</li>
                                        <li>Online identifiers (including IP addresses and information generated via Your browser)</li>
                                    </ul>
                                }
                            </div>
                            <div>
                                <div onClick={() => handleExpand("tracking")} className="cursor-pointer"><i className={`${expand === "tracking" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>TRACKING DATA:</div>
                                {expand === "tracking" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>IP address</li>
                                        <li>Location Data</li>
                                        <li>Configuration information</li>
                                        <li>Operational logs</li>
                                        <li>Location of files and other data</li>
                                    </ul>
                                }
                            </div>
                            <div>
                                <div onClick={() => handleExpand("financial")} className="cursor-pointer"><i className={`${expand === "financial" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>FINANCIAL INFORMATION:</div>
                                {expand === "financial" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>Credit card information</li>
                                        <li>Paypal ID</li>
                                        <li>Bank details</li>
                                    </ul>
                                }
                            </div>
                            <div>
                                <div onClick={() => handleExpand("profile")} className="cursor-pointer"><i className={`${expand === "profile" ? "fa-square-minus" : "fa-square-plus" } mr-2 fa-solid`}></i>PROFILE DATA:</div>
                                {expand === "profile" &&
                                    <ul className="list-disc ml-4 leading-8">
                                        <li>Full Name</li>
                                        <li>Email address</li>
                                        <li>Telephone or mobile number</li>
                                        <li>Business description</li>
                                        <li>Country and City of residence</li>
                                    </ul>
                                }
                            </div>
                        </div>

                        <h3 className="text-base font-bold mt-10 mb-6">ADDITIONAL INFORMATION:</h3>
                        <p>In some cases, (for example, if You are a client [or prospective client] of Our services, via the Site, any App or otherwise) We may request additional Personal Data as a means of securely identifying You or for another similar lawful purpose. The additional information We may request from You to be able to provide You with Our services includes:</p>
                        <ul className="list-disc flex flex-col gap-2 ml-4 mt-4">
                            <li>More secure identification methods</li>
                            <li>Credentials/references</li>
                            <li>Details of Your next of kin</li>
                            <li>Certain special categories of data (sensitive Personal Data) such as health conditions/status (only where necessary and with the necessary safeguards in place).</li>
                        </ul>
                        <p className="mt-4">Many of the categories of Personal Data above are collected directly from You (for example, Your Contact Details and Your Registration Data). However, <span className="font-bold">WE MAY ALSO COLLECT PERSONAL DATA FROM OTHER SOURCES</span>, including publicly accessible databases, joint marketing partners, social media platforms and other third parties. We may also receive Personal Data about You from third parties when We need to confirm Your Contact Details.  Should this be the case, and <span className="font-bold">when acting as a data controller</span>, We will take all measures as required by law to further inform You about the source of such Personal Data as well as the categories of Personal Data We collect and process.  There are certain instances at law where We are specifically forbidden from disclosing to You such activity (for example, when carrying out due diligence for anti-money laundering purposes).</p>
                        <p className="mt-4">When acting as a data processor, Poorva Cloud processes personal data on behalf of one or more data controllers. In such cases it is the data controller’s obligation to provide data subjects with the said information. In case of uncertainty over who is the data controller, please contact us at the details below.</p>
                        <p className="mt-4"><span className="font-bold">For a detailed description of the reasons why We process (as a data controller)  the categories of Personal Data above</span> (and any other specific Personal Data We process) as well as the corresponding legal ground(s) for doing so, please see the ‘What We Use Your Personal Data For (Purpose of Processing)’ below.</p>
                        <p className="mt-4"><span className="font-bold">For information/Personal Data that We may collect automatically</span> via the Site, please see the Cookies section below.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">SOCIAL MEDIA</h3>
                        <p>If You choose to connect one or more of Your <span className="font-bold">social media</span> accounts with Our Site to enable the sharing of Personal Data via social media platforms, certain categories of Personal Data relating to You from Your social media account(s) will be shared with Us.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">HOW AND WHY WE COLLECT PERSONAL DATA</h3>
                        <p>As a general rule, when acting as a data controller, We do not collect any Personal Data, that is, information that identifies You as an individual <span className="font-bold">other than that which You choose to provide to Us</span> such as the data (including Contact Details and Registration Data) You provide when registering with Our Site (where this is available), when contacting Us with enquiries relating to Our goods and/or services, when subscribing to any service offered by Us or via Our Site, such as any newsletters as may be issued by Us from time to time or even when subscribing to any offers We (and/or Our affiliates and/or corporate partners) may offer from time to time (see Personal Data We Collect About You above).</p>
                        <p className="mt-4">Unless otherwise specified and subject to various controls, as a general rule, <span className="font-bold">We only collect Personal Data (from You or elsewhere) that We:</span></p>
                        <ul className="list-disc flex flex-col gap-2 ml-4 mt-4 font-bold">
                            <li>Need to be able to provide You with the goods and/or services You request from Us</li>
                            <li>Are legally required to collect/use and to keep for a predetermined period of time</li>
                            <li>Believe to be necessary for Our legitimate business interests</li>
                        </ul>
                        <p className="mt-4">For a detailed description of the reasons why We process specific categories of personal data as well as the corresponding legal ground(s) for doing so, please see the ‘What We Use Your Personal Data For (Purpose of Processing)’ below.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">PERSONAL DATA RELATING TO THIRD PARTIES</h3>
                        <p>By providing Us with or allowing Us to access Personal Data relating to individuals other than Yourself, You are letting Us know that You have the authority to send Us that Personal Data or the authority to permit Us to access those data in the manner described in this Privacy Policy.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">WHAT WE USE YOUR PERSONAL DATA FOR (PURPOSE OF PROCESSING)</h3>
                        <p>The following is description (in a clear and plain manner) of what We use Your Personal Data for and the corresponding legal ground(s) we rely on for doing so.</p>
                        <p className="mt-4">For more detail on what is meant by terms such as <span className="font-bold">‘Contact Details’</span>, <span className="font-bold">‘Registration Data’</span> and other categories of Personal Data used in the tables below, please see the section above relating to Personal Data We Collect About You.</p>
                        <p className="mt-4"><span className="font-bold">Please note that WHERE WE RELY ON YOUR CONSENT, THIS CAN BE WITHDRAWN AT WILL</span> (See Special Note on Consent below).</p>

                        <h3 className="text-base font-bold mt-10 mb-6">PROSPECTIVE CLIENTS/USERS OF THE SITE/NEW CLIENTS OF OUR SERVICES:</h3>
                        <div className="overflow-x-auto">
                            <table className="text-left md:w-full w-[300%] text-xs md:text-sm">
                                <thead>
                                    <tr>
                                        <th className="w-[50%] border-t border-l border-black border-opacity-20 px-2">PURPOSE OF THE PROCESSING</th>
                                        <th className="w-[25%] border-t border-l border-black border-opacity-20 px-2">CATEGORIES OF PERSONAL DATA</th>
                                        <th className="w-[25%] border-t border-x border-black border-opacity-20 px-2">LEGAL BASIS FOR PROCESSING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To register users (directly or through third-parties social signups).</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Contractual necessity<br/>Legitimate interest</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To manage our relationship with you</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Legitimate Interest</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To be able to provide You with marketing and promotional offers that You may have requested from Us or that We may be authorized at law to provide to You</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Marketing Data<br/>Tracking Data</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Consent<br/>Or<br/>Legitimate Interests (where We don’t need Your Consent)</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To establish and investigate any suspicious behaviour in order to protect Our business from any risk and fraud</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Legitimate Interest (detection and prevention of fraud)</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Subscribing to a newsletter or mailing list</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Consent</td>
                                    </tr>
                                    <tr>
                                        <td className="border-y border-l border-black border-opacity-20 px-2">Send You text messages and push notifications</td>
                                        <td className="border-y border-l border-black border-opacity-20 px-2">Contact Details<br/>Registration data</td>
                                        <td className="border border-black border-opacity-20 px-2">Contractual Necessity<br/>Or<br/>Consent</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <h3 className="text-base font-bold mt-10 mb-6">ONGOING CLIENTS OF OUR SERVICES:</h3>
                        <div className="overflow-auto">
                            <table className="text-left md:w-full w-[300%] text-xs  md:text-sm">
                                <thead>
                                    <tr>
                                        <th className="w-[50%] border-t border-l border-black border-opacity-20 px-2">PURPOSE OF THE PROCESSING</th>
                                        <th className="w-[25%] border-t border-l border-black border-opacity-20 px-2">CATEGORIES OF PERSONAL DATA</th>
                                        <th className="w-[25%] border-t border-x border-black border-opacity-20 px-2">LEGAL BASIS FOR PROCESSING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Your being able to participate in a survey, feedback, feature demonstration, poll or a discussion across Our digital properties</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Consent</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Maintain records on Our systems</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details<br/>Tracking Data<br/>Profile Data<br/>Financial Information</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Contractual Necessity<br/>Legitimate Interest (to ensure We have accurate records)</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Continue to manage Our relationship with You</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details<br/>Tracking Data<br/>Financial Information</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Contractual Necessity<br/>Compliance with the Legal Obligations</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To process and manage payments transactions (where applicable)</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Financial Information</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Contractual Necessity</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To be able to provide You with (some or all of) Our services</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details<br/>Profile data<br/>Marketing Data</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Contractual Necessity</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Comply with legal and regulatory obligations</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details<br/>Tracking data<br/>Financial Information</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Legal Obligation</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To establish and investigate any suspicious behaviour in order to protect Our business from any risk and fraud</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Legitimate Interest (Recovery and detection & prevention of fraud)</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Subscribing to a newsletter or mailing list</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Registration Data<br/>Contact Details</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Consent</td>
                                    </tr>
                                    <tr>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">To be able to provide You with marketing material that You may have requested from Us or that We may be authorized at law to provide to You</td>
                                        <td className="border-t border-l border-black border-opacity-20 px-2">Marketing Data<br/>Tracking Data</td>
                                        <td className="border-t border-x border-black border-opacity-20 px-2">Consent (where we need it)<br/>Or<br/>Legitimate Interests (where We don’t need Your Consent)</td>
                                    </tr>
                                    <tr>
                                        <td className="border-y border-l border-black border-opacity-20 px-2">Send You text messages and push notifications</td>
                                        <td className="border-y border-l border-black border-opacity-20 px-2">Contact Details<br/>Registration data<br/>Contractual Necessity</td>
                                        <td className="border border-black border-opacity-20 px-2">Consent</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        	
                        <p className="mt-4">Should We need to process Your data for a new purpose in the future, which is entirely unrelated to the above, We will inform You of such processing in advance and You may exercise Your applicable rights (as explained below) in relation to such processing.</p>
                        <p className="mt-4">Finally, do note that without certain Personal Data relating to You, We may not be in the position to provide some or all of the services You expect from Us or even to guarantee the full functionality of Our Site.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">SPECIAL NOTE ON CONSENT</h3>
                        <p>For the avoidance of all doubt, We would like to point out that in those limited cases where We cannot or choose not to rely on another legal ground (for example, Our legitimate interests), We will process Your Personal Data on the basis of Your consent. In some cases, We will require Your explicit consent, for example, when, on the basis of Your explicit consent We will process special categories of data concerning You such as Your health data or data that reveals Your race or ethnic origin (what was previously referred to as ‘sensitive Personal Data’) that might be needed as part of Our processing of Your application for a credit facility with Us.</p>
                        <p className="mt-4">In those cases where We, as a data controller, process on the basis of Your consent (<span className="font-bold">which We will never presume</span> but which We shall have obtained in a clear and manifest manner from You), <span className="font-bold">YOU HAVE THE RIGHT TO WITHDRAW YOUR CONSENT AT ANY TIME</span> and this, in the same manner as You shall have provided it to Us.</p>
                        <p className="mt-4">Should You exercise Your right to withdraw Your consent at any time (by writing to Us at the physical or email address below), We will determine whether at that stage an alternative legal basis exists for processing Your Personal Data (for example, on the basis of a legal obligation to which We are subject) where We would be legally authorised (or even obliged) to process Your Personal Data without needing Your consent and if so, notify You accordingly.</p>
                        <p className="mt-4">When We ask for such Personal Data, You may always decline, however should You decline to provide Us with necessary data that We require to provide requested services, We may not necessarily be able to provide You with such services (especially if consent is the only legal ground that is available to Us).</p>
                        <p className="mt-4">Just to clarify, <span className="font-bold">consent is not the only ground that permits Us to process Your Personal Data</span>. In the last preceding section above We pointed out the various grounds that We rely on when processing Your Personal Data for specific purposes.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">ACCURACY OF PERSONAL DATA</h3>
                        <p>All reasonable efforts are made to keep any Personal Data We may hold about You up-to-date and as accurate as possible. You can check the information that We hold about You at any time by accessing and updating Your account information in the Poorva Cloud platform to achieve accuracy, in addition you can contact us in the manner explained below. If You find any inaccuracies, We will correct them and where required, delete them as necessary. <span className="font-bold">Please see below for a detailed list of Your legal rights in terms of any applicable data protection law.</span></p>

                        <h3 className="text-base font-bold mt-10 mb-6">DIRECT MARKETING</h3>
                        <p><span className="font-bold">We only send mail, messages and other communications relating to marketing where We are authorised to do so at law.</span> In most cases We rely on Your consent to do so (especially where We use electronic communications). If, at any time, You no longer wish to receive direct marketing communications from Us You can exercise this right by clicking the “unsubscribe” or “opt-out” link in the marketing emails we send you or let Us know by contacting Us at the details below or update Your preferences on any of Our Site(s) or Apps (where applicable).</p>
                        <p className="mt-4">In the case of direct marketing sent by electronic communications (where We are legally authorised to do so) You will be given an easy way of opting out (or unsubscribing) from any such communications.</p>
                        <p className="mt-4 font-bold">Please note that even if You withdraw any consent You may have given Us or if You object to receiving such direct marketing material from Us (in those cases where We do not need Your consent), from time to time We may still need to send You certain important communications from which You cannot opt-out.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">TRANSFERS TO THIRD COUNTRIES</h3>
                        <p>As a general rule, the data We process about You (collected via the Site, any of our Apps or otherwise) will be stored and processed within the European Union (EU)/European Economic Area (EEA) or any other non-EEA country deemed by the European Commission to offer an adequate level of protection (the so-called ‘white-listed’ countries listed here).</p>
                        <p className="mt-4">In some cases, it may be necessary for Us to transfer Your Personal Data to a non-EEA country not considered by the European Commission to offer an adequate level of protection. For example, for the implementation of Your desired transaction it can be necessary that We disclose Your Personal Data to other payment processors outside the EEA.</p>
                        <p className="mt-4">In such cases, apart from all appropriate safeguards that We implement, in any case, to protect Your Personal Data, We have put in place additional adequate measures. For example, We have ensured that the recipient is bound by the EU Standard Contractual Clauses (the EU Model Clauses) designed to protect Your Personal Data as though it were an intra-EEA transfer. You are entitled to obtain a copy of these measures by contacting Us as explained below</p>

                        <h3 className="text-base font-bold mt-10 mb-6">INTERNET COMMUNICATIONS</h3>
                        <p>You will be aware that data sent via the Internet may be transmitted across international borders even where sender and receiver of information are located in the same country. We cannot be held responsible for anything done or omitted to be done by You or any third party in connection with any Personal Data prior to Our receiving it including but not limited to any transfers of Personal Data from You to Us via a country having a lower level of data protection than that in place in the European Union, and this, by any technological means whatsoever (for example, WhatsApp, Skype, Dropbox etc.).</p>
                        <p className="mt-4">Moreover, <span className="font-bold">We shall accept no responsibility or liability whatsoever for the security of Your data while in transit through the internet unless Our responsibility results explicitly from a law having effect in India.</span></p>

                        <h3 className="text-base font-bold mt-10 mb-6">AUTHORISED DISCLOSURES</h3>
                        <p>Without prejudice to anything contained in this Privacy Policy and in the interest of full transparency, We reserve the right to disclose (and otherwise process) any relevant Personal Data relating to You which We may be processing (including in certain cases relevant IP addresses) to authorised third parties in or outside the EU/EEA if such disclosures are allowed under the Data Protection Laws (whether or not You have provided Your consent) including but not limited to:</p>
                        <p className="mt-4">
                            1. For the purpose of preventing, detecting or suppressing fraud (for example, if You provide false or deceptive information about Yourself or attempt to pose as someone else, We may disclose any information We may have about You in Our possession so as to assist any type of investigation into Your actions);
                            <br/>2. In the event of Poorva Cloud being involved in a merger, sale, restructure, acquisition, joint venture, assignment, transfer;
                            <br/>3. To protect and defend Our rights (including the right to property), safety, or those of Our affiliates, of Users of Our Site or even Your own;
                            <br/>4. To protect against abuse, misuse or unauthorised use of Our Site;
                            <br/>5. For any purpose that may be necessary for the performance of any agreement You may have entered into with Us (including the request for provision of services by third parties) or in order to take steps at Your request prior to entering into a contract;
                            <br/>6. To comply with any legal obligations such as may arise by way of response to any Court subpoena or order or similar official request for Personal Data; or
                            <br/>7. As may otherwise be specifically allowed or required by or under any applicable law (for example, under anti-money laundering legislation).
                        </p>

                        <h3 className="text-base font-bold mt-10 mb-6">SHARING OF PERSONAL DATA WITH OTHER CATEGORIES OF RECIPIENTS</h3>
                        <p>Relevant data will also be disclosed or shared as appropriate (and in all cases in line with the Data Protection Laws) to/with members and staff of Poorva Cloud, to/with other entities within the Poorva Cloud Group and/or to/with affiliated entities and/or subcontractors if pertinent to any of the purposes listed in this Privacy Policy (including to/with Our services providers who facilitate the functionality of the Site and/or any service You may require). Personal information will only be shared by Us to provide the services You request from Us or for any other lawful reason (including authorised disclosures not requiring Your consent).</p>
                        <p className="mt-4">Any such authorised disclosures will be done in accordance with the Data Protection laws (for example all Our processors are contractually bound by the requirements in the said Data Protection Laws, including a strict obligation to keep any information they receive confidential and to ensure that their employees/personnel are also bound by similar obligations). The said service providers (Our processors) are also bound by a number of other obligations (in particular, Article 28 of the GDPR).</p>
                        <p className="mt-4 font-bold">Your Personal Data will never be shared with third parties for their marketing purposes (unless You give Your consent thereto).</p>
                        <p className="mt-4">The list of third parties to whom We may disclose to and/or share Your Personal Data with is available on request.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">SECURITY MEASURES</h3>
                        <p>The personal information which We may hold (and/or transfer to any affiliates/partners/subcontractors as the case may be) will be held securely in accordance with Our internal security policy and the law.</p>
                        <p className="mt-4">We use reasonable efforts to safeguard the confidentiality of any and/or all Personal Data that We may process relating to You and regularly review and enhance Our technical, physical and managerial procedures so as to ensure that Your Personal Data is protected from:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>unauthorised access</li>
                            <li>improper use or disclosure</li>
                            <li>unauthorised modification</li>
                            <li>unlawful destruction or accidental loss</li>
                        </ul>
                        <p className="mt-4">To this end We have implemented security policies, rules and technical and organisational measures to protect the Personal Data that We may have under Our control. All our members, staff and data processors (including specific subcontractors, including cloud service providers, who may have access to and are associated with the processing of Personal Data, are further obliged (under contract) to respect the confidentiality of Our Users’ or clients’ Personal Data as well as other obligations as imposed by the Data Protection Laws.</p>
                        <p className="mt-4">Despite all the above, We cannot guarantee that a data transmission or a storage system can ever be 100% secure. For more information about Our security measures, please contact Us in the manner described below.</p>
                        <p className="mt-4">Authorised third parties, and external/third party service providers, with permitted access to Your information (as explained in this Privacy Policy) are specifically required to apply appropriate technical and organisational security measures that may be necessary to safeguard the Personal Data being processed from unauthorised or accidental disclosure, loss or destruction and from any unlawful forms of processing.</p>
                        <p className="mt-4">As stated above, the said service providers (Our data processors) are also bound by a number of other obligations in line with the Data Protection Laws (particularly, Article 28 of the GDPR). <span className="font-bold">For the avoidance of all doubt, these are the same obligations that we are bound by when acting as data processor on behalf of one or more data controllers.</span></p>

                        <h3 className="text-base font-bold mt-10 mb-6">RETENTION PERIODS</h3>
                        <p><span className="font-bold">We will retain Your Personal Data only for as long as is necessary</span> (taking into consideration the purpose for which it was originally obtained). The criteria We use to determine what is ‘necessary’ depends on the particular Personal Data in question and the specific relationship We have with You (including its duration).</p>
                        <p className="mt-4">Our normal practice is to determine whether there is/are any specific EU and/or Maltese law(s) (for example tax or corporate laws) <span className="font-bold">permitting</span> or even <span className="font-bold italic">obliging</span> Us to keep certain Personal Data for a certain period of time (in which case We will keep the Personal Data for the maximum period indicated by any such law). For example, any data that can be deemed to be ‘accounting records’ must be kept for ten (10 years).</p>
                        <p className="mt-4">We would also have to determine whether there are any laws and/or contractual provisions that may be invoked against Us by You and/or third parties and if so, what the prescriptive periods for such actions are (this is usually five (5) years). In the latter case, We will keep any relevant Personal Data that We may need to defend Ourselves against any claim(s), challenge(s) or other such action(s) by You and/or third parties for such time as is necessary.</p>
                        <p className="mt-4 font-bold">Where Your Personal Data is no longer required by Us, We will either securely delete or anonymise the Personal Data in question.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">PROCESSING FOR STATISTICAL REASONS</h3>
                        <p>Research and statistics using User or client information is only carried out so that We may understand Our Users’ and/or clients’ needs, to develop and improve Our services/activities. In any case, We will always ensure to obtain any consent We may legally require from You beforehand. As in all other cases, We will also ensure to implement all appropriate safeguards as may be necessary.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">LINKS TO THIRD PARTY SOURCES</h3>
                        <p>Links that We provide to third-party websites are clearly marked and We are not in any way whatsoever responsible for (nor can We be deemed to endorse in any way) the content of such websites (including any applicable privacy policies or data processing operations of any kind). We suggest that You should read the privacy policies of any such third-party websites.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">COOKIES</h3>
                        <p>When You visit Our Site, We collect certain categories of Personal Data automatically through the use of cookies and similar technologies.</p>
                        <p className="mt-4">For more detailed information including what cookies are and how and why We process such data in this manner (including the difference between essential and non-essential cookies) please read Our detailed but easy-to-read Cookie Policy.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">MINORS</h3>
                        <p>The Site and Our services are not intended to be used by any persons under the age of thirteen (13) and therefore We will never intentionally collect any Personal Data from such persons. If You are under the age of consent, please consult and get Your parent’s or legal guardian’s permission to use the Site and to use Our services.</p>
                        <p className="mt-4">We shall consider that any Personal Data of persons under the age of thirteen (13) received by Us, shall be sent with the proper authority and that the sender can demonstrate such authority at any time, upon Our request.</p>

                        <h3 className="text-base font-bold mt-10 mb-6">YOUR RIGHTS UNDER THE DATA PROTECTION LAWS</h3>
                        <p>Before addressing any request You make with Us, We may first need to verify Your identity. In all cases We will try to act on Your requests as soon as reasonably possible.</p>
                        <p className="mt-4">As explained in the Retention Periods section above, We may need to keep certain Personal Data for compliance with Our legal retention obligations but also to complete transactions that You requested prior to the change or deletion that You requested.</p>
                        <p className="mt-4 font-bold">Please note that when acting as a DATA PROCESSOR, Poorva Cloud processes personal data on behalf of one or more data controllers. In such cases queries will generally be direct to our client(s) – the data controller(s).</p>
                        <p className="mt-4">When We act as a data controller, Your various rights at law in so far as We are concerned include:</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT OF ACCESS</h4>
                        <p>You may, at any time request Us to confirm whether or not We are processing Personal Data that concerns You and, if We are, You shall have the right to access that Personal Data and to the following information:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>What Personal Data We have,</li>
                            <li>Why We process them,</li>
                            <li>Who We disclose them to,</li>
                            <li>How long We intend on keeping them for (where possible),</li>
                            <li>Whether We transfer them abroad and the safeguards We take to protect them,</li>
                            <li>What Your rights are,</li>
                            <li>How You can make a complaint,</li>
                            <li>Where We got Your Personal Data from and</li>
                            <li>Whether We have carried out any automated decision-making (including profiling) as well as related information.</li>
                        </ul>
                        <p className="mt-8"></p>Upon request, We shall (without adversely affecting the rights and freedoms of others including Our own) provide You with a copy of the Personal Data undergoing processing within one month of receipt of the request, which period may be extended by two months where necessary, taking into account the complexity and number of the requests. We shall inform You of any such extension within one month of receipt of the request, together with the reasons for the delay.
                        
                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO RECTIFICATION</h4>
                        <p>You have the right to rectify Your Personal Data. You can exercise this right by accessing Your account information in Poorva Cloud platform and updating the information which You like to rectify. You can also ask Us to rectify inaccurate Personal Data and to complete incomplete Personal Data concerning You. We may seek to verify the accuracy of the data before rectifying it.</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO ERASURE (THE RIGHT TO BE FORGOTTEN)</h4>
                        <p>You have the right to ask Us to delete Your Personal Data and We shall comply without undue delay but only where:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>The Personal Data are no longer necessary for the purposes for which they were collected; or</li>
                            <li>You have withdrawn Your consent (in those instances where We process on the basis of Your consent) and We have no other legal ground to process Your Personal Data; or</li>
                            <li>You shall have successfully exercised Your right to object (as explained below); or</li>
                            <li>Your Personal Data shall have been processed unlawfully; or</li>
                            <li>There exists a legal obligation to which We are subject; or</li>
                            <li>Special circumstances exist in connection with certain children’s rights.</li>
                        </ul>
                        <p className="mt-8">In any case, We shall not be legally bound to comply with Your erasure request if the processing of Your Personal Data is necessary:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>for compliance with a legal obligation to which We are subject (including but not limited to Our data retention obligations); or</li>
                            <li>for the establishment, exercise or defence of legal claims.</li>
                        </ul>
                        <p className="mt-8">There are other legal grounds entitling Us to refuse erasure requests although the two instances above are the most likely grounds that may be invoked by Us to deny such requests.</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO DATA RESTRICTION</h4>
                        <p>You have the right to ask Us to restrict (that is, store but not further process) Your Personal Data but only where:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>The accuracy of Your Personal Data is contested (see the <span className="font-bold">right to data rectification</span> above), for a period enabling Us to verify the accuracy of the Personal Data; or</li>
                            <li>The processing is unlawful and You oppose the erasure of Your Personal Data; or</li>
                            <li>We no longer need the Personal Data for the purposes for which they were collected but You need the Personal Data for the establishment, exercise or defence of legal claims; or</li>
                            <li>You exercised Your right to object and verification of Our legitimate grounds to override Your objection is pending.</li>
                        </ul>
                        <p className="mt-8">Following Your request for restriction, except for storing Your Personal Data, We may only process Your Personal Data:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>Where We have Your consent; or</li>
                            <li>For the establishment, exercise or defence of legal claims; or</li>
                            <li>For the protection of the rights of another natural or legal person; or</li>
                            <li>For reasons of important public interest.</li>
                        </ul>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO DATA PORTABILITY</h4>
                        <p>You have the right to ask Us to provide Your Personal Data (that You shall have provided to Us) to You in a structured, commonly used, machine-readable format, or (where technically feasible) to have it ‘ported’ directly to another data controller, provided this does not adversely affect the rights and freedoms of others. This right shall only apply where:</p>
                        <ul className="mt-4 pl-4 list-disc leading-8">
                            <li>The processing is based on Your consent or on the performance of a contract with You; and <span className="font-bold">Your Right to Withdraw Consent (when We rely on consent)</span>.</li>
                        </ul>
                        
                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO WITHDRAW CONSENT (WHEN WE RELY ON CONSENT)</h4>
                        <p>See Our Special Note on Consent for detailed information on this right (<span className="font-bold">which You may exercise at any time</span>).</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO OBJECT TO CERTAIN PROCESSING</h4>
                        <p>In those cases where We only process Your Personal Data when this is 1.) necessary for the performance of a task carried out in the public interest or 2.) when processing is necessary for the purposes of the legitimate interests pursued by Us or by a third party, You shall have the right to object to processing of Your Personal Data by Us. Where an objection is entered, the processing of data shall cease, unless We as data controller provide compelling and legitimate grounds requiring the continuation of the data processing which outweigh the objections You may have raised.</p>
                        <p className="mt-4">When Your data is processed for direct marketing purposes, You have the right to object <span className="font-bold">at any time</span> to the processing of Your Personal Data, which includes profiling to the extent that it is related to such direct marketing.</p>
                        <p className="mt-4">For the avoidance of all doubt, when We process Your Personal Data when this is necessary for the performance of a contract, when necessary for compliance with a legal obligation to which We are subject or when processing is necessary to protect Your vital interests or those of another natural person, this general right to object shall <span className="font-bold">not</span> subsist.</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">YOUR RIGHT TO LODGE A COMPLAINT</h4>
                        <p>You also have the right to lodge complaints with the appropriate Data Protection Supervisory Authority. The competent authority in India is the Office of the Data Protection Board of India (DPBI).</p>
                        <p className="mt-4">We kindly ask that You please attempt to resolve any issues You may have with Us first (even though, as stated above, You have a right to contact the competent authority at any time).</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">WHAT WE MAY REQUIRE FROM YOU</h4>
                        <p>As one of the security measures We implement, before being in the position to help You exercise Your rights as described above We may need to verify Your identity to ensure that We do not disclose to or share any Personal Data with any unauthorised individuals.</p>

                        <h4 className="text-sm font-bold mt-10 mb-6">TIME LIMIT FOR A RESPONSE</h4>
                        <p>We try to reply to all legitimate requests within one month from receiving them. In some particular cases (for example, if the matter is particularly complex or if You send Us multiple requests), it may take Us longer than a month. In such cases, we will notify You accordingly and keep You updated.</p>

                        
                        
                        <h3 className="text-base font-bold mt-10 mb-6">TURF DEV PVT. LTD. DETAILS</h3>
                        <p><span className="font-bold">TURF DEV PVT. LTD.</span> a company registered in <span className="font-bold">India</span> with company registration number <span className="font-bold">081264</span> and whose registered office address is at <span className="font-bold">110, Shri Vihar Colony, Durgapura, Jaipur, Rajasthan, ZIP/PIN- 302018</span> is the data controller responsible for processing Your Personal Data that takes place via the Site or in the manner explained above (or in the Terms of Service or notice that directed You here).</p>
                        <p className="mt-4">If You have any questions/comments about privacy or should You wish to exercise any of Your individual rights, please contact Us at: <span className="font-bold italic">privacy@poorva.cloud</span> or by writing to <span className="font-bold">110, Shri Vihar Colony, Durgapura, Jaipur, Rajasthan, ZIP/PIN- 302018</span></p>

                        <h3 className="text-base font-bold mt-10 mb-6">UPDATES</h3>
                        <p>We reserve the right, at Our complete discretion, to change, modify, add and/or remove portions of this Privacy Policy at any time. If You are an existing client with whom We have a contractual relationship You shall be informed by Us of any changes made to this Privacy Policy (as well as other terms and conditions relevant to the Site). We shall also archive and store previous versions of the Privacy Policy for Your review.</p>
                        <p className="mt-4">As a User of the Site with which We have no contractual relationship or even a lawful way of tracing, it is in Your interest to regularly check for any updates to this Privacy Policy (which are usually deemed to be effective on the date they are published on the Site), in the event that Our attempts to notify You of such updates do not reach You.</p>

                        <p className="mt-10"></p>Last Updated on 28th Sep 2024
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )

}

export default Privacy;