import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import LeftDiv from "../components/LeftDiv";

import companyLogo from '../../../images/poorva-cloud-logo-transparent.png';
import Copyright from "../../private-routes/components/Copyright";

const SignupWithEmail = (userData) => {
    useEffect(() => {
        document.title = "Create your account at Poorva Cloud";
    }, []);

    const userdata = userData.user;
	const navigate = useNavigate();

	useEffect(() => {
		if (userdata) {
			navigate("/dashboard"); // Redirect to the dashboard if the user is already signed in
		}
	});

    const [user, setUser] = useState({
        fullName: "",
        userName: "",
        passWord: "",
        reenterPassword: ""
    });

    const buttonRef = useRef(null); // Create a ref
    const [buttonWidth, setButtonWidth] = useState(null); // State to store button width

    useEffect(() => {
        // document.title = "Log in to your Poorva Cloud account";

        if (buttonRef.current) {
            setButtonWidth(buttonRef.current.offsetWidth); // Calculate button width
        }
    }, [buttonRef]);

    const [message, setSignupMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [warningMessages, setWarningMessages] = useState('');
    const [isSignupButtonDisabled, setIsSignupButtonDisabled] = useState(true);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const fieldErrors = {};
    // const warningMessage = {};

    const handleCheckboxChange = () => {
        setAgreeChecked(!agreeChecked);
    };

    // Function to check if a string contains at least one uppercase, one lowercase, and one number
    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?]).{8,}$/;
        return regex.test(password);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        let warningMessage = '';
        // setErrorMessages(warningMessage);

        if (name === 'fullName' && value.length === 0) {
            warningMessage = 'Required field!';
        } else if (name === 'fullName' && value.length > 40) {
            warningMessage = 'fullName should not be more than 40 characters.';
        }

        // if (name === 'lastname' && value.length > 15) {
        //     warningMessage = 'Lastname should not be more than 15 characters.';
        // }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name === 'userName' && value.length === 0) {
            warningMessage = 'Required field!';
        } else if (name === 'userName' && !emailRegex.test(value)) {
            warningMessage = 'Email is not in the proper format.';
        }

        if (name === 'passWord' && value.length === 0) {
            warningMessage = 'Required field!';
        } else if (name === 'passWord' && !isStrongPassword(value)) {
            warningMessage = 'Password should contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character and be at least 8 characters long.';
        }

        if (name === 'reenterPassword' && value.length === 0) {
            warningMessage = 'Required field!';
        } else if (name === 'reenterPassword' && value !== user.passWord) {
            warningMessage = 'Passwords do not match.';
        }

        // setWarningMessages(warningMessage);

        setWarningMessages((prevWarningMessages) => ({
            ...prevWarningMessages,
            [name]: warningMessage || '', // Set to empty string if no warning message
        }));

        setUser({
            ...user,
            [name]: value,
        });

    };

    // const checkAvailability = async (fieldName, value) => {        
    //         if (fieldName === 'userName') {
    //             const isAvailable = await checkFieldAvailability(fieldName, value);
    //             if (isAvailable !== true) {
    //                 return `${fieldName} is already taken.`;
    //             }
    //         }
    //         return ''; // No error
    // };
    
    // const checkFieldAvailability = async (fieldName, value) => {
        
    //     const listUsersApiUrl = `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/api/listUsers`;

    //     try {
    //         // console.log("Headers:", headers);
    //         const response = await axios.get(listUsersApiUrl, {
    //             // headers: headers,
    //             params: { [fieldName]: value }
    //         });
    
    //         // Assuming the API returns an array of users, check if any user has the specified value for the given field
    //         const isUnique = response.data.every(user => user[fieldName] !== value);
    //         return isUnique;
    //     } catch (error) {
    //         console.error(`${fieldName} availability check error: `, error);
    //         setIsLoading(false);
    
    //         // Return true to avoid blocking signup if there's an error
    //         // return true;
    //         throw new Error(`${fieldName} availability check error, try again later`);
    //     }
    // };

    const signup = async () => {
        setIsLoading(true);

        // const { firstname, userName, passWord, reenterPassword } = user;

        if (!agreeChecked) {
            setErrorMessages('You must agree to the terms and conditions.');
            setIsLoading(false);
            return;
        } else {
            setErrorMessages('');
        }

        // Check userName availability
        // const userNameError = await checkAvailability('userName', user.userName);
        // if (userNameError) {
        //     setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         userName: userNameError,
        //     }));
        //     setIsLoading(false);
        //     return;
        // }

        try {
            const res = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/signup`, user, 
                {withCredentials: true}
            );

            const { message } = res.data;
            // console.log('Faheem: ', res);
            setSignupMessage(message);
            if (message) {
                setTimeout(() => {
                    setSignupMessage('');
                }, 5000); // 5000 milliseconds = 5 seconds
            }
            setIsLoading(false);
            navigate("/dashboard");


            // if (message === "Successfully Registered, Please sign in now.") {
            //     const emailData = {
            //         to: userName,
            //         subject: "Welcome to TurfDev",
            //         html: `<!DOCTYPE html>
            //             <html>
            //             <head></head>
            //             <body>
            //                 <p>Hello ${firstname} ${lastname},</p>
            //                 <p>Welcome to TurfDev! Thank you for signing up. We are excited to have you as a member of our community.</p>
            //                 <p>Here are the details you provided during registration:</p>
            //                 <ul>
            //                     <li>First Name: ${firstname}</li>
            //                     <li>Last Name: ${lastname}</li>
            //                     <li>Email: ${userName}</li>
            //                 </ul>
            //                 <p>If you have any questions or need assistance, please don't hesitate to contact our support team.</p>
            //                 <p>Best regards, The TurfDev Team</p>
            //             </body>
            //             </html>`
            //     };
            //     sendWelcomeEmail(emailData);
            // }
            // navigate('/dashboard');
        } catch (error) {
            if (error) {
                if (error.response.data) {
                    console.log(error.response.data.message)
                    const errorMessage = error.response.data.message;
                    
                    // const [errorMessage, setErrorMessage] = useState('')

                    if (errorMessage.includes("User already exists")) {
                        // setErrorMessages((prevErrors) => ({ ...prevErrors, Email: errorMessage }));
                        setErrorMessages(errorMessage);
                        if (errorMessage) {
                            setTimeout(() => {
                                setErrorMessages('');
                            }, 5000); // 5000 milliseconds = 5 seconds
                        };
                    }
                } else {
                    // console.error("Signup error: ", error);
                    setErrorMessages("An error occurred during sign up.");
                    setTimeout(() => {
                        setErrorMessages('');
                        // console.log("An error occurred during signup.", error)
                    }, 5000); // 5000 milliseconds = 5 seconds
                }
            }
            setIsLoading(false);
        }
    };

    // const sendWelcomeEmail = (emailData) => {
    //     axios.post("https://apicloud.turfdev.in/api/sendsignupemail", emailData)
    //         .then((emailRes) => {
    //             console.log("Welcome email sent:", emailRes.data);
    //         })
    //         .catch((emailError) => {
    //             console.error("Error sending welcome email:", emailError);
    //         });
    // };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            signup();
        }
    };

    useEffect(() => {
        const hasWarningMessages = Object.values(warningMessages).some((error) => error !== '');
        // const requiredFields = Object.keys(user).filter((field) => field !== 'lastname');
        // const allFieldsFilled = requiredFields.every((field) => user[field].trim() !== '');
        const allFieldsFilled = Object.values(user).every((field) => field.trim() !== '');
        setIsSignupButtonDisabled(!allFieldsFilled || hasWarningMessages);
    }, [user, warningMessages]);

    const googleAuth = () => {
        window.open(
            `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/google/callback`,
            "_self"
        );
        // console.log(process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API)
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <div className="flex justify-center lg:flex-row flex-col min-h-screen">
                <LeftDiv />

                <div className="flex justify-center items-center flex-col lg:w-[50%] bg-white text-black p-5">
                    <div className="lg:absolute lg:top-10 flex justify-center lg:pt-0 pt-20">
						<Link to='/' className="w-[50%] lg:w-[25%] flex justify-center">
							<img className='rounded' alt='' src={companyLogo} />
						</Link>
					</div>

                    <div className="mt-20 w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex justify-center items-center flex-col gap-2">

                        {/* {console.log("User", user)} */}

                        {/* <div className="w-full flex flex-col md:flex-row justify-center items-start gap-2"> */}
                            {/* <div className="w-full md:flex-1"> */}
                                <input className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    value={user.fullName}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Fullname"
                                    type="text"
                                    name="fullName"
                                    required
                                />
                                {warningMessages.fullName && (
                                    <div className="w-full flex justify-start -mt-2">
                                        <p className="text-red-700 text-xs">{warningMessages.fullName}</p>
                                    </div>
                                )}
                                {/* {errorMessages.fullName && (<div className="w-full flex justify-start"><p className="text-red-700 text-xs">{errorMessages.fullName}</p></div>)} */}
                            {/* </div> */}
                            {/* <div className="w-full md:flex-1">
                                <input className="w-full placeholder:italic placeholder:opacity-50 placeholder:text-color3 bg-transparent  border border-color3 rounded-md py-2 px-3 focus:outline-none focus:border-color3 focus:ring-color3 focus:ring-1 sm:text-sm"
                                    value={user.lastname}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Lastname"
                                    type="text"
                                    name="lastname"
                                    required
                                />
                                {warningMessages.lastname && (
                                    <div className="w-full flex justify-start">
                                        <p className="text-red-700 text-xs">{warningMessages.lastname}</p>
                                    </div>
                                )}
                                {errorMessages.lastname && (<div className="w-full flex justify-start"><p className="text-red-700 text-xs">{errorMessages.lastname}</p></div>)}
                            </div> */}
                        {/* </div> */}

                        <input className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                            value={user.userName}
                            onChange={handleChange}
                            // onBlur={() => checkAvailability('userName', user.userName)}
                            onKeyDown={handleKeyDown}
                            placeholder="Email"
                            type="email"
                            name="userName"
                            required
                        />
                        {warningMessages.userName && (
                            <div className="w-full flex justify-start -mt-2">
                                <p className="text-red-700 text-xs">{warningMessages.userName}</p>
                            </div>
                        )}
                        {/* {errorMessages.userName && (<div className="w-full flex justify-start -mt-2"><p className="text-red-700 text-xs">{errorMessages.userName}</p></div>)} */}

                        <input className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                            value={user.passWord}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Password"
                            type="password"
                            name="passWord"
                            required
                        />
                        {warningMessages.passWord && (
                            <div className="w-full flex justify-start -mt-2">
                                <p className="text-red-700 text-xs">{warningMessages.passWord}</p>
                            </div>
                        )}
                        {/* {errorMessages.passWord && (<div className="w-full flex justify-start -mt-2"><p className="text-red-700 text-xs">{errorMessages.passWord}</p></div>)} */}

                        <input className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                            value={user.reenterPassword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Re-enter Password"
                            type="password"
                            name="reenterPassword"
                            required
                        />
                        {warningMessages.reenterPassword && (
                            <div className="w-full flex justify-start -mt-2">
                                <p className="text-red-700 text-xs">{warningMessages.reenterPassword}</p>
                            </div>
                        )}
                        {/* {errorMessages.reenterPassword && (<div className="w-full flex justify-start -mt-2"><p className="text-red-700 text-xs">{errorMessages.reenterPassword}</p></div>)} */}

                        <div className="flex gap-2">
                            <input type="checkbox" id="agree" name="agree" checked={agreeChecked} onChange={handleCheckboxChange} required />
                            <label htmlFor="agree" className="text-xs text-black">I agree to the Poorva Cloud's <span className="underline underline-offset-1"><Link to="#" className="text-custom-green">terms & conditions & privacy policy</Link></span></label>
                        </div>
                        {errorMessages.includes('You must agree to the terms and conditions.') && (
                            <div className="w-full flex justify-center -mt-2">
                                <p className="text-red-700 text-xs">{errorMessages}</p>
                            </div>
                        )}

                        <button 
                            onClick={signup}
                            disabled={isSignupButtonDisabled}
                            type="submit"
                            className={`${isSignupButtonDisabled ? 'cursor-not-allowed opacity-50' : ''} elastic-button1 w-full`}
                            ref={buttonRef}
                        >
                            {isLoading ? <div className="flex items-center gap-2"> <div className='spinner2'></div> Signing up...</div> : "Sign Up"}
                        </button>

                        {/* <Link to="/signup" className="flex justify-center items-center gap-2 w-full border border-color3 hover:text-color1 hover:bg-color3 px-8 py-2 rounded-full text-color3 transition duration-150 ease-out">
                            <i className="fa-solid fa-arrow-left-long"></i>Back
                        </Link> */}

                        <div className="flex justify-between">
                            <div className="">
                                <p className="text-sm text-black">Already Have An Account? <Link to='/signin' className="text-custom-green">Sign in</Link> here</p>
                            </div>
                        </div>

                        <div>
                            <p className="text-sm text-green-600"> {message} </p>
                        </div>

                        {!errorMessages.includes('You must agree to the terms and conditions.') && (
                            <div>
                                <p className="text-sm text-red-700">{errorMessages}</p>
                            </div>
                        )}
                        
                    </div>

                    <div className="mb-5 w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex flex-row gap-4 justify-center items-center">
						<div className="flex-1 border-t border-black"></div>
						<div className="flex-1 flex justify-center items-center">OR</div>
						<div className="flex-1 border-t border-black"></div>
					</div>

                    <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%]">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_CLIENT_ID}
                            // size="large"
                            width={buttonWidth}
                            text="signup_with"
                            theme="filled_blue"
                            // shape="pill"
                            onSuccess={(googleAuth)}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </div>
                </div>
            </div>

            <Copyright />
        </GoogleOAuthProvider>
    );
};

export default SignupWithEmail;
