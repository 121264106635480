import React from "react";
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
// import Scripts from "./Scripts";

import iconServer from '../../../images/icon-server.png';
import iconClock from '../../../images/icon-clock.png';
import iconServices from '../../../images/icon-services.png';
import iconExpert from '../../../images/icon-expert.png';
import iconSecurity from '../../../images/icon-security.png';
import iconScalable from '../../../images/icon-scalable.png';
import iconBackup from '../../../images/icon-backup.png';
import iconSupport from '../../../images/icon-support.png';


function Features(userData) {
    const user = userData.user;

    return (
        <>
            <Header userData={user}/>

            <div className="flex flex-col items-center bg-color1 text-color3 px-5 py-20 md:p-20 md:text-left text-center">
                <h1 className="md:text-[80px] text-[50px] font-bold text-center leading-tight tracking-tighter mb-5">Elevate Your Hosting Experience with Turfdev</h1>
                <p>Discover the Features That Set Us Apart in Managed Linux Web Hosting.</p>
                <div className="w-[200px] h-12 cursor-pointer mt-10">
                    <Link to="/plans" className="flex justify-center items-center border-2 border-color3 h-full w-full text-color1 bg-color3
                        hover:bg-transparent hover:text-color3 transition duration-150 ease-out rounded-full">Explore Our Plans
                    </Link>
                </div>
            </div>

            <div className="p-5 md:px-20 md:pt-20 bg-color4">
                <div className="text-center md:px-40 md:py-20 p-10 shadow-custom3 rounded-3xl bg-color3 text-color1">
                    <h1 className="text-3xl font-bold pb-5">Explore Our Hosting Features</h1>
                    <p>At Turfdev, we pride ourselves on delivering the best-in-class managed Linux web hosting solutions. Our feature-rich hosting plans are designed to cater to your specific needs, providing the foundation for a seamless online presence. Discover the key features that set Turfdev apart from the rest:</p>
                </div>
            </div>

            <div className="p-5 md:p-20 bg-color4 text-color1">
                <h1 className="text-3xl text-center">Key Hosting Features</h1>

                <div className="mt-5 md:mt-10 flex flex-col justify-center items-center gap-5 p-5">
                    <div className="w-full flex-wrap flex flex-col md:flex-row justify-center items-center gap-5">
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconServer} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">High-Performance Servers</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Experience lightning-fast page loading and superior website performance with our high-powered servers.</li>
                                    <li>SSD-based storage for faster data retrieval.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconClock} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Reliable Uptime</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Count on us for a 99.9% uptime guarantee, ensuring your website is always accessible to your audience.</li>
                                    <li>Redundant infrastructure for added reliability.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconServices} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Managed Services</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>We handle server management tasks, so you can focus on your business.</li>
                                    <li>Regular security updates, software maintenance, and 24/7 monitoring.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex-wrap flex flex-col md:flex-row justify-center items-start gap-5">
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconExpert} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Linux Expertise</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>We specialize in Linux hosting, with a team of experts well-versed in Linux distributions.</li>
                                    <li>Choice of CentOS, Ubuntu, Debian, and more.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconSecurity} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Security Measures</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Multiple layers of security to protect your data and website from threats.</li>
                                    <li>SSL certificates included for enhanced security.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconScalable} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Scalability</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Easily upgrade or downgrade your hosting plan as your website grows or your needs change.</li>
                                    <li>Customizable solutions for your unique requirements.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex-wrap flex flex-col md:flex-row justify-center items-center gap-5">
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconSupport} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">24/7 Customer Support</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Our dedicated support team is available around the clock to assist you.</li>
                                    <li>Rapid response to inquiries and issues.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:h-[380px] flex lg:flex-1 flex-col justify-start items-center gap-5 p-10 rounded-lg shadow-custom3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconBackup} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-xl font-bold">Daily Backups</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Automated daily backups with data retention for added peace of mind.</li>
                                    <li>Restore your website to a previous state if needed.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="hidden lg:h-[380px] lg:flex lg:flex-1 flex-col justify-start items-center gap-10 p-10 rounded-lg">
                            {/* <div className="flex flex-col justify-center items-center">
                                <div className="w-[90px] h-[90px] p-4 bg-color1 rounded-full">
                                    <img className="" src={iconScalable} alt="" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-md font-bold">Scalability</h1>
                                <ul className="mt-5 list-disc leading-6 text-left">
                                    <li>Easily upgrade or downgrade your hosting plan as your website grows or your needs change.</li>
                                    <li>Customizable solutions for your unique requirements.</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-col items-center px-10 py-20 md:p-20 bg-color3 text-color1">
                <h1 className="text-3xl">Why Choose Turfdev</h1>
                <ul className="mt-10 list-disc leading-6 text-left">
                    <li><strong>Experience: </strong>With years of experience in the hosting industry, we understand the nuances of managing Linux servers.</li>
                    <li><strong>Affordability: </strong>We offer competitive pricing without compromising on quality.</li>
                    <li><strong>Transparency: </strong>No hidden fees, no surprises. Our pricing is clear and straightforward.</li>
                    <li><strong>Customer-Centric Approach: </strong>Your success is our priority. We tailor our services to meet your specific needs.</li>
                    <li><strong>Cutting-Edge Technology: </strong>e keep up with the latest advancements in server hosting technology.</li>
                </ul>
            </div>

            <div className="px-5 py-20 md:py-20 md:px-60 bg-color2 text-color4 text-center flex flex-col items-center">
                <h1 className="text-3xl mb-5">Get Started Today</h1>
                <p>Ready to experience the power of managed Linux web hosting with Turfdev? Contact us today to discuss your hosting needs and let us help you take your online presence to the next level.</p>
                <Link to="/plans" className="mt-5 w-[60%] md:w-[35%] lg:w-[25%] flex justify-center items-center py-2 px-5 border-2 border-color4 bg-transparent text-color4 rounded-full hover:bg-color4 hover:text-color2">Get Started</Link>
            </div>

            <Footer />
        </>
    )
}

export default Features;