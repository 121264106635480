function ComponentHeader(serverData) {
    const plan = serverData.plan;
    const serverName = serverData.serverName;
    const serverStatus = serverData.serverStatus;
    const application = serverData.application;

    return (
        <>
            <div className="flex justify-between items-center gap-4 sm:gap-0 flex-col sm:flex-row lg:pr-20 lg:pl-5 lg:py-5 p-5 mx-5 mt-5 text-white text-center bg-custom-green rounded-t rounded-b-none shadow-custom3">
                <div className="flex flex-col">
                    <div className="flex items-center gap-1">
                        <h1 className="uppercase font-bold">{serverName}</h1>
                        <p className="font-light text-xs">(PC {plan})</p>
                    </div>
                    
                    <div className="flex items-center gap-2">
                        <a
                            href={`http://${application.applicationName}`} 
                            className="font-bold flex justify-center items-center gap-2" 
                            target="_blank"
                            rel="noopener noreferrer icon"
                        >
                            {application.applicationName}<i className="fa-solid fa-up-right-from-square"></i>
                        </a>
                        <p className={`${application.status === "running" ? "text-custom-green" : "text-red-700"} capitalize font-bold text-xs p-1 bg-white`}>{application.status}</p>
                    </div>
                </div>
                
                <div className="text-left sm:text-right">
                    <h1 className="uppercase">Server Status:</h1>
                    {serverStatus !== "running" &&
                        <h1 className="p-1 bg-white font-bold uppercase text-red-700">{serverStatus}</h1>
                    }
                    { serverStatus === "running" &&
                        <h1 className="p-1 bg-white font-bold capitalize text-green-700">Up & Running</h1>
                    }
                </div>
            </div>

        </>
    )
}

export default ComponentHeader;