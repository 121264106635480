import seamlessMigrationImg from '../../../../../images/seamless-migration.png'

function SeamlessMigration() {
    
    return (
        <div className='px-5 lg:px-20 lg:py-20 py-10 flex flex-col-reverse lg:flex-row gap-12'>
            <div className='lg:w-[55%] lg:px-10 flex justify-center items-center flex-col gap-8 text-center'>
                <h2 className='lg:px-8 py-2 bg-black text-white text-2xl'>Seamless Migration, Zero Hassle, Zero Cost: Elevate Your Website with Poorva</h2>
                <h2 className='text-xl'><span className='text-custom-green'>Experience Effortless Website Migration,</span> Absolutely Free of Charge</h2>
                <p>At Poorva, we prioritize your convenience and satisfaction. Our seamless  migration service ensures a smooth transition to our platform,  eliminating any hassle or downtime for your website. What's more, we  offer this invaluable service completely free of charge. Trust Poorva to  unlock your website's full potential without breaking the bank.</p>
            </div>

            <div className='lg:w-[45%]'>
                <img src={seamlessMigrationImg} alt="" />
            </div>
        </div>
    )
    
}

export default SeamlessMigration;
