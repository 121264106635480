import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LeftDiv from "../components/LeftDiv";
import companyLogo from '../../../images/poorva-cloud-logo-transparent.png';
import Copyright from "../../private-routes/components/Copyright";

const ForgotPassword = () => {
    useEffect(() => {
        document.title = "Reset your Poorva Cloud account password with simple steps";
    }, []);

    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [sendOtp, setSendOtp] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    // const [reenterPassword, setReenterPassword] = useState("");
    const [timer, setTimer] = useState(30);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
    const [isOtpButtonDisabled, setIsOtpButtonDisabled] = useState(true);
    const [passwordField, setPasswordField] = useState(false);
    // const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const isValidEmailFormat = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length !== 0 && emailRegex.test(email)) {
            return true;
        } else {
            return false;
        }
    };

    const handleEmailChange = (event) => {
        setError('');
        setMessage('');
        setEmail(event.target.value);
        // Validate email format here and update isButtonDisabled accordingly
        setIsButtonDisabled(!isValidEmailFormat(event.target.value));
    };

    const handleSendOTP = () => {
        setError('');
        setMessage('');

        fetch(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/send-otp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userName: email }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                
                if (data.message === 'OTP sent to your email!') {
                    setMessage(data.message);
                    setSendOtp(true);
                    setTimer(60);
                    setIsResendButtonDisabled(true);
                    const intervalId = setInterval(() => {
                        setTimer((prevTimer) => {
                            if (prevTimer === 1) {
                                clearInterval(intervalId);
                                setIsResendButtonDisabled(false);
                            }
                            return prevTimer - 1;
                        });
                    }, 1000);
                } else {
                    setError(data.message);
                    setSendOtp(false);
                }
            })
            .catch((error) => {
                console.error("Error sending OTP:", error);
                setError("Error sending OTP, try again later");
            });
        
    };

    const isValidOtp = (otp) => {
        return otp.length === 6;
    };

    const handleOtpChange = (event) => {
        const enteredOtp = event.target.value;
        setOtp(enteredOtp);
        if (enteredOtp) {
            setMessage('');
            setError('');
        }

        const isOtpValid = isValidOtp(enteredOtp);
    
        // Update the state based on the validity of the OTP
        setIsOtpButtonDisabled(!isOtpValid);
    };

    const handleOtpVerification = () => {
        setError('');
        setMessage('');

        fetch(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/verify-otp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                otp,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // console.log(data.message);
                if (data.message === 'OTP verified successfully!') {
                    setMessage(data.message);
                    setPasswordField(true);
                } else {
                    setError(data.message);
                    setPasswordField(false);
                }
            })
            .catch((error) => {
                console.error("Error verifying the OTP:", error);
                setError("Error verifying the OTP");
            });
    };

    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?]).{8,}$/;
        return regex.test(password);
    };

    const handlePasswordStrength = (event) => {
        const password = event.target.value;
        setNewPassword(password);
        // setError('');
        // setMessage('');
    };

    const handleResetPassword = () => {
        setError('');
        setMessage('');
        
        if (newPassword.length !== 0) {
            if (!isStrongPassword(newPassword)) {
                setError('Password must contain at least 1 uppercase, 1 lowercase and 1 special character');
                return;
            }
        } else {
            setError('Password length should be more than or equal to 8 chracters');
            return;
        };

        fetch(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                newPassword,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.message === 'Password updated successfully!') {
                    setMessage(data.message);
                    setIsResetPassword(true);
                    // setIsPasswordButtonDisabled(true);
                } else {
                    setError(data.message);
                    // setIsResetPassword(false);
                }
            })
            .catch((error) => {
                console.error("Error resetting password:", error);
                setError('Error resetting password, try again later.');
            });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleKeyDownSendOtp = (event) => {
		if (event.key === 'Enter') {
			handleSendOTP();
		}
	};

    const handleKeyDownVerifyOtp = (event) => {
		if (event.key === 'Enter') {
			handleOtpVerification();
		}
	};

    const handleKeyDownResetPassword = (event) => {
		if (event.key === 'Enter') {
			handleResetPassword();
		}
	};

    return (
        <>
            <div className="flex justify-center lg:flex-row flex-col min-h-screen">
                <LeftDiv />

                <div className="flex justify-center items-center flex-col lg:w-[50%] bg-white text-black p-5 gap-4">
                    <div className="lg:absolute lg:top-10 flex justify-center lg:pt-0 pt-20">
						<Link to='/' className="w-[50%] lg:w-[25%] flex justify-center">
							<img className='rounded' alt='' src={companyLogo} />
						</Link>
					</div>
                    <h1 className="text-xl font-bold">Forgot your Password?</h1>
                    <p className="-mt-4 text-sm text-center">
                        Enter your registered email address and reset it using OTP.
                    </p>
                    <div className="w-[100%] sm:w-[80%] md:w-[70%] lg:w-[50%] flex justify-center items-center flex-col gap-2">
                        <input
                            className={`${passwordField || sendOtp ? 'cursor-not-allowed opacity-50' : ''} w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 ${sendOtp ? 'border-green-600' : 'border-custom-green'} rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm`}
                            placeholder="Email"
                            type="text"
                            name="Email"
                            value={email}
                            onChange={handleEmailChange}
                            onKeyDown={handleKeyDownSendOtp}
                            disabled={passwordField || sendOtp }
                        />

                        {!sendOtp && (
                            <button                            
                                className={`${isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''} w-full elastic-button1`}
                                type="button"
                                onClick={handleSendOTP}
                                disabled={isButtonDisabled}
                            >
                                Send OTP
                            </button>                        
                        )}

                        {sendOtp && (
                            <>
                                <input
                                    // className={`${passwordField ? 'cursor-not-allowed opacity-50' : ''} w-[100%] placeholder:italic placeholder:opacity-50 placeholder:text-color3 bg-transparent  border ${passwordField ? 'border-green-600' : 'border-color3'} rounded-md py-2 px-3 focus:outline-none focus:border-color3 focus:ring-color3  focus:ring-1 sm:text-sm`}
                                    className={`${passwordField ? 'cursor-not-allowed opacity-50' : ''} w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 ${passwordField ? 'border-green-600' : 'border-custom-green'} rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm`}
                                    placeholder="OTP"
                                    type="text"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    onKeyDown={handleKeyDownVerifyOtp}
                                    disabled={passwordField}
                                />
                                {!passwordField && (
                                    <>
                                        <div className='flex flex-row gap-1'>
                                            <button
                                                className={`${isResendButtonDisabled ? 'cursor-not-allowed opacity-50' : 'text-blue-400 cursor-pointer hover:text-black'}`}
                                                onClick={handleSendOTP}
                                                disabled={isResendButtonDisabled}
                                                type="button"
                                            >
                                                Resend OTP
                                            </button>
                                            <p>after {`${timer}`} seconds.</p>
                                        </div>
                                        <button
                                            className={`${isOtpButtonDisabled ? 'cursor-not-allowed opacity-50' : ''} elastic-button1 w-full`}
                                            type="button"
                                            onClick={handleOtpVerification}
                                            disabled={isOtpButtonDisabled}
                                        >
                                            Verify OTP
                                        </button>
                                    </>
                                )}
                            </>
                        )}

                        {passwordField && (
                            <>
                                {!isResetPassword && (
                                    <div className="w-[100%]">
                                        <p>Enter new password</p>
                                    </div>
                                )}
                                <div className="w-full flex flex-row items-center">
                                    <input
                                        // className="w-[100%] placeholder:italic placeholder:opacity-50 placeholder:text-color3 bg-transparent border border-color3 rounded-md py-2 px-3 focus:outline-none focus:border-color3 focus:ring-color3  focus:ring-1 sm:text-sm"
                                        className={`${isResetPassword ? 'cursor-not-allowed opacity-50 border-green-600' : 'border-color3'} w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm`}
                                        placeholder="New Password"
                                        type={passwordVisible ? "text" : "password"}
                                        name="newpassword"
                                        value={newPassword}
                                        onChange={handlePasswordStrength}
                                        onKeyDown={handleKeyDownResetPassword}
                                        disabled={isResetPassword}
                                    />
                                    <i onClick={togglePasswordVisibility} className={`-ml-8 z-50 cursor-pointer fa-solid ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                {!isResetPassword && (
                                    <button
                                        // className={`${isPasswordButtonDisabled ? 'cursor-not-allowed opacity-50' : ''} flex justify-center items-center w-full border border-color3 bg-color3 hover:bg-transparent hover:text-color3 px-8 py-2 rounded-full mt-4 text-color1 transition duration-150 ease-out`}
                                        className='elastic-button1 w-full'
                                        type="button"
                                        onClick={handleResetPassword}
                                        // disabled={isPasswordButtonDisabled}
                                    >
                                        Reset Password
                                    </button>
                                )}

                                {isResetPassword && (
                                    <Link to='/signin'
                                        className='elastic-button1 w-full'
                                    >
                                        Sign in now
                                    </Link>
                                )}
                            </>
                        )}
                        
                        {!isResetPassword && (
                            <Link
                                to="/signin"
                                className="elastic-button2 w-full gap-2"
                            >
                                <i className="fa-solid fa-arrow-left-long"></i>Back to Sign in
                            </Link>
                        )}
                        
                        {message && (
                            <div className="">
                                <p className="text-sm text-green-500 text-center"> {message} </p>
                            </div>
                        )}

                        {error && (
                            <div className="">
                                <p className="text-sm text-red-500 text-center">{error}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Copyright />
        </>
    );
};

export default ForgotPassword;
