import { Link } from 'react-router-dom';

import heroBg from '../../../../../images/homepage-hero.png';

function Hero() {
    
    return (
        <div className='px-5 lg:px-20'>
            <div className="py-10 lg:py-44 bg-contain bg-no-repeat bg-right flex flex-col items-center lg:items-start justify-center" style={{ backgroundImage: `url(${heroBg})` }}>
                <h1 className='leading-12 text-4xl lg:text-[42px] text-custom-green lg:text-left text-center' style={{ textShadow: '2px 3px 5px rgba(0,0,0,0.30)' }}>Elevate Your Digital Realm: </h1>
                <h1 className='leading-12 text-4xl lg:text-[42px] lg:text-left text-center' style={{ textShadow: '2px 3px 5px rgba(0,0,0,0.30)' }}>Streamlined Managed Cloud Hosting with <br/>Microservices, AI Powered..</h1>
                <div className='mt-10 flex justify-start items-center gap-4'>
                    <Link to="/plans" className="button2">View Plans</Link>
                    <Link to="/plans" className="button1">Start</Link>
                </div>
            </div>
        </div>
    )
}

export default Hero;
