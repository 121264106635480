import React, {useEffect, useState} from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import axios from 'axios';

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

function Dashboard(userData) {
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Dashboard - Manage your cloud servers and apps";
    }, []);
    // console.log("Dash", user);
    
    // const navigate = useNavigate();
    // useEffect(() => {
	// 	if (!user) {
	// 		navigate("/signin"); // Redirect to the dashboard if the user is already signed in
	// 	}
	// });

    const selectedItem0 = 'home';

    const settingsForCarousel = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false
    };

    const [serversLength, setServersLength] = useState([]);

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/getServerList`,
                    { poorvaID: user.poorvaID },
                    { withCredentials: true }
                );
                setServersLength(Object.keys(response.data.collectionsWithInstanceID).length);
            } catch (error) {
                // console.error("Error fetching servers:", error);
            }
        };

        // Run the function immediately when the component mounts
        fetchServers();
        
        const intervalId = setInterval(() => {    
            fetchServers();
        }, 10000); // Run every 10 seconds
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    
    }, [user.poorvaID]);
    
    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden md:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} />
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className="bg-white flex flex-col gap-2 m-5 md:shadow-custom3 rounded py-10 lg:pl-5 lg:pr-20 items-start">
                        
                        <div className="w-full text-left flex flex-row justify-between items-center mt-0 md:mt-10 mb-10">
                            <h1 className="uppercase text-black text-xl font-bold text-left">Dashboard</h1>
                            <Link to="/createserver" className="button1">+ Add Server</Link>
                        </div>

                        <div className="w-full">
                            <div className="w-full hidden sm:flex gap-6">
                                <div className="flex-1 flex justify-start">
                                    <Link to="/dashboard/products" className="h-36 w-36 lg:h-44 lg:w-44 p-2 flex flex-col justify-center items-center border border-gray-300 rounded-md shadow cursor-pointer hover:shadow-custom3">
                                        <h1 className="text-[50px]  font-bold">{serversLength}</h1>
                                        <h2 className="font-light text-md text-center">Active Services</h2>
                                    </Link>
                                </div>
                                <div className="flex-1 flex justify-center">
                                    <Link to="/dashboard/invoices" className="h-36 w-36 lg:h-44 lg:w-44 p-2 flex flex-col justify-center items-center border border-gray-300 rounded-md shadow cursor-pointer hover:shadow-custom3">
                                        <h1 className="text-[50px]  font-bold">1</h1>
                                        <h2 className="font-light text-md text-center">Unpaid Invoices</h2>
                                    </Link>
                                </div>
                                <div className="flex-1 flex justify-end">
                                    <Link to="/dashboard/mytickets" className="h-36 w-36 lg:h-44 lg:w-44 p-2 flex flex-col justify-center items-center border border-gray-300 rounded-md shadow cursor-pointer hover:shadow-custom3">
                                        <h1 className="text-[50px]  font-bold">3</h1>
                                        <h2 className="font-light text-md text-center">Active Tickets</h2>
                                    </Link>
                                </div>
                                <div className="flex-1 flex justify-end">
                                    <Link to="" className="h-36 w-36 lg:h-44 lg:w-44 p-2 flex flex-col justify-center items-center border border-gray-300 rounded-md shadow cursor-pointer hover:shadow-custom3">
                                        <h1 className="text-[50px]  font-bold">3</h1>
                                        <h2 className="font-light text-md text-center">New Notifications</h2>
                                    </Link>
                                </div>
                            </div>

                            {/* FOR SMALL DEVICES */}
                            <div className="sm:hidden w-full flex justify-center items-center gap-6 mb-8 ">
                                <Slider {...settingsForCarousel} className="w-full">
                                    <div className="flex-1 flex justify-start">
                                        <Link to="/dashboard/products" className="mx-1 bg-gray-300 flex flex-col justify-center items-center rounded-md cursor-pointer">
                                            <h1 className="text-[50px] font-bold">{serversLength}</h1>
                                            <h2 className="font-light text-lg">Active Services</h2>
                                        </Link>
                                    </div>
                                    <div className="flex-1 flex justify-center">
                                        <Link to="/dashboard/invoices" className="mx-1 bg-gray-300 flex flex-col justify-center items-center rounded-md cursor-pointer">
                                            <h1 className="text-[50px] font-bold">1</h1>
                                            <h2 className="font-light text-lg">Unpaid Invoices</h2>
                                        </Link>
                                    </div>
                                    <div className="flex-1 flex justify-end">
                                        <Link to="/dashboard/mytickets" className="mx-1 bg-gray-300 flex flex-col justify-center items-center rounded-md cursor-pointer">
                                            <h1 className="text-[50px] font-bold">3</h1>
                                            <h2 className="font-light text-lg">Active Tickets</h2>
                                        </Link>
                                    </div>
                                    <div className="flex-1 flex justify-end">
                                        <Link to="" className="mx-1 bg-gray-300 flex flex-col justify-center items-center rounded-md cursor-pointer">
                                            <h1 className="text-[50px] font-bold">3</h1>
                                            <h2 className="font-light text-lg">New Notifications</h2>
                                        </Link>
                                    </div>
                                </Slider>
                            </div>

                            <div className="w-full flex sm:flex-row flex-col gap-6 sm:mt-8 mt-16">
                                <div className="flex flex-col flex-1 gap-6">
                                    <div className="flex flex-col flex-1 text-left">
                                        <h1 className="bg-gray-300 border border-gray-400 font-bold p-4">Your active Products/Services</h1>
                                        
                                        <Link to="/dashboard/serverdetails" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100 hover:">
                                            <div>
                                                <h1 className="font-bold uppercase">TD-S2-Small</h1>
                                                <p>server.tdshop.in</p>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <div className="bg-green-600 h-2 w-2 rounded-full"></div>
                                                <p className="font-bold">Active</p>
                                            </div>
                                        </Link>
                                        <Link to="/dashboard/serverdetails" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100 hover:">
                                            <div>
                                                <h1 className="font-bold uppercase">TD-S2-Large</h1>
                                                <p>server.turf.com</p>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <div className="bg-red-700 h-2 w-2 rounded-full"></div>
                                                <p className="font-bold">Stopped</p>
                                            </div>
                                        </Link>
                                        <Link to="/dashboard/serverdetails" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100 hover:">
                                            <div>
                                                <h1 className="font-bold uppercase">TD-S2-Xlarge</h1>
                                                <p>server.turfdev.in</p>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <div className="bg-green-600 h-2 w-2 rounded-full"></div>
                                                <p className="font-bold">Active</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="flex flex-col flex-1 text-left">
                                        <h1 className="bg-gray-300  font-bold border border-gray-400 p-4">Recent News</h1>
                                        <Link to="" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                            <div>
                                                <h1 className="font-bold capitalize">We are Migrating</h1>
                                                <p>Oct 03, 2023</p>
                                            </div>
                                        </Link>
                                        <Link to="" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                            <div>
                                                <h1 className="font-bold capitalize">Grab your coupon before it's too late</h1>
                                                <p>Sep 12, 2023</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className="flex flex-col flex-1 text-left">
                                    <h1 className="bg-gray-300  font-bold border border-gray-400 p-4">Your Recent Tickets</h1>
                                    <Link to="/dashboard/mytickets" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                        <div>
                                            <h1 className="font-bold uppercase">#TD97103572</h1>
                                            <p>Need help setting up WordPress</p>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-blue-700 h-2 w-2 rounded-full"></div>
                                            <p className="font-bold">Answered</p>
                                        </div>
                                    </Link>
                                    <Link to="/dashboard/mytickets" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                        <div>
                                            <h1 className="font-bold uppercase">#TD90120396</h1>
                                            <p>Raise an invoice for my TD-XLARGE server</p>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-green-600 h-2 w-2 rounded-full"></div>
                                            <p className="font-bold">Open</p>
                                        </div>
                                    </Link>
                                    <Link to="/dashboard/mytickets" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                        <div>
                                            <h1 className="font-bold uppercase">#TD06163902</h1>
                                            <p>Website throwing a 500 internal server error</p>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-black h-2 w-2 rounded-full"></div>
                                            <p className="font-bold">Closed</p>
                                        </div>
                                    </Link>
                                    <Link to="/dashboard/mytickets" className="flex justify-between border-b border-gray-200 p-4 hover:bg-gray-100">
                                        <div>
                                            <h1 className="font-bold uppercase">#TD02537529</h1>
                                            <p>Having problem creating a new domain</p>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-red-700 h-2 w-2 rounded-full"></div>
                                            <p className="font-bold">Customer-Reply</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <Copyright />
        </>
    )

}

export default Dashboard;