import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0";
import Copyright from "../components/Copyright";
import PageNotFound from "../../../images/404.png"

import InvoiceDetails from "./components/InvoiceDetails";

function Invoices(userData) {
    const user = userData.user;
    const compartment = "dashboard";

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invoiceID = queryParams.get('invoiceid');
    const detailsFlag = queryParams.has('details');
    // console.log("INV: ", invoiceID);

    useEffect(() => {
        document.title = "Manage Invoices";
    }, []);

    const selectedItem0 = "invoices";

    const navigate = useNavigate();

    // Sample invoice data
    const invoices = [
        { id: 709126, plan: "TD-s2-small", server: "server.tdshop.in", amount: 299, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid" },
        { id: 981022, plan: "TD-s2-large", server: "server.turf.com", amount: 499, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid" },
        { id: 354071, plan: "TD-s2-medium", server: "server.media.com", amount: 399, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid" },
        { id: 409854, plan: "TD-s2-extra-large", server: "server.giant.com", amount: 599, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid" },
        { id: 512394, plan: "TD-s2-micro", server: "server.micro.com", amount: 199, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid" },
        { id: 609365, plan: "TD-s2-nano", server: "server.nano.com", amount: 99, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid" },
        { id: 798102, plan: "TD-s2-pico", server: "server.pico.com", amount: 89, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid" },
    ];

    const currentInvoice = invoiceID ? invoices.find(invoice => invoice.id === parseInt(invoiceID)) : null;

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 5;

    // Filtered invoices based on search query
    const filteredInvoices = invoices.filter(invoice =>
        invoice.plan.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.server.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewDetails = (invoiceid) => {
        navigate(`/dashboard/invoices?details&invoiceid=${invoiceid}`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate the invoices to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedInvoices = filteredInvoices.slice(startIndex, startIndex + itemsPerPage);

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden md:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} />
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className={`${invoiceID ? "lg:pr-0" : "lg:pr-20"} md:w-auto w-[230%] bg-white flex flex-col gap-2 m-5 md:shadow-custom3 rounded py-10 lg:pl-5 items-start`}>

                        {invoiceID ? (
                            <>
                                {currentInvoice && detailsFlag ? (
                                    <InvoiceDetails userData={user} invoiceDetails={currentInvoice}/>
                                ) : (
                                    <div className="p-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-16 lg:gap-8 text-black">

                                        <div className="w-full lg:w-[60%] flex justify-center items-center"><img className="w-full md:w-[80%] lg:w-full" src={PageNotFound} alt="" /></div>

                                        <div className="w-full lg:w-[40%]">
                                            <h1 className="text-[90px] font-bold text-center leading-14 text-custom-green">404<br/>error</h1>
                                            <p className="text-sm text-center mt-10">It seems the page you were looking for has taken a detour or is on vacation. Don't worry, though – even the best explorers find themselves off the beaten path from time to time.</p>
                                            <Link className="mt-4 elastic-button1 px-6 py-1 cursor-pointer rounded" to="/dashboard/invoices"><i className="fa-solid fa-money-check-dollar"></i><span className="pl-4">Invoices</span></Link>
                                        </div>

                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="text-left w-full">
                                    <h1 className="uppercase mb-10 text-black text-xl font-bold text-left md:mt-10">Your Invoices</h1>
                                    <input
                                        type="text"
                                        placeholder="Search invoices..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                                    />
                                </div>
                                {selectedInvoices.length > 0 ? (
                                    selectedInvoices.map((invoice) => (
                                        <div key={invoice.id} className="w-full p-4 flex gap-2 items-center justify-between text-sm text-black border-b border-black border-opacity-20">
                                            <div className="w-full flex justify-between items-center gap-2 flex-1">
                                                <div className="flex-1 font-bold">
                                                    PCI-{invoice.id}
                                                </div>
                                                <div className="flex flex-1 flex-col items-start">
                                                    <h1 className="uppercase font-bold">{invoice.server}</h1>
                                                    <h2 className="uppercase font-light">{invoice.plan}</h2>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <h1 className="">Amount</h1>
                                                    <h2 className="uppercase font-bold">US$ {invoice.amount}</h2>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-between gap-2 flex-1">
                                                <div className="flex-1 flex flex-col items-center">
                                                    <h1 className="">Due Date</h1>
                                                    <h2 className="font-bold">{invoice.dueDate}</h2>
                                                </div>
                                                <div className="flex flex-1 justify-end gap-4 items-center">
                                                    <h1 className={`uppercase font-bold ${invoice.status === 'paid' ? 'text-green-700' : 'text-red-700'}`}>
                                                        {invoice.status}
                                                    </h1>
                                                    <button type="submit" onClick={() => handleViewDetails(invoice.id)} className="button-transparent border border-black">View</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-red-700 text-sm">No invoices found for your search.</p>
                                )}
                                <div className="flex justify-between mt-10 w-full">
                                    <button 
                                        onClick={handlePreviousPage} 
                                        disabled={currentPage === 1} 
                                        className={`${currentPage === 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <i className="fa-solid fa-arrow-left-long"></i>
                                        <p>Previous</p>
                                    </button>
                                    <div className="flex gap-2">
                                        {Array.from({ length: totalPages }, (_, index) => {
                                            const pageNumber = index + 1;
                                            return (
                                                <button
                                                    key={pageNumber}
                                                    onClick={() => handlePageClick(pageNumber)}
                                                    className={`${
                                                        currentPage === pageNumber ? 'text-custom-green font-bold' : 'text-black'
                                                    }`}
                                                >
                                                    {pageNumber}
                                                </button>
                                            );
                                        })}
                                    </div>

                                    <button 
                                        onClick={handleNextPage} 
                                        disabled={currentPage === totalPages} 
                                        className={`${currentPage === totalPages ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <p>Next</p>
                                        <i className="fa-solid fa-arrow-right-long"></i>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Copyright />
        </>
    );
}

export default Invoices;
