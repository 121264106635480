import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0";
import Copyright from "../components/Copyright";

function Products(userData) {
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Dashboard - Products";
    }, []);

    const selectedItem0 = 'products';

    const location = useLocation();
    const navigate = useNavigate();
    
    // console.log("USER: ", user);
    // if (!user) {
    //     navigate("/signin");
    // };
    
    const [serverConfig, setServerConfig] = useState(null);
    
    useEffect(() => {
        // Check if serverConfig exists in the location state
        if (location.state && location.state.serverConfig) {
            // Save it in session storage
            sessionStorage.setItem('serverConfig', JSON.stringify(location.state.serverConfig));
            setServerConfig(location.state.serverConfig);
            
            // Clear the state from location to avoid persistence
            navigate(location.pathname, { replace: true });
        } else {
            // Retrieve from session storage if page is reloaded
            const savedConfig = sessionStorage.getItem('serverConfig');
            if (savedConfig) {
                setServerConfig(JSON.parse(savedConfig));
                // Remove from session storage so it doesn't persist on reload
                sessionStorage.removeItem('serverConfig');
            }
        }
    }, [location, navigate]);

    const [openDialog, setOpenDialog] = useState(null);
    const [dialogAction, setDialogAction] = useState('');
    const [serverName, setServerName] = useState('');
    const [selectedServer, setSelectedServer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [servers, setServers] = useState([]);
    const [serverStatuses, setServerStatuses] = useState({});
    const [serverChecks, setServerChecks] = useState({});
    const [creatingServer, setCreatingServer] = useState(false);
    // const [refreshing, setRefreshing] = useState(false);
    // const [instanceStates, setInstanceStates] = useState({});
    // const [disableButton, setDisableButton] = useState(false);
    const [error, setError] = useState('');

    const createServer = async (config) => {
        setError('');

        try {
            // Make a POST request to the backend to save server configuration
            const response = await axios.post(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/createEC2Instance`,
                config,
                { withCredentials: true }
            );
            
            // console.log(response.data.message);
            // Optionally, you can handle the response here
        } catch (err) {
            setError("Error creating the server.");
            // console.error('Error saving server configuration:', err.message);
            // Optionally, you can handle the error here
        };
    };
    
    useEffect(() => {
        if (serverConfig) {
            createServer(serverConfig);
        };
    }, [serverConfig]);

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/getServerList`,
                    { poorvaID: user.poorvaID },
                    { withCredentials: true }
                );
                setServers(response.data.collectionsWithInstanceID);
            } catch (error) {
                // console.error("Error fetching servers:", error);
            }
        };

        // Run the function immediately when the component mounts
        fetchServers();
        
        const intervalId = setInterval(() => {    
            fetchServers();
        }, 10000); // Run every 10 seconds
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    
    }, [user.poorvaID]);

    useEffect(() => {
        const findServer = () => {
            if (servers) {
                // Flatten the arrays into one array of server objects
                const allServers = Object.values(servers).flat();

                // Check if the serverConfig.serverName is present in any of the servers
                if (serverConfig && !allServers.some(server => server.serverName === serverConfig.serverName)) {
                    setCreatingServer(true);
                } else {
                    setCreatingServer(false);
                };
            };
        };

        findServer();

        const intervalId = setInterval(() => {    
            findServer();
        }, 10000); // Run every 10 seconds
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [servers, serverConfig]);

    useEffect(() => {
        const getServerStatus = async () => {
            const statuses = {};
            const checks = {};
            for (const server of Object.values(servers)) {
                try {
                    // // Delay each iteration by 1 second
                    // await new Promise(resolve => setTimeout(resolve, 1000));
                    // console.log("LIST: ", server);
                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/getEC2InstanceDetails`,
                        {
                            serverName: server[0].serverName,
                            poorvaID: user.poorvaID
                        },
                        { withCredentials: true }
                    );
                    
                    statuses[server[0].serverName] = response.data.data.status;
                    
                    if (statuses[server[0].serverName] === "running" ) {
                        try {
                            // // Delay each iteration by 1 second
                            // await new Promise(resolve => setTimeout(resolve, 1000));
                            // console.log("LIST: ", server);
                            const response = await axios.post(
                                `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/describeInstanceStatus`,
                                {
                                    serverName: server[0].serverName,
                                    poorvaID: user.poorvaID
                                },
                                { withCredentials: true }
                            );
                            checks[server[0].serverName] = response.data.data.instanceStatus.status;
                        } catch (err) {
                            // console.error(`Error fetching status for server ${server[0].serverName}:`, err.response.data);
                            checks[server[0].serverName] = "error"; // Or set a default error status
                        };
                    };
                    
                } catch (err) {
                    // console.log("RESPONSE: ", err.response.data.error);
                    // console.error(`Error fetching status for server ${server[0].serverName}:`, err.response.data);
                    statuses[server[0].serverName] = "error"; // Or set a default error status
                };
            };
            
            setServerStatuses(statuses);
            setServerChecks(checks);
        };

        // Run the function immediately when the component mounts
        if (Object.keys(servers).length > 0) {
            getServerStatus();
        }

        const intervalId = setInterval(() => {
            if (Object.keys(servers).length > 0) {
                getServerStatus();
            }
        }, 10000); // Run every 10 seconds
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);

    }, [servers, user.poorvaID]);
    
    // console.log("STATUSES: ", serverStatuses);

    const instanceTypes = [
        {name: "Micro", value: 't3.micro', details: {Memory: '1 GB', Core: '2 Cores'}, price: {Hourly: 0.0209, Monthly: 15.62}},
        {name: "Small", value: 't3.small', details: {Memory: '2 GB', Core: '2 Cores'}, price: {Hourly: 0.0434, Monthly: 31.25}},
        {name: "Medium", value: 't3.medium', details: {Memory: '4 GB', Core: '2 Cores'}, price: {Hourly: 0.0868, Monthly: 62.50}},
        {name: "Large", value: 't3.large', details: {Memory: '8 GB', Core: '2 Cores'}, price: {Hourly: 0.1736, Monthly: 124.99}},
        {name: "CO XL", value: 'c3.xlarge', details: {Memory: '8 GB', Core: '4 Cores'}, price: {Hourly: 0.3746, Monthly: 269.70}},
        {name: "XL", value: 't4g.xlarge', details: {Memory: '16 GB', Core: '4 Cores'}, price: {Hourly: 0.3472, Monthly: 249.98}},
        {name: "CO 2XL", value: 'c6g.2xlarge', details: {Memory: '16 GB', Core: '8 Cores'}, price: {Hourly: 0.7027, Monthly: 505.92}},
        {name: "2XL", value: 't4g.2xlarge', details: {Memory: '32 GB', Core: '8 Cores'}, price: {Hourly: 0.6944, Monthly: 499.97}},
        {name: "CO 4XL", value: 'c7a.4xlarge', details: {Memory: '32 GB', Core: '16 Cores'}, price: {Hourly: 2.1212, Monthly: 1527.28}},
        {name: "4XL", value: 'm6g.4xlarge', details: {Memory: '64 GB', Core: '16 Cores'}, price: {Hourly: 1.5913, Monthly: 1145.76}},
        // {name: "CO 8XL", value: 'c7g.8xlarge', details: {Memory: '64 GB', Core: '32 Cores'}, price: {Hourly: 2.9967, Monthly: 2157.60}},
        {name: "CO 9XL", value: 'c5.9xlarge', details: {Memory: '72 GB', Core: '36 Cores'}, price: {Hourly: 3.9525, Monthly: 2845.80}},
        {name: "8XL", value: 'm6g.8xlarge', details: {Memory: '128 GB', Core: '32 Cores'}, price: {Hourly: 3.1827, Monthly: 2291.51}},
        {name: "CO 12XL", value: 'c7g.12xlarge', details: {Memory: '96 GB', Core: '48 Cores'}, price: {Hourly: 4.4950, Monthly: 3236.40}},
        {name: "12XL", value: 'm6g.12xlarge', details: {Memory: '192 GB', Core: '48 Cores'}, price: {Hourly: 4.7740, Monthly: 3437.28}},
        {name: "CO 18XL", value: 'c5.18xlarge', details: {Memory: '144 GB', Core: '72 Cores'}, price: {Hourly: 7.9050, Monthly: 5691.60}},
        {name: "16XL", value: 'm6g.metal', details: {Memory: '256 GB', Core: '64 Cores'}, price: {Hourly: 6.3653, Monthly: 4583.04}},
        {name: "CO 24XL", value: 'c6a.24xlarge', details: {Memory: '192 GB', Core: '96 Cores'}, price: {Hourly: 9.4860, Monthly: 6829.92}},
        {name: "24XL", value: 'm5a.24xlarge', details: {Memory: '384 GB', Core: '96 Cores'}, price: {Hourly: 10.6640, Monthly: 7678.08}},
    ];

    // useEffect(() => {
    //     const getServerCheck = async () => {
    //         const checks = {};
    //         for (const server of Object.values(servers)) {
    //             try {
    //                 // // Delay each iteration by 1 second
    //                 // await new Promise(resolve => setTimeout(resolve, 1000));
    //                 // console.log("LIST: ", server);
    //                 const response = await axios.post(
    //                     `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/describeInstanceStatus`,
    //                     {
    //                         serverName: server[0].serverName,
    //                         poorvaID: user.poorvaID
    //                     },
    //                     { withCredentials: true }
    //                 );
    //                 checks[server[0].serverName] = response.data.data.instanceStatus.status;
    //             } catch (err) {
    //                 // console.error(`Error fetching status for server ${server[0].serverName}:`, err);
    //                 checks[server[0].serverName] = "error"; // Or set a default error status
    //             }
    //         }
    //         setServerChecks(checks);
    //     };

    //     // Run the function immediately when the component mounts
    //     if (Object.keys(servers).length > 0) {
    //         getServerCheck();
    //     }

    //     const intervalId = setInterval(() => {
    //         if (Object.keys(servers).length > 0) {
    //             getServerCheck();
    //         }
    //     }, 10000); // Run every 10 seconds
    
    //     // Cleanup the interval on component unmount
    //     return () => clearInterval(intervalId);
        
    // }, [servers]);

    // console.log("CHECKS: ", serverChecks);


    const handleToggleDialog = (serverName, action) => {
        setOpenDialog((prevState) => (prevState === serverName ? null : serverName));
        setDialogAction(action);

        const allServers = Object.values(servers).flat();
        const selected = allServers.find(server => server.serverName === serverName);
        setSelectedServer(selected);
        // setSelectedServer(servers.find(server => server.serverName === serverName));
    };

    const handleViewDetails = (collectionName) => {
        navigate(`/dashboard/manageserver/${collectionName}?serverDetails`);
    }

    const [loadingAction, setLoadingAction] = useState({
        destroy: {},
        remove: {},
    });

    const handleDestroyServer = async () => {
        if (serverName === selectedServer?.serverName) {
            setOpenDialog(null);
            
            setLoadingAction(prev => ({
                ...prev,
                destroy: {
                    ...prev.destroy,
                    [selectedServer.serverName]: true,
                },
            }));

            try {
                // console.log("SERVER NAME:", serverName, "POORVA ID: ", user.poorvaID, "AWS REGION: ", selectedServer.awsRegion);

                await axios.delete(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/destroyAWSResources`, {
                    data: {
                        serverName: serverName,
                        poorvaID: user.poorvaID,
                        awsRegion: selectedServer.awsRegion,
                    },
                    withCredentials: true,
                });

                // setServers(prevServers => {
                //     const updatedServers = { ...prevServers };
                //     Object.keys(updatedServers).forEach(collectionName => {
                //         updatedServers[collectionName] = updatedServers[collectionName].filter(server => server.serverName !== selectedServer.serverName);
                //     });
                //     return updatedServers;
                // });

                // setOpenDialog(null);
            } catch (error) {
                // console.error("Error destroying server:", error);
            } finally {
                setLoadingAction(prev => ({
                    ...prev,
                    destroy: {
                        ...prev.destroy,
                        [selectedServer.serverName]: false,
                    },
                }));
            }
        } else {
            alert("Server name does not match.");
        }
    };

    const handleAction = async (action, serverName) => {
        setIsLoading(true);
        const apiMap = {
            start: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/startEC2Instance`,
            stop: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/stopEC2Instance`,
            restart: `${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/rebootEC2Instance`
        };

        const payload = {
            poorvaID: user.poorvaID,
            serverName: serverName
        };

        try {
            const response = await axios.post(apiMap[action],
                payload,
                {withCredentials: true}
            );
            alert(`Server ${action}ed successfully.`);
        } catch (error) {
            // console.error(`Error ${action}ing the server:`, error);
            alert(`Failed to ${action} the server.`);
        } finally {
            setIsLoading(false);
        }
    }

    const handleRemoveServer = async () => {
        if (serverName === selectedServer?.serverName) {
            try {
                setLoadingAction(prev => ({
                    ...prev,
                    remove: {
                        ...prev.remove,
                        [selectedServer.serverName]: true,
                    },
                }));

                await axios.post(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/removeServer`,
                    {
                        serverName: serverName,
                        userName: user.userName
                    },
                    { withCredentials: true }
                );

                setOpenDialog(null);
            } catch (error) {
                // console.error("Error removing server:", error);
            } finally {
                setLoadingAction(prev => ({
                    ...prev,
                    remove: {
                        ...prev.remove,
                        [selectedServer.serverName]: false,
                    },
                }));
            };
        } else {
            alert("Server name does not match.");
        };
    };

    // const handleRecreateServer = async () => {
    //     // setError('');
    //     setIsLoading(true);

    //     const serverConfig = {
    //         userName: user.username,
    //         userEmail: user.userName,
    //         poorvaID: user.poorvaID,
    //         serverName: selectedServer.serverName,
    //         instanceType: selectedServer.instanceType,
    //         serverSize: selectedServer.serverSize,
    //         volumeSize: selectedServer.volumeSize,
    //         linuxDistro: selectedServer.linuxDistro,
    //         linuxVersion: selectedServer.linuxVersion,
    //         awsRegion: selectedServer.awsRegion,
    //         availabilityZone: selectedServer.awsRegion + 'a',
    //         sshSftpUserValue: selectedServer.sshUser,
    //         sshSftpPasswordValue: selectedServer.sshPassword,
    //     };

    //     try {
    //         await axios.post(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/createEC2Instance`,
    //             serverConfig,
    //             { withCredentials: true }
    //         );
    //         setIsLoading(false);
    //     } catch (error) {
    //         setIsLoading(false);
    //         // setError("Error saving server configuration");
    //     }
    // };

    // useEffect(() => {
    //     if (serverConfig && !Object.values(servers).some((server) => server.serverName === serverConfig.serverName)) {
    //         setCreatingServer(true);
    //     } else {
    //         setCreatingServer(false);
    //     };
    // }, [servers]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 5;

    // Filtered servers based on search query
    const filteredServers = Object.keys(servers).filter(server =>
        server.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredServers.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate the servers to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedServers = filteredServers.slice(startIndex, startIndex + itemsPerPage);
    // console.log("SERVERS: ", servers);

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden md:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} />
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%]">
                    <div className="md:w-auto w-[200%] bg-white flex flex-col gap-2 m-5 md:shadow-custom3 rounded py-10 lg:pl-5 lg:pr-20 items-start">

                        <div className="w-full text-left flex flex-row justify-between items-center my-10">
                            <h1 className="uppercase text-black text-xl font-bold text-left">Your Servers</h1>
                            <Link to="/createserver" className="button1">+ Add Server</Link>
                        </div>

                        <div className="w-full flex items-center">
                            <input
                                type="text"
                                placeholder="Search servers..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg"
                                disabled={servers.length === 0}
                            />
                            {/* <i className="fa-solid fa-x -ml-8 opacity-50 hover:opacity-80 cursor-pointer" title="clear" onClick={() => setSearchQuery("")}></i> */}
                            <i className={`${searchQuery ? "hover:opacity-80 cursor-pointer" : ""} fa-solid fa-x -ml-8 opacity-50`} title={`${searchQuery ? "clear" : ""}`} disabled={!searchQuery} onClick={() => setSearchQuery("")}></i>
                        </div>

                        {/* <div className="flex justify-center items-center mb-10">
                            <button
                                title="Reload the server status"
                                className=" border border-black border-opacity-30 px-2 py-1 rounded hover:bg-slate-200"
                                // onClick={}
                            >
                                <i className="fa-solid fa-arrow-rotate-right"></i>
                            </button>
                        </div> */}

                        {creatingServer &&
                            <div className="w-full border-t border-black border-opacity-20 p-4 flex items-center justify-between">
                                <div className="flex flex-1 flex-col items-start justify-start">
                                    <h1 className="font-bold">{serverConfig.serverName}</h1>
                                    {/* <h2 className="text-sm font-light">{server.publicIpAddress}</h2> */}
                                </div>
                                <div className="flex flex-1 justify-end items-center text-sm gap-2">
                                    {/* <div className="flex gap-2 text-custom-green font-bold"> */}
                                    <div className="spinner2-custom-green"></div>
                                    <p>Creating...</p>
                                    {/* </div> */}
                                </div>
                            </div>
                        }
                    
                        {/* {Object.keys(servers).map((collectionName) => (
                            <div className="w-full" key={collectionName}>
                                {servers[collectionName].map((server) => (
                                    <div className="w-full" key={server.serverName}>
                                        {serverStatuses[server.serverName] === "pending" && (
                                            <div className="w-full border-t border-black border-opacity-20 p-4 flex items-center justify-between">
                                                <div className="flex flex-1 flex-col items-start justify-start">
                                                    <h1 className="font-bold">{server.serverName}</h1>
                                                    <h2 className="text-sm font-light">{server.publicIpAddress}</h2>
                                                </div>
                                                <div className="flex flex-1 justify-end items-center">
                                                    <div className="flex gap-2 text-custom-green font-bold">
                                                        <div className="spinner2-custom-green"></div>
                                                        <p>Creating</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))} */}

                        {selectedServers.map((collectionName) => (
                            <div className="w-full" key={collectionName}>
                                {servers[collectionName].map((server) => (
                                    <div className="w-full" key={server.serverName}>
                                        {/* {serverStatuses[server.serverName] !== "pending" && (   */}
                                        <div className="w-full border-t border-black border-opacity-20 p-4 flex flex-wrap items-center justify-between">
                                            <div className="flex w-[34%] flex-col items-start justify-start gap-1" style={{ whiteSpace: 'normal', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                                                <h1 className="font-bold">{server.serverName} <span className="text-xs text-slate-600">(PC {instanceTypes.find(s => s.value === server.instanceType)?.name})</span></h1>
                                                <h2 className="text-sm">{server.publicIpAddress}</h2>
                                            </div>
                                            <div className="flex flex-col w-[33%] text-left items-center justify-center gap-4">
                                                <div className="flex justify-start items-center gap-1">
                                                    {serverStatuses[server.serverName] ? (
                                                        <div className="flex justify-start items-center gap-1">
                                                            {serverStatuses[server.serverName] === "error" &&
                                                                <div className="flex justify-start items-center gap-1">
                                                                    <div className="w-5 h-5 rounded-full shadow bg-red-700"></div>
                                                                    <div className="block text-xs capitalize">Removed</div>
                                                                </div>
                                                            }
                                                            {serverStatuses[server.serverName] === "running" ? ( 
                                                                <>
                                                                    {serverChecks[server.serverName] === "ok" ? (
                                                                        <div className="flex justify-start items-center gap-1">
                                                                            <div className="w-5 h-5 rounded-full shadow bg-green-600"></div>
                                                                            <div className="block text-xs capitalize font-bold">{serverStatuses[server.serverName]}</div>
                                                                        </div>
                                                                    ) : serverChecks[server.serverName] === "initializing" ? (
                                                                        <div className="flex flex-col justify-center items-center gap-1 text-xs">
                                                                            <div className="flex justify-center items-center gap-1 text-xs">
                                                                                <div className="spinner2-custom-green"></div>
                                                                                <p className="capitalize font-bold">{serverChecks[server.serverName]}</p>
                                                                            </div>
                                                                            <div className="text-custom-green text-center">Server initialization may take up to 5 minutes.</div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="flex justify-center items-center gap-1 text-xs">
                                                                            <div className="spinner2-custom-green"></div>
                                                                            <p className="capitalize font-bold">{serverChecks[server.serverName]}</p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {serverStatuses[server.serverName] !== "error" &&
                                                                        <div className="flex justify-start items-center gap-1">
                                                                            <div className="w-5 h-5 rounded-full shadow bg-red-700"></div>
                                                                            <div className="block text-xs capitalize">{serverStatuses[server.serverName]}</div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="flex justify-center items-center gap-1 text-xs">
                                                            <div className="spinner2-custom-green"></div>
                                                            <p>Loading...</p>
                                                        </div>
                                                    )}
                                                </div>
                                                {!serverStatuses[server.serverName] || (serverStatuses[server.serverName] !== "terminated" && serverStatuses[server.serverName] !== 'error' && serverStatuses[server.serverName] !== 'Shutting-Down' && !loadingAction["destroy"][server.serverName]) &&
                                                    <div className="flex justify-start items-center gap-1">
                                                        <button className={`${serverStatuses[server.serverName] === "running" ? "opacity-50" : "hover:bg-emerald-900 hover:text-white"} "text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-full w-8 h-8 transition duration-150 ease-in-out"`}
                                                            onClick={() => handleAction('start', server.serverName)}
                                                            disabled={serverStatuses[server.serverName] === "running"}
                                                            title="Start"
                                                        >    
                                                            <i className="fa-solid fa-play"></i>
                                                        </button>
                                                        <button className={`${serverChecks[server.serverName] !== "ok" ? "opacity-50" :"hover:bg-red-700 hover:text-white"} "text-center border-2 border-red-700 bg-transparent text-red-700 rounded-full w-8 h-8 transition duration-150 ease-in-out"`}
                                                            onClick={() => handleAction('stop', server.serverName)}
                                                            disabled={serverChecks[server.serverName] !== "ok"}
                                                            title="Shutdown"
                                                        >
                                                            <i className="fa-solid fa-power-off"></i>
                                                        </button>
                                                        <button className={`${serverChecks[server.serverName] !== "ok" ? "opacity-50" :"hover:bg-amber-500 hover:text-white"} "text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-full w-8 h-8 transition duration-150 ease-in-out"`}
                                                            onClick={() => handleAction('restart', server.serverName)}
                                                            disabled={serverChecks[server.serverName] !== "ok"}
                                                            title="Restart"
                                                        >
                                                            <i className="fa-solid fa-rotate-right"></i>
                                                        </button>
                                                    </div>
                                                }
                                                {loadingAction["destroy"][server.serverName] &&
                                                    <div className="flex justify-center items-center gap-1 text-xs">
                                                        {/* <div className="spinner2-custom-red"></div> */}
                                                        <div className="text-red-700 text-center">Server termination may take up to 5 minutes.</div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex w-[33%] justify-end text-center gap-1 text-sm">
                                                {!serverStatuses[server.serverName] || serverStatuses[server.serverName] === 'terminated' || serverStatuses[server.serverName] === 'error' ? (
                                                    <>
                                                        {/* <button type="submit" className="elastic-button1 px-3" onClick={() => handleToggleDialog(server.serverName, 'recreate')}>
                                                            {isLoading ?
                                                                <div className="flex items-center gap-1">
                                                                    <div className='spinner2'></div>
                                                                    <p>Recreating</p>
                                                                </div>
                                                                :
                                                                "Recreate"
                                                            }
                                                        </button>
                                                        <button type="submit" className="button-red" onClick={() => handleToggleDialog(server.serverName, 'remove')}>Remove</button> */}
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <Link to="/dashboard/serverdetails" className="button-transparent text-black border border-black">Manage</Link> */}
                                                        <button
                                                            onClick={() => handleViewDetails(collectionName)}
                                                            className={`${loadingAction["destroy"][server.serverName] ? "opacity-50" : ""} button-transparent text-black border border-black`}
                                                            disabled={loadingAction["destroy"][server.serverName]}
                                                        >
                                                            Manage
                                                        </button>
                                                        <button 
                                                            type="submit"
                                                            className={`${loadingAction["destroy"][server.serverName] || serverChecks[server.serverName] === "initializing" ? "opacity-50" : ""} button-red`}
                                                            onClick={() => handleToggleDialog(server.serverName, 'destroy')}
                                                            disabled={loadingAction["destroy"][server.serverName] || serverChecks[server.serverName] === "initializing"}
                                                        >
                                                            
                                                            {loadingAction["destroy"][server.serverName] ? ( 
                                                                <div className="spinner2"></div>                        
                                                            ) : (
                                                                <p>Destroy</p>
                                                            )}
                                                            
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            {openDialog === server.serverName && (
                                                <div className="w-full mt-4 flex items-center justify-center z-50">
                                                    <div className="">
                                                        <div className="text-black text-left p-5 font-light text-sm">
                                                            {dialogAction === 'destroy' && (
                                                                <>
                                                                    <p className="text-left">
                                                                        <span className="font-bold text-red-700">WARNING: </span>
                                                                        This will delete the entire server, and it won't be recoverable.
                                                                    </p>
                                                                    <p className="mt-2 mb-1">To proceed, type the server name in the required field.</p>
                                                                    <input
                                                                        className="w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                                                        type="text"
                                                                        name="serverName"
                                                                        required
                                                                        value={serverName}
                                                                        onChange={(e) => setServerName(e.target.value)}
                                                                    />
                                                                    <div className="px-5 pb-5 text-sm flex gap-1 justify-end">
                                                                        <button
                                                                            className={`${loadingAction.destroy[selectedServer.serverName] || loadingAction.remove[selectedServer.serverName] ? "opacity-50 cursont-not-allowed" : ""} mt-2 px-6 uppercase button-transparent border border-black`}
                                                                            type="button"
                                                                            onClick={() => handleToggleDialog(null)}
                                                                            disabled={loadingAction.destroy[selectedServer.serverName] || loadingAction.remove[selectedServer.serverName]}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                        <button
                                                                            className={`${loadingAction.destroy[selectedServer.serverName] || loadingAction.remove[selectedServer.serverName] ? "opacity-50 cursont-not-allowed" : ""} mt-2 px-6 uppercase button-red`}
                                                                            type="button"
                                                                            onClick={() => {
                                                                                if (dialogAction === 'destroy') handleDestroyServer();
                                                                                if (dialogAction === 'remove') handleRemoveServer();
                                                                            }}
                                                                            disabled={loadingAction.destroy[selectedServer.serverName] || loadingAction.remove[selectedServer.serverName]}
                                                                        >
                                                                            {loadingAction[dialogAction][selectedServer.serverName] ? (
                                                                                <div className="spinner2"></div>
                                                                            ) : (
                                                                                dialogAction.charAt(0).toUpperCase() + dialogAction.slice(1)
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/* )} */}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div className="-mt-2 w-full border-t border-black border-opacity-20"></div>
                        
                        <div className="flex justify-between mt-10 w-full">
                            <button 
                                onClick={handlePreviousPage} 
                                disabled={currentPage === 1} 
                                className={`${currentPage === 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                            >
                                <i className="fa-solid fa-arrow-left-long"></i>
                                <p>Previous</p>
                            </button>
                            <div className="flex gap-2">
                                {Array.from({ length: totalPages }, (_, index) => {
                                    const pageNumber = index + 1;
                                    return (
                                        <button
                                            key={pageNumber}
                                            onClick={() => handlePageClick(pageNumber)}
                                            className={`${
                                                currentPage === pageNumber ? 'text-custom-green font-bold' : 'text-black'
                                            }`}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                })}
                            </div>

                            <button 
                                onClick={handleNextPage} 
                                disabled={currentPage === totalPages} 
                                className={`${currentPage === totalPages ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                            >
                                <p>Next</p>
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Copyright />
        </>
    );
}

export default Products;
