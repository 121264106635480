import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function PhpManager(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;
    useEffect(() => {
        document.title = 'Manage PHP - versions and settings';
    }, []);

    return (
        <>
            <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?appManager`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    PHP Manager
                </p>
            </div>

            <div>
                <h1 className="text-xl uppercase font-bold">PHP Manager</h1>
                <p className="font-light">A simple interface to manage your PHP variables & versions.</p>
            </div>

            <div className="mt-8 ml-4">
                <h1 className="text-md font-bold uppercase mb-2">Select PHP Version</h1>
                <select className="w-full sm:w-[50%] border-2 border-custom-green bg-black text-white rounded-md px-1 py-1">
                    <option value="php74">PHP 7.4</option>
                    <option value="php80">PHP 8.0</option>
                    <option value="php81">PHP 8.1</option>
                    <option value="php82">PHP 8.2</option>
                </select>
            </div>

            {/* <div className="mt-5 ml-4">
                <h1 className="text-md font-bold uppercase mb-2">Install Extenstions</h1>

            </div> */}
            

            <div className="flex lg:flex-row text-sm md:text-base flex-col mt-5">
                <div className="flex flex-col p-5">
                    <table className="w-full md:table-fixed table-auto]">
                        <thead className="">
                            <tr className="bg-gray-300 text-black border-t border-b border-gray-300">
                                {/* Padding is not working on "tr" so it's implemented on "th" and "td" */}
                                <th className="pl-4 py-2">PHP Directive</th>
                                <th className="pr-4 py-2">Value</th>
                            </tr>
                        </thead>
                        
                        <tbody className="">
                            <tr className="p-4">
                                <td className="pl-4 py-2">allow_url_fopen</td>
                                <td>
                                    <select className="border-2 border-custom-green bg-black text-white rounded-md px-1 w-full sm:w-[50%]" defaultValue={'DEFAULT'}>
                                        <option value="on">On</option>
                                        <option value="DEFAULT">Off</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">display_error</td>
                                <td>
                                    <select className="border-2 border-custom-green bg-black text-white rounded-md px-1 w-full sm:w-[50%]">
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">log_errors</td>
                                <td>
                                    <select className="border-2 border-custom-green bg-black text-white rounded-md px-1 w-full sm:w-[50%]">
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">error_reporting</td>
                                <td>
                                    <select className="border-2 border-custom-green bg-black text-white rounded-md px-1 w-[100%] md:w-auto" defaultValue={'DEFAULT'}>
                                        <option value="eall">~E_ALL</option>
                                        <option value="enotice">E_ALL & ~E_NOTICE</option>
                                        <option value="e_all">E_ALL</option>
                                        <option value="DEFAULT">E_ALL & ~E_DEPRECATED & ~E_STRICT</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">file_uploads</td>
                                <td>
                                    <select className="border-2 border-custom-green bg-black text-white rounded-md px-1 w-full sm:w-[50%]">
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">open_basedir</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="maxExecution" defaultValue="" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">max_execution_time</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="maxExecution" defaultValue="300" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">max_input_time</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="maxInputTime" defaultValue="60" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">max_input_vars</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="maxInputVars" defaultValue="1000" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">memory_limit</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="memoryLimit" defaultValue="128M" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">post_max_size</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="postMaxSize" defaultValue="16M" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">upload_max_filesize</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="uploadMaxSize" defaultValue="8M" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">session.gc_maxlifetime</td>
                                <td>
                                    <input className="w-full sm:w-[50%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="sessionTimeout" defaultValue="1440" />
                                </td>
                            </tr>
                            <tr className="p-4">
                                <td className="pl-4 py-2">session.save_path</td>
                                <td>
                                    <input className="w-full sm:w-[50%] md:w-auto placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-1 px-2 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="" type="text" id="sessionPath" defaultValue="/var/php/7.4/session" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="flex">
                        <button className="mt-8 button1" id="phpINI" type="submit">Apply</button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PhpManager;
