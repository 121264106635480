import stayAheadImg from '../../../../../images/stay-ahead.png'

function StayAhead() {
    
    return (
        <div className='px-5 lg:px-20 lg:py-20 py-10 flex flex-col gap-12'>
            <div className='flex justify-center items-center'>
                <h2 className='lg:w-[40%] lg:px-8 py-2 bg-black text-white text-2xl text-center'>Stay Ahead with Real-Time Server & Application Monitoring</h2>
            </div>

            <div className='flex flex-col-reverse lg:flex-row gap-8'>
                <div className='px-5 lg:w-[55%] lg:px-10 flex justify-center items-center flex-col gap-8'>
                    <ul className="list-disc flex flex-col gap-2">
                        <li><span className='font-bold'>Proactive Monitoring:</span> We ensure optimal performance by continuously monitoring your digital assets using AI-powered tools.</li>
                        <li><span className='font-bold'>Cutting-Edge Tools:</span> Our AI-powered monitoring tools provide real-time insights into server and application health.</li>
                        <li><span className='font-bold'>Comprehensive Insights:</span> Track server uptime, resource usage, and application performance with precision, backed by AI-powered analytics.</li>
                        <li><span className='font-bold'>Empowered Decision-Making:</span> Stay informed and make data-driven decisions to optimize your operations, with insights powered by AI.</li>
                        <li><span className='font-bold'>Swift Issue Resolution:</span> Our dedicated team swiftly addresses potential issues, using AI-powered tools to ensure uninterrupted performance.</li>
                        <li><span className='font-bold'>Stay Ahead:</span> With Poorva Cloud's AI-powered real-time monitoring, stay ahead of challenges and maintain seamless performance.</li>
                    </ul>
                </div>

                <div className='lg:w-[45%]'>
                    <img src={stayAheadImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default StayAhead;
