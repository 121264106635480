import React, { useState } from "react";
import { Link } from 'react-router-dom';

import companyLogo from '../../../images/poorva-cloud-logo.png';
import leftImg from '../../../images/leftdiv.png';

function LeftDiv() {

    const [showLinks, setShowLinks] = useState(false);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    return (
        <>
            <div className="flex lg:flex-row flex-col lg:justify-start justify-center items-center lg:w-[50%] bg-black text-white lg:pl-0 lg:pt-10 pt-0 pb-10 gap-16 md:gap-24">
                <div className={`${showLinks ? '' : '-translate-y-[70%] lg:-translate-y-0 lg:-translate-x-[70%]'} lg:left-0 top-0 flex items-center lg:flex-row flex-col transition-all duration-300 ease-in-out z-50`}>
                    <div className="lg:w-[70%] h-[70%] lg:h-auto flex lg:flex-col justify-start items-center gap-4 text-black bg-white p-4 lg:px-2 lg:py-4 rounded-bl-md lg:rounded-bl-none lg:rounded-tr-md rounded-br-md transition-all duration-300 ease-in-out">
                        <Link to="/" title="Homepage" className="flex justify-center items-center gap-1"><i className="fa-solid fa-house"></i><p className="text-sm hover:font-bold"></p></Link>
                        <Link to="/plans" title="Price" className="flex justify-center items-center gap-1"><i className="fa-solid fa-tags"></i><p className="text-sm hover:font-bold"></p></Link>
                        {/* <Link to="/support" title="Support" className="flex justify-center items-center gap-1"><i className="fa-solid fa-headset"></i><p className="text-sm hover:font-bold"></p></Link> */}
                        <Link to="/contact" title="Contact" className="flex justify-center items-center gap-1"><i className="fa-solid fa-message"></i><p className="text-sm hover:font-bold"></p></Link>
                        <Link to="/about" title="About Us" className="flex justify-center items-center gap-1"><i className="fa-solid fa-circle-info"></i><p className="text-sm hover:font-bold"></p></Link>
                        <Link to="/signin" title="Sign In" className="flex justify-center items-center gap-1"><i className="fa-solid fa-right-to-bracket"></i><p className="text-sm hover:font-bold"></p></Link>
                        <Link to="/signup" title="Sign Up" className="flex justify-center items-center gap-1"><i className="fa-solid fa-user-plus"></i><p className="text-sm hover:font-bold"></p></Link>
                    </div>
                    <div className="lg:w-[30%] h-[30%] lg:h-auto -mt-[2px] lg:-mt-0 lg:-ml-[2px] flex justify-center items-center bg-white px-4 lg:px-1 py-1 lg:py-4 rounded-bl-md lg:rounded-bl-none lg:rounded-tr-md rounded-br-md cursor-pointer transition-all duration-300 ease-in-out" onClick={toggleLinks}>
                        <i title="Menu" className={`hidden lg:block text-black fa-solid ${showLinks ? 'fa-chevron-left' : 'fa-chevron-right'}`}></i>
                        <i title="Menu" className={`lg:hidden text-black fa-solid ${showLinks ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </div>
                </div>
                
                <div className="h-full relative top-0 flex flex-col gap-20 lg:gap-20 items-center lg:-ml-40">
                    <Link to='/' className="w-[50%] lg:w-[25%] flex justify-center">
                        <img className='rounded' alt='' src={companyLogo} />
                    </Link>
                    <h3 className="mt-12 w-[60%] lg:w-[65%] text-center">Discover Superior Managed Cloud Hosting with Us! Join now and enhance your hosting experience with our cutting-edge solutions and dedicated support.</h3>
                    <img className='-mt-12 w-[75%]' src={leftImg} />
                </div>
            </div>
        </>
    )
}

export default LeftDiv