import alwaysAvailableImg from '../../../../../images/always-available.png'

function AlwaysAvailable() {
    
    return (
        <div className='px-5 lg:px-20 lg:py-20 py-10 flex flex-col-reverse lg:flex-row-reverse'>
            <div className='lg:w-[60%] lg:px-20 flex justify-center items-center flex-col gap-8 text-center'>
                <h2 className='lg:px-8 py-2 bg-black text-white text-2xl'>Always Available, Always Reliable: Our 24/7/365 Support</h2>
                <h2 className='text-xl'><span className='text-custom-green'>Instant Assistance at Your Fingertips,</span> Anytime, Anywhere</h2>
                <p>At Poorva Cloud, we understand that your business doesn't sleep, and neither do we. Our dedicated support team is available around the clock, ready to address any questions, concerns, or technical issues you may encounter. Whether you prefer real-time chat support for immediate assistance or submitting a ticket for more complex inquiries, we've got you covered. Rest assured that our team is committed to providing prompt and effective solutions, ensuring that your operations remain uninterrupted and your peace of mind intact. With Poorva Cloud, help is never more than a click away.</p>
            </div>

            <div className='p-10 lg:w-[40%]'>
                <img src={alwaysAvailableImg} alt="" />
            </div>
        </div>
    )
    
}

export default AlwaysAvailable;
